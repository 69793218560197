import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCvv8LQmwhH5hpgJBHvUdb4WALs8TmAhpQ",
  authDomain: "fortress-wfms.firebaseapp.com",
  databaseURL: "https://fortress-wfms.firebaseio.com",
  projectId: "fortress-wfms",
  storageBucket: "fortress-wfms.appspot.com",
  messagingSenderId: "45286596417",
  appId: "1:45286596417:web:0e6c770794dae1a4c18bc0",
  measurementId: "G-E2CL9LMZ1Q",
};
firebase.initializeApp(config);

export default firebase;
