import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import swal from "sweetalert";
import {useHistory} from "react-router-dom";

import {deleteUser, getUsers} from "../../../api";
import * as apiActions from "../../../state/users";
import moment from 'moment';

import {PageLayout} from "../../../components/pageLayout";

import userDefaultImg from "../../../resources/img/user/user-img-default.png";
import {hideLoader, showLoader} from '../../../utils/loader';
import {usePagination, useSortBy, useTable} from 'react-table';
import i18n from "i18next";


export function AllUsers() {
    const [error, setError] = useState("");
    const allData = useSelector((state) => state.users.byId);
    const allIds = useSelector((state) => state.users.allIds);
    const allDataArray = allIds.map((key) => allData[key]);
    const companyState = useSelector((state) => state.company.profile);
    const [view, setView] = useState("table");
    const dispatch = useDispatch();
    const columns = [
            {
                Header: '',
                accessor: 'user',
                image: true
            },
            {
                Header: i18n.t('table.users.header.name', "Name")
                ,
                accessor: 'name',
            },
            {
                Header: i18n.t('table.users.header.emp-no', "Emp No"),
                accessor: 'emp',
            },
            {
                Header: i18n.t('table.users.header.email', "Email"),
                accessor: 'email'
            },
            {
                Header: i18n.t('table.users.header.role', "Role"),
                accessor: 'role',
            },
            {
                Header: i18n.t('table.users.header.status', "Status"),
                accessor: 'status',
            },
            {
                Header: i18n.t('table.users.header.emergency', "Emergency"),
                accessor: 'emergency',
            }
        ]
    ;
    const views = {
        table: {
            icon: "table-large",
        },
        grid: {
            icon: "border-outside"
        }
    }
    useEffect(async () => {
        setError("");
        loadAllUsers();
    }, []);

    async function loadAllUsers() {
        showLoader();
        const res = await getUsers(companyState.id, false);
        if (res.success) {
            dispatch(apiActions.clearUsers());
            dispatch(apiActions.setUsers(res.data));
        }
        hideLoader();
    }

    useEffect(() => {
        if (error) {
            swal(error);
        }
    }, [error]);
    let history = useHistory();

    function editUser(user) {

        history.push({
            pathname: '/users/edit',
            search: '?id=' + user.id
        })
    }

    function removeUser(user,force,message) {
      if(!message){
        message='You cannot undo this. Are you sure to remove "' + user.user.firstName + '" ?';
      }

        swal(
            {
                title: 'Confirm',
                text: message,
                icon: 'warning',
                buttons: {
                    cancel: "Close",
                    submit: {
                        text: "Delete",
                        value: "delete"
                    }
                }

            }
        ).then((val) => {
            if (val == 'delete') {
                return deleteUser(user.id,force);

            }
            throw null;
        }).then((res) => {

            if (res && !res.success && res.code && res.code.indexOf("C") != -1) {
              removeUser(user,true,res.message+". Do you still want to remove this user?")
            } else {

                swal({
                    title: res && res.success ? 'Success' : 'Error',
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: res && res.success ? 'success' : 'error',
                    buttons: "Close"
                }).then(() => {

                        loadAllUsers();

                    }
                );
            }
        })
    }

    function getStatus(status, time) {
        if (!status || status === 'ONLINE') {
            return "Online";
        }
        if (!time) {
            return status;
        }
        return "Online " + moment(time).fromNow();
    }

    return (
        <PageLayout>
            <div className="content-wrapper">
                <div className="row justify-flex-end">
                    <ul
                        className="nav nav-pills nav-pills-custom justify-content-md-end"
                        id="pills-tab-custom"
                        role="tablist"
                    >
                        {Object.keys(views).map((v) => {
                            return (
                                <li className="nav-item">
                                    <a
                                        className={"nav-link " + (v == view ? "active" : "")}
                                        id="pills-home-tab-custom"
                                        data-toggle="pill"
                                        href="#pills-health"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected={v == 'table' ? "true" : "false"}
                                        onClick={(e) => {
                                            setView(v)
                                        }}
                                    >
                                        <button className="btn btn-sm">
                                            <i className={"mdi mdi-" + views[v].icon}></i>
                                        </button>
                                    </a>
                                </li>
                            )
                        })}


                    </ul>
                </div>
                <div className="row">

                    <div className="col-12">
                        {view == "table" ?
                            (<Table
                                data={
                                    allDataArray.map((user) => {
                                            return {
                                                user: user.imageURL ? user.imageURL : userDefaultImg,
                                                name: user.name,
                                                email: user.user.email,
                                                role: user.userRole.name,
                                                status: getStatus(user.userStatus, user.statusUpdatedTime),
                                                emergency: user.availableForEmergency ? "Available" : "N/A",
                                                userData: user,
                                                emp: user.employeeNumber
                                            }
                                        }
                                    )
                                }

                            ></Table>)
                            :
                            (

                                <GridView
                                    allDataArray={allDataArray}
                                ></GridView>
                            )
                        }

                    </div>
                    )

                </div>
            </div>
        </PageLayout>
    );


    function Table({data}) {


        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page, // Instead of using 'rows', we'll use page,
            // which has only the rows for the active page

            // The rest of these things are super handy, too ;)
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: {pageIndex, pageSize},
        } = useTable(
            {
                columns,
                data,
                initialState: {pageSize: 10}
            },
            useSortBy,
            usePagination
        )

        // We don't want to render all 2000 rows for this example, so cap
        // it at 20 for this use case

        return (
            < div className="table-responsive">
                <table className="table table-striped" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                      {column.isSorted
                          ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                          : ''}
                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} >
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} >
                                                {cell.column.image ? (<img className="card-img-top  avatar-img"
                                                                           src={
                                                                               cell.value
                                                                           }
                                                >

                                                </img>) : cell.render('Cell')}


                                            </td>

                                        )
                                    })}
                                    <td>
                                        <div className=" r-2 t-2 ">
                                            <button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                                                    onClick={() => editUser(row.original.userData)}
                                            ><i className="mdi mdi-account-edit"></i></button>
                                            <button className="btn btn-sm btn-outline-danger btn-fw p-2"
                                                    onClick={() => removeUser(row.original.userData)}
                                            ><i className="mdi mdi-account-remove"></i></button>

                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </table>
                <div className="row justify-content-md-end flex">
                    <div className="col-md-7">
                        <div className="pagination  justify-content-md-end d-flex">
                            <nav>
                                <ul className="pagination flex-wrap">
                                    <li className="page-item">
                                        <button className="btn btn-sm" onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}>
                                            {'<<'}
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button className="btn btn-sm" onClick={() => previousPage()}
                                                disabled={!canPreviousPage}>
                                            {'<'}
                                        </button>
                                    </li>

                                    <li className="page-item">
                                        <button className="btn btn-sm" onClick={() => nextPage()}
                                                disabled={!canNextPage}>
                                            {'>'}
                                        </button>
                                    </li>

                                    <li className="page-item">
                                        <button className="btn btn-sm" onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}>
                                            {'>>'}
                                        </button>
                                    </li>


                                </ul>
                            </nav>

                        </div>


                    </div>

                    <div className="col-md-3">
            <span>
              Page{' '}
                <input
                    className=" form-control-sm col-md-5 "
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                    }}
                />
              <strong>
                of {pageOptions.length}
              </strong>{' '}
            </span>

                    </div>


                </div>


            </ div>
        )
    }

    function GridView(props) {
        return (
            <div className="row">
                {props.allDataArray.map((userData) => {
                    return (
                        <div className="col-sm-12 col-md-6 col-xl-4 grid-margin stretch-card user-card"
                             key={userData.id}>
                            <div className="card mb-3">
                                <div className="row no-gutters">
                                    <div className="col-4">
                                        <img
                                            src={
                                                userData.imageURL ? userData.imageURL : userDefaultImg
                                            }
                                            className="card-img-top"
                                            style={{
                                                height: "150px",
                                                objectFit: "cover"
                                            }}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">

                                            <div className="pos-abs r-2 t-2 action-btns">
                                                <button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                                                        onClick={() => editUser(userData)}
                                                ><i className="mdi mdi-account-edit"></i></button>
                                                <button className="btn btn-sm btn-outline-danger btn-fw p-2"
                                                        onClick={() => removeUser(userData)}
                                                ><i className="mdi mdi-account-remove"></i></button>

                                            </div>


                                            <h4 className="card-title">
                                                {userData.user.firstName +
                                                " " +
                                                userData.user.lastName}
                                                {userData.user.emailVerified && (
                                                    <small
                                                        className="mdi mdi-checkbox-marked-circle user-verified-tick"></small>
                                                )}
                                            </h4>
                                            <p className="text-muted">
                                                {userData.user.email}
                                                {!userData.user.emailVerified && (
                                                    <small
                                                        className="mdi mdi-alert-circle email-unverified-tick"></small>
                                                )}
                                            </p>
                                            <p className="card-text">{userData.user.phone}</p>
                                            {userData.userRole.roleLevel === 1 ? (
                                                <small className="badge badge-danger-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : userData.userRole.roleLevel === 2 ? (
                                                <small className="badge badge-secondary-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : userData.userRole.roleLevel === 3 ? (
                                                <small className="badge badge-warning-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : userData.userRole.roleLevel === 4 ? (
                                                <small className="badge badge-info-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : userData.userRole.roleLevel === 5 ? (
                                                <small className="badge badge-success-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : userData.userRole.roleLevel === 10 ? (
                                                <small className="badge badge-light-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            ) : (
                                                <small className="badge badge-dark-outline badge-pill">
                                                    {userData.userRole.name}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>


        );
    }
}
