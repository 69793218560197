import React, { Component, useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';

// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:

// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useSelector } from 'react-redux';
import i18n from "i18next";
const statusesNames = {
    "Completed": i18n.t('forms.status.completed', "Completed"),
    "Ongoing": i18n.t('forms.status.ongoing', "Ongoing"),
    "On hold": i18n.t('forms.status.on-hold', "On hold"),
}
export function ReportFilter(props) {
    const config = props.config;


    const handleCallback = (start, end) => {

        props.onDateRangeChange(start, end);
    };


    const label =
        props.start.format('MMMM D, YYYY') + ' - ' + props.end.format('MMMM D, YYYY');




    return (
        <div className="row">


{
                props.groupSelection && (
                    <div className="col-md-3 col-lg-3">
                        <label htmlFor="date-filter">Groups</label>

                        <Multiselect
                            options={props.allGroups} // Options to display in the dropdown
                            onSelect={props.setGroups} // Function will trigger on select event
                            onRemove={props.setGroups} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeOnSelect={false}
                            selectedValues={props.groups}
                            id="groupSelect"
                            closeIcon="cancel"
                            emptyRecordMsg="No groups available"
                            placeholder="Select groups..."

                        />


                    </div>
                )
            }

            {
                props.locationFilter && props.allLocationIds && (
                    <div className="col-md-3 col-lg-2" id="date-range">

                        <label htmlFor="site-filter">Site</label>
                        <select
                            className="form-control form-control-sm"
                            id="site-filter"
                            required
                            onChange={(e) => props.onSiteChange(e)}
                        >
                            <option value="">{props.allSites ? "All Sites" : "Select Site..."}</option>
                            {props.allLocationIds &&
                                Object.values(props.allLocationIds).map((site) => {
                                    return (
                                        <option value={site.id} key={site.id}>
                                            {site.id + ". " + site.name}
                                        </option>
                                    );

                                })}
                        </select>
                    </div>

                )
            }



            {
                props.userFilter && props.allUserIds && (
                    <div className="col-md-3 col-lg-2" id="date-range">

                        <label htmlFor="user-filter">Employee</label>
                        <select
                            className="form-control form-control-sm"
                            id="user-filter"
                            required
                            onChange={(e) => props.onUserChange(e)}
                        >
                            <option value="">{props.allUsers ? "All Employees" : "Select Employee..."}</option>
                            {props.allUserIds &&
                                Object.values(props.allUserIds).map((user) => {
                                    return (
                                        <option value={user.id} key={user.id}>
                                            {user.user.firstName + " " + user.user.lastName + (user.employeeNumber != null ? " (" + user.employeeNumber + ")" : "")}
                                        </option>
                                    );

                                })}
                        </select>
                    </div>

                )
            }





            <div className="col col-md-6 col-lg-4  " id="date-range">
                <label htmlFor="date-filter">Date range</label>
                <DateRangePicker
                    id="date-filter"
                    initialSettings={{
                        startDate: props.start.toDate(),
                        endDate: props.end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                            'All Time': [
                                moment().subtract(50, 'year').startOf('year').toDate(),
                                moment().add(1, 'days').toDate(),
                            ]
                        },
                    }}
                    onCallback={handleCallback}
                >
                    <div
                        id="reportrange"
                        style={{
                            background: '#fff',
                            cursor: 'pointer',
                            padding: '5px 10px',
                            border: '1px solid #ccc',
                            width: '100%',
                        }}
                    >
                        <i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
                    </div>
                </DateRangePicker>
            </div>


            {
                props.jobStatus && (
                    <div className="col-md-3 col-lg-3">
                        <label htmlFor="date-filter">Status</label>

                        <ul
                            className="nav nav-pills nav-pills-custom justify-content-md-start "
                            id="pills-tab-custom"
                            role="tablist"
                        >
                            {Object.keys(props.statuses).map((stat) => {
                                return (
                                    <li className="nav-item ">
                                        <a
                                            className={"nav-link " + (props.statuses[stat] == props.selectedStatus ? "active" : "")}
                                            id="pills-home-tab-custom"
                                            data-toggle="pill"
                                            href="#pills-health"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected={stat == 'Completed' ? "true" : "false"}
                                            onClick={(e) => {
                                                props.changeStatus(stat)
                                            }}
                                        >
                                            {statusesNames[stat]?statusesNames[stat]:stat}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            }


         




            <div>

            </div>


        </div>
    );
}