import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { validatePasswordResetToken, forgortPasswordRequest, resetPassword } from "../../api";
import * as apiActions from "../../state/company";

import { COLOR_PROFILE } from "../../config";
import { isValidEmail } from "../../utils/authUtils";

export function ForgotPassword(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  function getParam(url, key) {

    var url = new URL(url);
    return url.searchParams.get(key);

  }
  useEffect(() => {
    if (error) {
      swal(error);
    }
    checkForToken();
  }, [error]);

  async function checkForToken() {
    const token = getParam(window.location.href, "token");
    setToken(token);
    if (token) {

      const res = await validatePasswordResetToken(token);
      if (!res.success) {
        swal(res.message).then(()=>{
          setStep(1);
        })
    
      } else {
        setStep(2);
      }
    } else {
      setStep(1);
    }
  }
  async function submitEmail(e) {
    e.preventDefault();
    const email=document.getElementById("email").value;
    if (isValidEmail(email)) {
      const res = await forgortPasswordRequest(email);
      if (res.success) {
        swal({
          title: "Success",
          text: "Password reset link is sent to your email (" + email + ").",
          icon: "success"
        })
      } else {
        swal({
          text: res.message,
          title: "Error",
          icon: "error"
        });
      }
    } else {
      swal("Email is invalid");
    }

  }



  const Step1 = () => {
    return (<div>
      <h4>Reset your password</h4>
      <h6 className="font-weight-light">Please enter your email address</h6>
      <form
        className="pt-3"
        onSubmit={(e) => {
          submitEmail(e);
        }}
      >
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            id="email"
            name="email"
            placeholder="Email"
            required
          />
        </div>
        <div className="mt-3">
          <button
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>)
      ;
  }
  async function submitPassword(e) {
    e.preventDefault();
    const pw = document.getElementById("password").value;
    const cpw = document.getElementById("c_password").value;

    if (cpw === pw) {
      const res = await resetPassword({
        token: token,
        password: pw
      });

      if (res && res.success) {
        swal({
          title: "Success",
          text: "Password changed",
          icon: "success"
        }).then(() => {
          window.location.href = "/signin";
        })
      } else {
        swal({
          text: res.message,
          title: "Error",
          icon: "error"
        });
      }

    } else {
      swal("Passwords are not matching");
    }


  }
  const Step2 = () => {
    return (<div>
      <h4>Reset your password</h4>
      <h6 className="font-weight-light">Please enter a new password.</h6>
      <form
        className="pt-3"
        onSubmit={(e) => {
          submitPassword(e);
        }}
      >
        <div className="form-group">
          <input
            type="password"
            name="password"
            className="form-control form-control-lg"
            id="password"
            placeholder="Password"
            required
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            name="C_password"

            className="form-control form-control-lg"
            id="c_password"
            placeholder="Confirm Password"
            required
          />
        </div>
        <div className="mt-3">
          <button
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
            type="submit"
          >
            Reset
          </button>
        </div>
      </form>
    </div>)
      ;
  }






  return (
    <div>
      {
        step == 1 ? (<Step1 />) : (<Step2 />)
      }

    </div>
  );




}
