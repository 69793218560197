import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

import { getGroups, deleteGroup, registerGroup, updateGroup } from "../../../api";
import * as apiActions from "../../../state/groups";
import moment from 'moment';

import { PageLayout } from "../../../components/pageLayout";

import userDefaultImg from "../../../resources/img/user/user-img-default.png";
import { hideLoader, showLoader } from '../../../utils/loader';
import { useTable, useSortBy, usePagination } from 'react-table';
import i18n from "i18next";



export function ManageGroups() {
  const [error, setError] = useState("");
  const allData = useSelector((state) => state.groups.byId);
  const allIds = useSelector((state) => state.groups.allIds);
  const allDataArray = allIds.map((key) => allData[key]);
  const companyState = useSelector((state) => state.company.profile);
  const dispatch = useDispatch();
  const [group, setGroup] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const columns = [

        {
          Header: i18n.t('site.groups.table.header.name',"Name"),
          accessor: 'name',
        },
        {
          Header: i18n.t('site.groups.table.header.description',"Description"),
          accessor: 'description',
        }
      ]
  ;

  const views = {
    table: {
      icon: "table-large",
    },
    grid: {
      icon: "border-outside"
    }
  }
  useEffect(async () => {
    setError("");
    loadAllGroups();
    reset();
  }, []);

  async function loadAllGroups() {
    showLoader();
    const res = await getGroups(companyState.id);
    if (res.success) {
      dispatch(apiActions.clearGroups());
      dispatch(apiActions.setGroups(res.data));
    }
    hideLoader();
  }

  function _setGroup(user) {
    setGroup(user);
  }
  useEffect(() => {
    if (error) {
      swal(error);
    }
  }, [error]);
  let history = useHistory();

  function editGroup(group) {
    setEditMode(true);
    let _group = { ...group };
    _group.tenantId = companyState.id;
    _setGroup(_group);
  }

  const emptyGroup = {
    name: "",
    description: "",
    tenantId: companyState.id,
  };

  const reset = () => {
    setEditMode(false);
    _setGroup(emptyGroup);
  }
  const _registerGroup = async (e) => {
    showLoader();
    setError("");
    e.preventDefault();

    // //console.log((user);)
    // //console.log((profileImage);)
    if (!error || error.length == 0) {
      let res = null;
      if (!isEditMode) {
        res = await registerGroup(group);

      } else {
        res = await updateGroup(group.id, group);
      }
      if (res && res.success) {



        hideLoader();
        swal({
          title: 'Success',
          text: 'New group added',
          icon: 'success'
        }).then(() => {
          loadAllGroups();
          reset();
        }

        )

      } else {
        hideLoader();
        swal(res && res.message ? res.message : "Something went wrong");
      }

    }
  };
  function removeGroup(group) {
    swal(
      {
        title: 'Confirm',
        text: 'You cannot undo this. Are you sure to remove "' + group.name + '" ?',
        icon: 'warning',
        buttons: {
          cancel: "Close",
          submit: {
            text: "Delete",
            value: "delete"
          }
        }

      }
    ).then((val) => {
      if (val == 'delete') {
        return deleteGroup(group.id);

      }
      throw null;
    }).then((res) => {
      swal({
        title: res && res.success ? 'Success' : 'Error',
        text: res && res.message ? res.message : "Something went wrong",
        icon: res && res.success ? 'success' : 'error',
        buttons: "Close"
      }).then(() => {

        loadAllGroups();

      }
      );
    })
  }


  function getGroupProp(prop, isNaN) {
    return group && group[prop] ? group[prop]
      : isNaN ? -1 : '';
  }
  function setGroupProp(prop, val) {
    let _group = { ...group };
    _group[prop] = val;
    setGroup({ ..._group });
  }

  return (
    <PageLayout>
      <div className="content-wrapper">

        <div className="card">
          <div className="card-body">
            <div className="row flex">
              <div className="ml-2">
                <h4 className="card-title">    {i18n.t('site.groups.title',"Manage Groups")}
                </h4>
                <p className="card-description">    {i18n.t('site.groups.description',"Manage Groups")}
                </p>
              </div>
              <div className="ml-4">
                <button className="btn btn-primary"
                  onClick={() => {
                    reset();
                  }}
                >Add new</button>
              </div>
            </div>



            <div className="row flex justify-center">

              <div className="col-md-6">

                <Table
                  data={
                    allDataArray.map((group) => {
                      return {
                        name: group.name,
                        description: group.description,
                        groupData: group,
                      }
                    }
                    )
                  }

                ></Table>



              </div>
              <div className="col-md-6">
                <div className="border">
                  <div className="card-body">
                    <h4 className="card-title">{isEditMode ? i18n.t('site.groups.form.update.title',"Update Group") : i18n.t('site.groups.form.add.title',"Add Group")}</h4>
                    <p className="card-description">    {i18n.t('site.groups.form.add.description',"Group Detail")}
                    </p>

                    <form className="forms-sample" onSubmit={_registerGroup}>


                      <div className="row">
                        <div className="form-group col-sm-12 ">
                          <label htmlFor="name">    {i18n.t('site.groups.form.name',"Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="name"
                            placeholder=    {i18n.t('site.groups.form.name.placeholder',"Group name")}

                            value={getGroupProp('name')}
                            onChange={(e) => { setGroupProp('name', e.target.value) }}
                            required={!isEditMode}
                          />
                        </div>


                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12 ">
                          <label htmlFor="fname">    {i18n.t('site.groups.form.description',"Description")}
                          </label>
                          <textarea
                            className="form-control form-control-sm"
                            id="description"
                            placeholder=    {i18n.t('site.groups.form.description.placeholder',"Description")}

                            value={getGroupProp('description')}
                            onChange={(e) => { setGroupProp('description', e.target.value) }}

                          />
                        </div>

                      </div>


                      <button type="submit"

                        className=
                        {isEditMode ? "btn btn-warning mr-2" : "btn btn-primary mr-2"}


                      >
                        {isEditMode ? i18n.t('btn.update',"Update"): i18n.t('btn.submit',"Submit")}
                      </button>
                      {!isEditMode && (<button
                        onClick={() => {
                          reset();
                        }}
                        type="reset" className="btn btn-light">
                        {i18n.t('btn.cancel',"Cancel")}

                      </button>)}
                    </form>


                  </div>
                </div>

              </div>


            </div>


          </div>
        </div>

      </div>
    </PageLayout>
  );


  function Table({ data }) {



    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 10 }
      },
      useSortBy,
      usePagination
    )

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case

    return (
      < div className="table-responsive" >
        <table className="table table-striped" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
                <th>
                  {i18n.t('site.groups.table.header.actions',"Actions")}

                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(
              (row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()} >
                          {cell.column.image ? (<img className="card-img-top  avatar-img"
                            src={
                              cell.value
                            }
                          >

                          </img>) : cell.render('Cell')}





                        </td>

                      )
                    })}
                    <td>
                      <div className=" r-2 t-2 ">
                        <button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                          onClick={() => editGroup(row.original.groupData)}
                        ><i className="mdi mdi-pencil"></i></button>
                        <button className="btn btn-sm btn-outline-danger btn-fw p-2"
                          onClick={() => removeGroup(row.original.groupData)}
                        ><i className="mdi mdi-delete"></i></button>

                      </div>
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
        <div className="row justify-content-md-end flex">
          <div className="col-md-7">
            <div className="pagination  justify-content-md-end d-flex">
              <nav> <ul className="pagination flex-wrap">
                <li className="page-item"> <button className="btn btn-sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button></li>
                <li className="page-item"> <button className="btn btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button></li>

                <li className="page-item"> <button className="btn btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button> </li>

                <li className="page-item"> <button className="btn btn-sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button> </li>


              </ul></nav>

            </div>


          </div>

          <div className="col-md-3">
            <span>
              Page{' '}
              <input
                className=" form-control-sm col-md-5 "
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
              />
              <strong>
                of {pageOptions.length}
              </strong>{' '}
            </span>

          </div>


        </div>



      </ div>
    )
  }

}
