import React, {useEffect, useState} from "react";
import {Multiselect} from 'multiselect-react-dropdown';
import userDefaultImg from "../../../../resources/img/user/user-img-default.png";
import moment from "moment/moment";
import Fade from "@material-ui/core/Fade";
import {Calendar} from "../../shared/calendar";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {getUserName} from "../../../../utils/StringUtils";
import {date2str} from "../../../../utils/dateUtils";
import {AnimatePresence} from "framer-motion";
import {Box, Slide} from "@material-ui/core";
import {NotificationWidget} from "./notificationWidget";
import {CalendarView} from "./calendarView";
import swal from "sweetalert";
import i18n from "i18next";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "80%",
        height: "80%",
        margin: "auto"
    }
}));

function getMappedId(event) {
    if (event.calendarEvent) {
        return event.calendarEvent.mappedId;
    }
    return event.mappedId;
}

export function EmployeeSelectWidget({
    title,
                                         selectedJob,
                                         jobLocation,
                                         setCalendarEvents,
                                         availableEmployees, addedEmployees, onSelect, onRemove, setHours,
                                         calendarEvents, setRemovedEvents, setAddedCalEvents, rootAddedCalEvent
                                     }) {
    const [removedEvents, setRemovedEventsLocal] = useState([]);
    const [addedCalEventsLocal, setAddedCalEventsLocalLocal] = useState([]);
    const [showAllEvents, setShowAllEvents] = useState(false);
    useEffect(() => {
        if (!rootAddedCalEvent || rootAddedCalEvent.length == 0) {
            {
                setAddedCalEventsLocalLocal([]);
            }
        }

    }, [rootAddedCalEvent]);


    const classes = useStyles();

    function getEventFromId(events, id, newEvent, remove) {
        let event = null;

        for (let e of events) {
            if (e['id'] == id) {
                event = e;
                break;
            }
        }
        if (event) {
            if (remove) {
                events.splice(events.indexOf(event), 1);
            } else {

                events[events.indexOf(event)] = newEvent;
            }
        }

    }

    const [open, setOpen] = useState(false);


    function addEvent(event) {

        const generateEvent = (id, _eve) => {
            return {
                id: id,
                calendarEventType: 'JOB_TASK',
                userAssigneeId: _eve["userAssigneeId"],
                tenantUserIds: [_eve["tenantUserId"]],
                assigneeId: _eve["tenantUserId"],
                startTime: _eve["sd"] + " " + _eve["st"],
                duration: _eve["duration"] ? _eve["duration"] : 120,
                recursiveFrequency: _eve["recursiveFrequency"],
                notifyBeforeMinutes: _eve["notifyBeforeMinutes"],
                allDay: _eve["allDay"],
                mappedText: _eve['mappedText'],
                mappedId: _eve['mappedId'],
                task: _eve['task'],
                dateText: _eve['title'],
                title: _eve['title'],
                active: true,
                notificationSubscriptionRequest: _eve["notificationSubscriptionRequest"]

            }
        }
        let _event;
        if (event["tenantUserId"]) {

            if (event.event) {
                if (event.event.id) {

                    if (event.event.id < 1) {
                        const newEvent = generateEvent(event.event.id, event);
                        getEventFromId(addedCalEventsLocal, event.event.id, newEvent, false);


                    } else {
                        getEventFromId(calendarEvents, event.event.id, null, true);
                        _event = generateEvent(Math.floor(Math.random() * -100000000), event);
                    }
                }
            } else {
                _event = generateEvent(Math.floor(Math.random() * -100000000), event)

            }

            if (_event) {

                addedCalEventsLocal.push(_event);
            }
            setCalendarEvents([...calendarEvents]);
            setAddedCalEventsLocalLocal([...addedCalEventsLocal]);//TODO check heare for tenantUserId
            setAddedCalEvents([...addedCalEventsLocal]);
        }

    }


    function removeEvent(id) {
        if (removedEvents.indexOf(id) == -1 && id > 0) {
            removedEvents.push(id);
        }
        getEventFromId(addedCalEventsLocal, id, null, true);
        getEventFromId(calendarEvents, id, null, true);
        getEventFromId(rootAddedCalEvent, id, null, true);

        setAddedCalEventsLocalLocal([...addedCalEventsLocal]);
        setCalendarEvents([...calendarEvents]);
        setAddedCalEvents([...rootAddedCalEvent]);

        setRemovedEventsLocal([...removedEvents]);
        setRemovedEvents([...removedEvents]);
    }

    function getUserEvents(events, emp, force) {
        if (!force && showAllEvents) {
            events.forEach((e) => {
                if (selectedJob) {
                    e['active'] = selectedJob.id == getMappedId(e);
                } else {
                    e['active'] = e.id&&e.id<0;
                }
            })
            return events;
        }

        function isUserEvent(event) {
            return event.assigneeId === getUserId(emp);
        }


        if (events) {
            let filteredItems = [];
            events.forEach((e) => {
                if (isUserEvent(e)) {

                    if (selectedJob) {
                        e['active'] = selectedJob.id == getMappedId(e);
                    } else {
                        e['active'] = e.id&&e.id<0;
                    }

                    filteredItems.push(e);


                }
            });

            return filteredItems;
        }
        return [];
    }

    function getEmpModified(emp) {
        if (!emp.firstName && emp.user && emp.user.firstName) {
            emp.firstName = emp.user.firstName
        }
        if (!emp.tenantUserId && emp.id) {
            emp.tenantUserId = emp.id;
        } else if (emp.tenantUserId) {
            emp.id = emp.tenantUserId;
        }
        return emp;
    }

    return (

        <div>
            <div className="row">
                <div className="col-sm-12 form-group">
                    <label htmlFor="employeeSelect">
                    {title && (<h6>{title}</h6> )}
                        {i18n.t('job.add.form.employees',"Select Employees")}

                    </label>

                    <Multiselect

                        options={availableEmployees} // Options to display in the dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="display" // Property name to display in the dropdown options
                        groupBy="role"
                        selectedValues={addedEmployees}
                        showCheckbox={true}
                        closeOnSelect={false}
                        id="employeeSelect"
                        closeIcon="cancel"
                        emptyRecordMsg="No employees available"
                        placeholder=    {i18n.t('job.add.form.employees.placeholder',"Select employees...")}

                    />
                    <div className="pos-abs r-0 " style={{
                        right: "20px",
                        top: "60%"
                    }} title="View full calendar">

                        <button type="button"

                                onClick={() => {
                                    setOpen(true)
                                }}
                                className="float-right r-0 btn btn-sm btn-outline-primary   h-50"><i
                            className="mdi mdi-calendar"></i></button>


                        <Modal
                            open={open}
                            className={classes.modal}
                            onClose={() => {
                                setOpen(false)
                            }}

                            closeAfterTransition


                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={open}>
                                <div className="card m-4 p-4">
                                    <AnimatePresence>

                                        <CalendarView
                                            showAllEvents={showAllEvents}
                                            setShowAllEvents={setShowAllEvents}
                                            addedCalEvents={addedCalEventsLocal}
                                            events={calendarEvents}

                                            handleCalendarClick={() => {
                                            }}
                                            title={"Full Calendar"}
                                            showSetAllButton={false}
                                        />
                                    </AnimatePresence>
                                </div>
                            </Fade>
                        </Modal>


                    </div>

                </div>


            </div>
            {addedEmployees.length > 0 && (
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>

                                {addedEmployees.map((emp, index) => {
                                    emp = getEmpModified(emp);
                                    return (<UserRow
                                        emp={emp}
                                        selectedJob={selectedJob}
                                        allEmps={availableEmployees}
                                        addedEmployees={addedEmployees}
                                        events={getUserEvents(calendarEvents, emp)}
                                        setHours={setHours}
                                        addEvent={addEvent}

                                        showAllEvents={showAllEvents}
                                        setShowAllEvents={setShowAllEvents}
                                        addedCalEvents={getUserEvents(addedCalEventsLocal, emp)}
                                        jobLocation={jobLocation}
                                        removeEvent={removeEvent}
                                    >
                                    </UserRow>)
                                })}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            )}
        </div>
    )
}

export function getSingleText(e, jobLocation) {
    if (e && e.calendarEvent && e.calendarEvent.title && e.calendarEvent.title.length > 0) {
        return e.calendarEvent.title;
    }
    let text = jobLocation && jobLocation.name ? ("Task for " + jobLocation.name) : "Task";

    if (e.recursiveFrequency && e.recursiveFrequency != 'None') {
        text += " - " + e.recursiveFrequency.capitalize();
    } else {
        text += " " + days[moment(e.startTime).day()]
    }

    return text;
}

const days = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}



String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
}

export function UserRow({
                            selectedJob,
                            emp,
                            jobLocation,
                            setHours,
                            events,
                            addEvent,
                            removeEvent,
                            setShowAllEvents,
                            showAllEvents,
                            addedCalEvents,
                            allEmps,
                            addedEmployees
                        }) {

    const [open, setOpen] = useState(false);


    function getText(userEvents) {
        if (userEvents && userEvents.length > 0) {
            let events = [];
            userEvents.forEach((e) => {
                events.push(getSingleText(e, jobLocation))
            });

            return events.join(", ")

        }
        return "";
    }

    function getDays() {


        return getText(events);
    }


    return (
        <tr className="rounded-corners">
            <td className=" w-30 flex-grow p-3">
                <div className="flex justify-content align-bottom">
                    <img
                        src={
                            emp.imageURL ? emp.imageURL : userDefaultImg
                        }
                        className="card-img-top  avatar-img"
                        alt="..."
                    />
                    <p className=" ml-3">{emp.display}</p>
                </div>
            </td>
            <td className="w-20">
                <div className=" flex justify-content align-bottom">

                    <p className=" ml-3" style={{
                        textTransform: "capitalize"
                    }}>{emp.userRole.name.toLowerCase()}</p>
                </div>
            </td>


            <td className="w-50">
                <div className="form-group">
                    <div className="input-group">


                        <button
                            className="btn w-100 btn-outline-primary"
                            type="button"
                            onClick={() => {
                                setOpen(true)
                            }}

                            id={"hours" + emp.id}
                        > Set schedule
                        </button>
                        <AnimatedDivs
                            emp={emp}
                            open={open}
                            setOpen={setOpen}
                            events={events}
                            addedCalEvents={addedCalEvents}
                            showAllEvents={showAllEvents}
                            setShowAllEvents={setShowAllEvents}
                            selectedJob={selectedJob}
                            allEmps={allEmps}
                            addEvent={addEvent}
                            removeEvent={removeEvent}
                            addedEmployees={addedEmployees}
                            jobLocation={jobLocation}
                        />


                    </div>

                </div>
            </td>
        </tr>
    )
}

export function AnimatedDivs({
                                 emp, open, setOpen, events, addedCalEvents, showAllEvents, setShowAllEvents,

                                 selectedJob,
                                 allEmps,
                                 addEvent,
                                 removeEvent,
                                 jobLocation,
                                 addedEmployees

                             }) {


    const classes = useStyles();

    const [dayViewOpen, setDayViewOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});
    const [currentStep, setCurrentStep] = useState(1);

    function handleCalendarClick(day, event) {

        if (day) {

            setSelectedEvent(null);
            setSelectedDay({...day});
        } else if (event) {
            setSelectedDay(null);
            setSelectedEvent({...event});
        }


        setDayViewOpen(true);
        setCurrentStep(2);
    }

    const containerRef = React.useRef(null);
    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={() => {
                setOpen(false)
                setCurrentStep(1);
                setShowAllEvents(false);
            }}

            closeAfterTransition


            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="card m-4 p-4">
                    <AnimatePresence>
                        {currentStep === 1 ? (
                            <CalendarView
                                showAllEvents={showAllEvents}
                                setShowAllEvents={setShowAllEvents}
                                addedCalEvents={addedCalEvents}
                                events={events}
                                handleCalendarClick={handleCalendarClick}
                                title={emp.firstName + "'s Calendar"}
                                showSetAllButton={true}
                            />
                        ) : (
                            <Box
                                sx={{


                                    overflow: "hidden"
                                }}
                                ref={containerRef}
                            >
                                <Slide in={currentStep === 2} direction="left" timeout={500}
                                       container={containerRef.current}>

                                    <div className="calendar-body">
                                        <EventView
                                            event={selectedEvent}
                                            day={selectedDay}
                                            emp={emp}
                                            allEvents={events}
                                            job={selectedJob}
                                            allEmps={allEmps}
                                            addEvent={addEvent}
                                            removeEvent={removeEvent}
                                            jobLocation={jobLocation}
                                            setCurrentStep={setCurrentStep}
                                            addedEmployees={addedEmployees}
                                        ></EventView>


                                    </div>


                                </Slide>
                            </Box>

                        )}
                    </AnimatePresence>


                </div>

            </Fade>
        </Modal>
    )
}


function addHoursToDate(date, hours) {
    return moment(date).add(hours, "hours").toDate();
}

export function EventView({
                              day, event, addEvent, removeEvent,
                              job, emp, allEvents, jobLocation, allEmps, setCurrentStep, addedEmployees
                          }) {
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [busyEvent, setBusyEvent] = useState("");
    const [tabs, setTabs] = useState({});
    const [commonData, setCommonData] = useState({});
    const [removeIds, setRemoveIds] = useState([]);
    const [saveData, setSaveData] = useState({});

    useEffect(() => {
        const cd = _setCommonData();
        _setTitle(cd);
    }, [day, event]);

    function _setTitle(commonData) {
        const m = moment(commonData.startTime);
        const date = m.format("LLLL")
        const location = jobLocation ? jobLocation.name : "New job";


        if (!commonData.title) {
            if (commonData.event && commonData.event.calendarEvent && commonData.event.calendarEvent.title) {
                commonData.title = commonData.event.calendarEvent.title;
            } else if (commonData.event && commonData.event.title) {
                commonData.title = commonData.event.title;
            } else {
                commonData.title = getSingleText(commonData, jobLocation);
            }
        }


        commonData.mappedText = commonData.title;
        if (!commonData.task) {

            commonData.task = " task : " + location.capitalize();
            setTitle(commonData.task)
        } else {
            setTitle(commonData.title)
        }
        if (commonData.task == 'Edit') {
            setSubTitle("Edit task: " + date + " for " + (emp.name ? emp.name : (emp.firstName ? emp.firstName : "")));
        } else {

            setSubTitle("Create a task on " + date + " for " + (emp.name ? emp.name : (emp.firstName ? emp.firstName : "")));
        }
        setTabs({
            0: {
                id: 0,
                title: 'Configure',
                active: true,
                view: configureView
            },
            1: {
                id: 1,
                active: false,
                title: 'Notifications',
                view: NotificationView

            }
        });
        setCommonData({...commonData});
    }


    const _setCommonData = () => {
        //console.log((day, event);)
        const isDay = day && Object.keys(day).length > 0;
        const isEvent = event && Object.keys(event).length > 0;
        const resourse = !isDay && event ? event.resource : null;

        const getStart = () => {
            if (isDay) {
                const dt = day['start'];
                if (dt.getHours() === 0) {
                    const end = day['end'];
                    const d = moment(end).diff(moment(dt), 'minutes')
                    return d > (10 * 60) ? addHoursToDate(dt, 10) : dt;
                }
                return dt
            }
            if (isEvent) {
                return event['start'];
            }
            return new Date();
        }
        let st = getStart();

        const getDuration = () => {
            if (isDay) {
                const end = day['end'];
                const d = moment(end).diff(moment(st), 'minutes')
                return d && d <= (10 * 60) ? d : 120;
            }
            if (isEvent) {
                return resourse && resourse['duration'] ? resourse['duration'] : 0;
            }
            return new Date();
        }


        let mappedId = event ? getMappedId(event) : null;
        if (!mappedId) {
            mappedId = job ? job.id : null;
        }
        const id = resourse && resourse.id ? resourse.id : null;
        const recursiveFrequency = !isDay && resourse ? resourse.recursiveFrequency : null;
        const duration = getDuration();
        const commonData = {
            emp: getUserId(emp),
            tenantUserId: resourse && resourse.assigneeId ? resourse.assigneeId : getUserId(emp),
            jobHasUserId: emp.jobHasUserId,
            id: id,
            userAssigneeId: resourse && resourse.id && resourse.id > 0 ? resourse.id : null,
            empName: getUserName(emp),
            sd: date2str(new Date(st), 'yyyy-MM-dd'),
            st: date2str(new Date(st), 'hh:mm'),
            startTime: st,
            duration: duration,
            mappedId: mappedId,
            dateText: getSingleText({
                startTime: st,
                duration: duration,
                recursiveFrequency: recursiveFrequency
            }, jobLocation),
            task: isDay ? "Add" : "Edit",
            recursiveFrequency: recursiveFrequency,
            allDay: !isDay && resourse ? resourse.allDay : false,
            notifyBeforeMinutes: !isDay && resourse ? parseInt(resourse.notifyBeforeMinutes) : 5,
            event: resourse,
            setSaveData: setSaveData,


        }
        //console.log((emp);)
        setCommonData({...commonData});
        return commonData;
    };

    function saveCalendarEvent() {
        //console.log((commonData, saveData);)
        addEvent({...commonData, ...{notificationSubscriptionRequest: saveData}})
        setCurrentStep(1);
    }

    function _removeEvent(id) {
        swal({
                title: "Confirm",
                text: "Are you sure you want to remove this event?",
                icon: "warning",
                buttons: {
                    cancel: "Close",
                    submit: {
                        text: "Delete",
                        value: "delete"
                    }
                }
            }
        ).then((val) => {
            if (val == 'delete') {
                removeEvent(id);
                // setTimeout(()=>{
                setCurrentStep(1);

                // },300)
            }
            throw null;
        });
    }

    return (

        <div className="">
            <div className="card-body">

                <h4 className="card-title">{title}</h4>
                <p className="card-description">{subTitle}</p>

                <ul className="nav nav-pills nav-pills-success">
                    {Object.values(tabs).map((tt) => {

                        return (
                            <li className="nav-item">
                                <a className={"nav-link " + (tt.active ? "active" : "")}
                                   id={"id-pills-" + tt.title}
                                   data-toggle="pill"
                                   href={"#pills-" + tt.title}
                                   role="tab" aria-controls={"pills-" + tt.title}
                                   aria-selected="true">{tt.title}</a>
                            </li>)
                    })}


                </ul>
                <div className="tab-content" id="pills-tabContent">

                    {Object.values(tabs).map((tt) => {

                        return (
                            <div
                                className={"tab-pane fade " + (tt.active ? "active show" : "")}
                                id={"pills-" + tt.title}
                                role="tabpanel" aria-labelledby={"pills-" + tt.title}>
                                {tt.view({
                                    commonData: commonData,
                                    setCommonData: setCommonData,
                                    allEmps: allEmps,
                                    shouldDisable:shouldDisable(job, commonData, emp, jobLocation),
                                    addedEmployees: addedEmployees,
                                    job: job,
                                    jobLocation: jobLocation,
                                    emp: emp,


                                })}


                            </div>
                        )
                    })}
                </div>

                <div className="row b-m-10 r-15 m-4 mt-2 pos-abs">
                    <div className="col-sm-12 ">

                        <button
                            onClick={() => setCurrentStep(1)}
                            className=" btn btn-outline-primary ml-2">    {i18n.t('btn.back',"Back")}

                        </button>

                        {!shouldDisable(job, commonData, emp, jobLocation) && (
                            <>

                                {commonData && commonData.event && (

                                    <button
                                        onClick={() => _removeEvent(commonData.id)}
                                        className="btn btn-danger ml-2">    {i18n.t('btn.remove',"Remove")}

                                    </button>

                                )}

                                <button
                                    onClick={() => saveCalendarEvent()}
                                    className=" btn btn-primary ml-2">    {i18n.t('btn.done',"Done")}

                                </button>
                            </>
                        )}


                    </div>

                </div>
            </div>

        </div>


    );
}

export function getUserFromId(users, id) {
    if (users && users.length > 0) {
        for (let u of users) {
            if (u.tenantUserId == id) {
                return u;
            }
        }
    }
    return null;
}

export function getUserId(user) {
    if (user) {
        if (user.tenantUserId) {
            return user.tenantUserId;
        }
        return user.id;
    }
    return null;
}

function shouldDisable(job, commonData, emp, jobLocation) {
    //console.log((job, commonData);)
    const r = commonData.event;
    const newJob = !job && jobLocation != null;
    if (r && r.calendarEvent != null && newJob) {
        return true;
    }
    if (r && job && job.id && r.calendarEvent && r.calendarEvent.mappedId) {

        const sameJob = r.calendarEvent.mappedId == job.id;
        const sameUser = commonData.tenantUserId && emp.id == commonData.tenantUserId;


        return !sameJob || !sameUser;
    }

    return r && r.assigneeId && r.assigneeId != emp.tenantUserId;
}

const CUSTOM_KEY = 'CUSTOM';



export function configureView({commonData, setCommonData, availableEmployee,  shouldDisable, job, addedEmployees, emp, jobLocation}) {
    const durationMap = {
        'CUSTOM': i18n.t('job.schedule.form.duration.custom',"Custom"),
        15: '15 '+i18n.t('time.minutes',"minutes"),
        30: '30 '+i18n.t('time.minutes',"minutes"),
        60: '1 '+i18n.t('time.hour',"hour"),
        90: '1 '+i18n.t('time.hour',"hour")+' and 30 '+i18n.t('time.minutes',"minutes"),
        120: '2 '+i18n.t('time.hour',"hour"),
        180: '3 '+i18n.t('time.hour',"hour"),

    }
    const frequencies = {
        "DAILY": i18n.t('frequency.daily', "Daily"),
        "TWO_DAYS": i18n.t('frequency.two-days' , "1 Days interval"),
        "WEEKLY": i18n.t('frequency.weekly', "Weekly"),
        "MONTHLY": i18n.t('frequency.monthly', "Monthly"),
        "QUARTERLY": i18n.t('frequency.quarterly', "Quarterly"),
        "ANNUALLY": i18n.t('frequency.annually', "Annually"),
    }
    function getDuration() {
        const isCustom = () => {
            //console.log((commonData.duration);)
            return !durationMap[commonData.duration] || durationMap[commonData.duration] == 'Custom';
        }

        return (<div className="row">
            <div className="form-group col-sm-12 col-md-6">
                <label htmlFor="urole">    {i18n.t('job.schedule.form.duration',"Duration")}
                </label>
                <select
                    className="form-control form-control-sm"
                    id="urole"
                    value={isCustom() ? CUSTOM_KEY : commonData.duration}
                    disabled={shouldDisable}

                    onChange={(e) => {
                        const val = e.target.value;
                        if (val != CUSTOM_KEY) {
                            commonData.duration = val;
                        } else {
                            commonData.duration = 10;
                        }

                        setCommonData({...commonData})
                    }}
                    required
                >
                    {(
                        Object.keys(durationMap).map((key, index) => {
                            return (
                                <option key={key} value={key}>
                                    {durationMap[key]}
                                </option>
                            );
                        })
                    )}
                </select>
            </div>


            <div className="form-group col-md-6">
                <label htmlFor="date-start">    {i18n.t('job.schedule.form.mins',"Mins")}
                </label>
                <input

                    type="number"
                    className="form-control form-control-sm"
                    id="date-start"
                    value={commonData.duration}
                    onBlur={(e) => {
                        commonData.duration = e.target.value;
                        setCommonData({...commonData})
                    }}
                    onChange={(e) => {
                        commonData.duration = e.target.value;
                        setCommonData({...commonData})
                    }}

                    min={0}
                    placeholder="Duration in mins"
                    disabled={!isCustom() || shouldDisable}
                />
            </div>


        </div>)
    }


    function getDateTimeRow(dtTitle,timeTitle, dayProp, timeProp) {
        let min = moment(commonData.startTime);
        return (<div className="row">
            <div className="form-group col-md-6">
                <label htmlFor="date-start">{dtTitle}</label>
                <input
                    type="date"
                    className="form-control form-control-sm"
                    id="date-start"
                    value={commonData[dayProp]}
                    onChange={(e) => {
                        commonData[dayProp] = e.target.value;
                        setCommonData({...commonData})
                    }}
                    min={min.format("yyyy-MM-dd")}
                    placeholder="Date of Birth"
                    disabled={shouldDisable}
                />
            </div>

            <div className="form-group col-md-6">
                <label htmlFor="time-start">{timeTitle}</label>
                <input
                    type="time"
                    className="form-control form-control-sm"
                    id="time-start"
                    value={commonData[timeProp]}
                    onChange={(e) => {
                        commonData[timeProp] = e.target.value;
                        setCommonData({...commonData})
                    }}
                    min={min.format("hh:mm")}
                    placeholder="Date of Birth"
                    disabled={shouldDisable}

                />
            </div>


        </div>)
    }


    return (
        <>
            {commonData && (
                <>


                    <div className="row">
                        <div className="form-group col-sm-12 ">
                            <label htmlFor="urole">    {i18n.t('forms.employee',"Employee")}
                            </label>
                            <select
                                className="form-control form-control-sm"
                                id="urole"
                                disabled={true}
                                value={commonData.emp}
                                onChange={(e) => {
                                    commonData.emp = e.target.value;
                                    commonData.empId = getUserFromId(availableEmployee, e.target.value);
                                    setCommonData({...commonData})
                                }}
                                required
                            >
                                <option value="-1">    {i18n.t('forms.employee.select',"Select employees...")}
                                </option>
                                {addedEmployees ? (
                                    addedEmployees.map((user) => {
                                        return (
                                            <option key={getUserId(user)} value={getUserId(user)}>
                                                {getUserName(user)}
                                            </option>
                                        );
                                    })
                                ) : (
                                    <option value="">{}</option>
                                )}
                            </select>
                        </div>


                    </div>

                    <div className="row">
                        <div className="form-group col-sm-12 ">
                            <label htmlFor="title">    {i18n.t('job.schedule.form.title',"Title")}
                            </label>
                            <input
                                className="form-control form-control-sm"
                                id="title"
                                type="text"
                                value={commonData.title ? commonData.title : ""}
                                disabled={shouldDisable}

                                onChange={(e) => {
                                    const val = e.target.value;
                                    commonData.title = val;
                                    setCommonData({...commonData})
                                }}
                                required
                            />

                        </div>
                    </div>


                    {getDateTimeRow(i18n.t('job.schedule.form.start-date',"Start Date"),
                        i18n.t('job.schedule.form.start-time',"Start Time"),
                        "sd", "st")}
                    {getDuration()}


                    <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                            <label htmlFor="urole">    {i18n.t('job.schedule.form.repeat',"Repeat")}
                            </label>
                            <select
                                className="form-control form-control-sm"
                                id="urole"
                                value={commonData.recursiveFrequency ? commonData.recursiveFrequency : -1}
                                disabled={shouldDisable}

                                onChange={(e) => {
                                    const val = e.target.value;
                                    if (val != -1) {
                                        commonData.recursiveFrequency = val;

                                    } else {
                                        commonData.recursiveFrequency = null;
                                    }
                                    setCommonData({...commonData})
                                }}
                                required
                            >
                                <option value="-1">    {i18n.t('job.schedule.form.repeat.no',"No")}
                                </option>
                                {(
                                    Object.keys(frequencies).map((key, index) => {
                                        return (
                                            <option key={key} value={key}>
                                                {frequencies[key]}
                                            </option>
                                        );
                                    })
                                )}
                            </select>
                        </div>


                        <div className="form-group col-sm-12 col-md-6 ">
                            <label htmlFor="notiBefore">    {i18n.t('job.schedule.form.notify-before',"Notify before (mins)")}
                            </label>
                            <input
                                type="number"
                                className="form-control form-control-sm"
                                id="notiBefore"
                                value={commonData.notifyBeforeMinutes}
                                onChange={(e) => {
                                    commonData.notifyBeforeMinutes = parseInt(e.target.value);
                                    setCommonData({...commonData})
                                }}
                                min={0}
                                disabled={shouldDisable}

                                placeholder=    {i18n.t('job.schedule.form.notify-before',"Notify before (mins)")}

                            />
                        </div>
                    </div>


                </>


            )}
        </>
    );
}


export function NotificationView({
                                     commonData,
                                     setCommonData,
                                     availableEmployee,
                                     job,
    shouldDisable,
                                     jobLocation,
                                     addedEmployees,
                                     emp
                                 }) {
    function getNotiKVMap() {
        //console.log((addedEmployees);)
        return {

            "ANY": {
                table: "JOB_USER",
                value: commonData.jobHasUserId
            }
        }
    }

    function getUsers() {
        let userFilter = [];
        const item = job ? job : jobLocation;
        userFilter.push(item.clientId);
        if (addedEmployees) {
            addedEmployees.forEach((e) => {
                if (e.roleLevel != 5 && e.jobHasUserId == null) {
                    userFilter.push(e.tenantUserId)

                }
            })
        }
        return userFilter;
    }


    return (
        <>

            <div className="row">
                <div className="col-sm-12">

                    {!shouldDisable&&(
                        <NotificationWidget
                            saveAll={commonData.setSaveData}
                            addedUsers={getUsers()}
                            enableAddUsers={false}
                            mappedId={commonData.jobHasUserId}
                            notificationCategory={'JOB_USER'}
                            notiKVMap={getNotiKVMap()}
                        >

                        </NotificationWidget>

                    )}






                </div>

            </div>
        </>
    );
}