import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/es/storage/session";
import { companyReducer } from "./company";
import { authReducer } from "./auth";
import { userReducer } from "./users";
import { contractReducer } from "./contracts";
import { locationReducer } from "./locations";
import { notificationReducer } from "./notifications";
import { groupReducer } from "./groups";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["company", "auth", "users", "contracts", "locations","notifications"],
};

const rootReducer = combineReducers({
  company: companyReducer,
  auth: authReducer,
  users: userReducer,
  contracts: contractReducer,
  locations: locationReducer,
  notifications: notificationReducer,
  groups: groupReducer

});

export default persistReducer(persistConfig, rootReducer);
