import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import './Message.css';
import swal from "sweetalert";
import userDefaultImg from "../../../../resources/img/user/user-img-default.png";

export default function Message(props) {
  const {
    data,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp
  } = props;

  function openFile(file){
    let wrapper = document.createElement('div');
      ReactDOM.render(
        
          <img 
           className="full-img"
            src={file.url}
          ></img>
      



        , wrapper);
      let el = wrapper.firstChild;
      swal({
        title: data.message,
        html: true,
        type: "info",
        className: 'swal-wide',
        content: el,
        showCancelButton: true,
        showConfirmButton: false
      });
  }

  const friendlyTimestamp = moment(data.timestamp).format('LLLL');
  return (
    <div className={[
      'message',
      `${isMine ? 'mine' : ''}`,
      `${startsSequence ? 'start' : ''}`,
      `${endsSequence ? 'end' : ''}`
    ].join(' ')}>
      {
        showTimestamp &&
        <div className="timestamp">
          {friendlyTimestamp}
        </div>
      }
      <div className="col-sm-12">
        {startsSequence && !isMine&& (
          <div className="row mt-4 mb-2">
            <div className="">
              <img src={
                data.imageURL ? data.imageURL : userDefaultImg
              }
                className="chat-img  avatar-img"
                alt="..." />
            </div>
            <div className="col">
              <h5 className="mt-2"> {data.firstName +
                " " +
                data.lastName} {isMine ? " (You)" : ""}</h5>
              <p> {data.role}</p>
            </div>
          </div>
        )}
        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            {data.message}
          </div>
        </div>

        {data.data && (
          <div className="row">
            {
              data.data.map((file) => {
                return (
                  <div  onClick={
                    ()=>{
                      openFile(file);
                    }
                  }>
                    {
                      file.fileType === 'IMAGE' && (
                        <img className="con-image p-4" style={{ maxWidth: "200px", maxHeight: "300px" }}
                          src={file.url}
                        />
                      )
                    }

                  </div>
                )
              })
            }
          </div>
        )}
      </div>

    </div>
  );
}