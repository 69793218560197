import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getNotificationConfig} from "../../../../api";
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import userDefaultImg from "../../../../resources/img/user/user-img-default.png";
import Icon from "@mdi/react";
import Switch from "@material-ui/core/Switch";
import swal from "sweetalert";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    mdiAccountPlus,
    mdiAlertCircleOutline,
    mdiBriefcaseAccount,
    mdiBriefcaseCheck,
    mdiBriefcaseMinus,
    mdiBriefcaseOff,
    mdiBriefcasePlus,
    mdiChatAlertOutline,
    mdiChatPlus,
    mdiChatPlusOutline,
    mdiDomain,
    mdiFormatListChecks,
    mdiLockCheck,
    mdiMapMarkerPlus,
    mdiMessageDraw,
    mdiMessagePlus,
    mdiMessagePlusOutline,
    mdiSortClockAscendingOutline,
    mdiSortClockDescendingOutline,
    mdiStarSettings,
    mdiStarSettingsOutline,
    mdiAccountClock,
    mdiReminder
} from "@mdi/js";

const splitter = "___";


const gateways = {
    'APP': {
        'icon': '',

    },
    'EMAIL': {
        'icon': ''

    },
    'SMS': {
        'icon': ''
    }
}
export const notiIconMap = {
    "mdiDomain": mdiDomain,
    "mdiAccountPlus": mdiAccountPlus,
    "mdiMapMarkerPlus": mdiMapMarkerPlus,
    "mdiSortClockDescendingOutline": mdiSortClockDescendingOutline,
    "mdiSortClockAscendingOutline": mdiSortClockAscendingOutline,
    "mdiMessagePlusOutline": mdiMessagePlusOutline,
    "mdiBriefcasePlus": mdiBriefcasePlus,
    "mdiChatAlertOutline": mdiChatAlertOutline,
    "mdiChatPlusOutline": mdiChatPlusOutline,
    "mdiChatPlus": mdiChatPlus,
    "mdiMessagePlus": mdiMessagePlus,
    "mdiMessageDraw": mdiMessageDraw,
    "mdiFormatListChecks": mdiFormatListChecks,
    "mdiStarSettings": mdiStarSettings,
    "mdiStarSettingsOutline": mdiStarSettingsOutline,
    "mdiLockCheck": mdiLockCheck,
    "mdiAlertCircleOutline": mdiAlertCircleOutline,
    "mdiBriefcaseCheck": mdiBriefcaseCheck,
    "mdiBriefcaseOff": mdiBriefcaseOff,
    "mdiBriefcaseMinus": mdiBriefcaseMinus,
    "mdiBriefcaseAccount": mdiBriefcaseAccount,
    "mdiAccountClock": mdiAccountClock,
    "mdiReminder": mdiReminder,
}

export function NotificationWidget({
                                       notificationCategory = 'JOB',
                                       mappedId, enableAddUsers = true,
                                       addedUsers, saveAll,
                                       notiKVMap
                                   }) {
    const companyState = useSelector((state) => state.company.profile);
    const allUserData = useSelector((state) => state.users.byId);

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [n_gateways, setN_Gateways] = useState({});
    const [n_types, setN_Types] = useState({});
    const [n_users, setN_Users] = useState([]);
    const [n_subs, setN_Subs] = useState([]);
    const [toAdd, setToAdd] = useState([]);
    const [toRemove, setToRemove] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        _getNotificationConfig(notificationCategory, mappedId);
    }, [notificationCategory, mappedId])

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 700,
        },
        firstCol: {
            maxWidth: 200
        },
        table: {
            alignItems: 'center',
            justifyContent: 'center',
            margin: "auto",

        }, modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "60%",
            margin: "auto"
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();

    async function _getNotificationConfig(notificationCategory, mappedId) {
        const res = await getNotificationConfig(companyState.id, mappedId, notificationCategory);
        if (res.success && res.data) {
            let users = await updateAvailableUsers(res.data);
            processRowsColumnsInverse(res.data, users);
            updateGateways(res.data);

        }
    }

    async function updateAvailableUsers(data) {
        let availableUsers = [];
        let uIds = [];

        const addU=(id)=>{
            availableUsers.push(allUserData[id]);
            uIds.push(id);
        }
        if (data.availableTenantUsers) {

            data.availableTenantUsers.forEach((id) => {
               addU(id);
            });


        }
        if(addedUsers){
            addedUsers.forEach((user)=>{
                addU(user);
            })
        }
        setN_Users([...uIds]);
        return availableUsers;
    }

    function processRowsColumnsInverse(data, users) {
        const cols = processColsInverse(data, users);
        const rows = processRowsInverse(data, cols,);
    }

    function processColsInverse(data, users) {
        let _cols = [];
        _cols[0] = {
            id: 'event',
            name: 'Notification Event'
        }
        let _n_types = {};
        if (users) {
            users.forEach((u) => {


                if (u) {
                    _cols.push({
                        id: u.id,
                        user: u

                    });
                }

            });
        }
        setColumns([..._cols]);
        return _cols;
    }

    function processRowsInverse(data, cols) {
        let _rows = [];
        let subs = [];

        const n_gateways = data.notificationHasGateways;
        const pushToSubs = (sub, g, v) => {
            if (sub) {
                subs.push({
                    key: sub,
                    gateway: g,
                    value: v
                })
            }


        }
        //console.log((data);)
        if (data.notificationSubscribersDTOS) {
            data.notificationSubscribersDTOS.forEach((v) => {

                let _row = [];
                _row.push(v.notificationType);

                cols.forEach((col, i) => {
                    if (i != 0 && col.user) {
                        const [email, e_sub] = isSubscribedSubscribed(col.user.id, 'EMAIL', v, n_gateways);
                        const [app, a_sub] = isSubscribedSubscribed(col.user.id, 'APP', v, n_gateways);
                        const [sms, s_sub] = isSubscribedSubscribed(col.user.id, 'SMS', v, n_gateways);
                        _row[i] = {
                            EMAIL: email,
                            APP: app,
                            SMS: sms,
                            nt: v
                        }

                        pushToSubs(e_sub, 'EMAIL', email);
                        pushToSubs(a_sub, 'APP', app);
                        pushToSubs(s_sub, 'SMS', sms);


                    }
                })


                _rows.push(_row);

            });
        }


        //console.log((subs);)
        setN_Subs([...subs]);
        setRows([..._rows]);

        return _rows;

    }


    function isSubscribedSubscribed(u_id, gateway, n_type, n_gateways) {

        const subs = n_type.subscribers;
        if (subs) {
            for (let index = 0; index < subs.length; index++) {
                const s = subs[index];
                if (s.tenantUserId == u_id && n_gateways[s.notificationHasGatewayId]
                    && n_gateways[s.notificationHasGatewayId]['supportedGateway'] == gateway) {

                    return [{
                        sub_id: s.id,
                        n_gateway_id: s.notificationHasGatewayId
                    }, getSubKey(u_id, n_type.notificationType.event, gateway, s.id)];
                }
            }

        }
        return [null, null];
    }

    function getSubKey(uId, ntId, gateway, sId) {
        if (uId && ntId && gateway) {
            let array = [uId, ntId, gateway]
            if (sId) {
                array.push(sId)
            }

            return array.join(splitter);
        }
        return null;
    }

    function renderCol(col, x, last) {

        return x == 0 ? (
            <TableCell className={classes.firstCol}>

                <label>{col.name}</label>
            </TableCell>


        ) : (

            renderUserCell(col, last)
        );

    }

    function renderUserCell(x, last) {
        const user = x.user;
        return (
            <TableCell style={{
                maxWidth: "200px",
                minWidth:"150px"
            }}>
                <div className="row">

                    <img
                        src={
                            user.imageURL ? user.imageURL : userDefaultImg
                        }
                        className=" card-img-top avatar avatar-img"
                        style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "fit"
                        }}
                        alt="..."
                    />

                    <div className="col">
                        <div style={{
                            textTransform: "capitalize",
                            fontStyle: "oblique"
                        }}>({user.userRole.name.toLowerCase()})
                        </div>
                        <div>{user.name}</div>
                    </div>

                    {last && enableAddUsers && (<button onClick={(e) => {
                        setOpen(true);
                    }} className="btn btn-outline-primary">+</button>)}

                </div>

            </TableCell>
        )
    }


    function renderRow(row, y) {

        return (
            <TableRow>
                {
                    row && row.map((r, x) => {
                            const user = columns[x];
                            return x == 0 ? (

                                <TableCell className={classes.firstCol}>
                                    <Icon path={notiIconMap[r.icon]}
                                          className="mr-2"
                                          title={r.icon}
                                          size={1}
                                          horizontal
                                          vertical
                                          rotate={180}
                                    />
                                    <label>{r.title}</label>
                                </TableCell>

                            ) : (

                                renderNotiCell(user, r)
                            )
                        }
                    )
                }
            </TableRow>

        )

    }

    function renderNotiCell(user, noti) {
        const emailAvailable = noti.EMAIL != undefined;
        const appAvailable = noti.APP != undefined;
        const smsAvailable = noti.SMS != undefined;
        return (
            <TableCell>
                <div className="col">

                    {
                        toggleIButton("Email notification", emailAvailable, user, noti, 'EMAIL', "mdi mdi-email-alert")
                    }
                    {
                        toggleIButton("App notification", appAvailable, user, noti, 'APP', "mdi mdi-cellphone")

                    }
                    {
                        toggleIButton("SMS notification", smsAvailable, user, noti, 'SMS', "mdi mdi-message-bulleted")

                    }

                </div>
            </TableCell>
        )
    }

    function toggleIButton(title, enabled, user, noti, gateway, icon) {
        return (

            <div className="">
                <Typography component="div">
                    <Grid title={title} component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                            <Switch size="small"
                                    color="primary"
                                    checked={enabled} onChange={() => {
                                subscribe(user, noti, gateway, !enabled)
                            }} name="checkedC"/>
                        </Grid>
                        <Grid item><i className={icon}></i></Grid>
                    </Grid>
                </Typography>


            </div>
        );
    }

    function subscribe(user, item, gateway, subscribe) {
        //console.log((item);)
        if (subscribe) {
            _subscribe(user, item.nt, gateway);
            item[gateway] = {
                new: true,
                user: user.id,
                gateway: gateway
            }
        } else {
            _unsubscribe(user, item.nt, gateway);

            item[gateway] = null;
        }
        setRows([...rows]);
        save();
        //console.log((toAdd, toRemove);)
    }

    function containsInSubs(uId, nt, gateway) {
        const key = getSubKey(uId, nt, gateway);

        for (let index = 0; index < n_subs.length; index++) {
            const n_sub = n_subs[index];
            if (n_sub.key.includes(key)) {
                return [key, n_sub];
            }

        }
        return [key, null];
    }

    function _subscribe(user, nt, gateway) {
        const [key, sub] = containsInSubs(user.id, nt.notificationType.event, gateway);
        if (sub) {
            const index = toRemove.indexOf(sub.value.sub_id);
            if (index != -1) {
                toRemove.splice(index, 1);
                setToRemove([...toRemove]);
            }
            return sub.value;
        } else {
            const index = toAdd.indexOf(key);
            if (index == -1) {
                toAdd.push(key);
                setToAdd([...toAdd]);
            }
        }

    }

    function _unsubscribe(user, nt, gateway) {
        const [key, sub] = containsInSubs(user.id, nt.notificationType.event, gateway);
        if (sub) {
            const index = toRemove.indexOf(sub.value.sub_id);
            if (index == -1) {
                toRemove.push(sub.value.sub_id);
                setToRemove([...toRemove]);
            }

        } else {
            const index = toAdd.indexOf(key);
            if (index != -1) {
                toAdd.splice(index, 1);
                setToAdd([...toAdd]);
            }
        }

    }

    function updateGateways(data) {
        if (data.notificationHasGateways) {
            setN_Gateways({...data.notificationHasGateways});
        }

    }

    function addNewUser(u) {

        n_users.push(u.id);
        setN_Users([...n_users]);

        columns.push({
            id: u.id,
            user: u
        });
        setColumns([...columns]);

        rows.forEach((row, i) => {

            row[row.length] = {
                EMAIL: null,
                APP: null,
                SMS: null,
                nt: row[row.length - 1].nt
            }

        })
        setRows([...rows]);
    }

    const SelectUser = () => {


        const [userId, SetUserId] = useState(-1);
        useEffect(() => {
            SetUserId(-1);

        }, []);

        async function addUserToNoti(e) {
            if (userId != -1) {
                await addNewUser(allUserData[userId])
                setOpen(false);
            } else {
                swal({
                    title: "Empty",
                    text: "Select a user",
                    icon: "error"
                });
            }
        }


        const notExistingUser = (e) => {
            return n_users.indexOf(parseInt(e)) == -1;
        }
        return (
            <div className="card col-sm-12 grid-margin stretch-card">
                <div className="card-body text-align-left">

                    <h4 className="card-title">Add new user</h4>
                    <p className="card-description">Add new user for notification</p>

                    <div className="forms-sample">


                        <div className="row">


                            <div className="form-group col-sm-12">
                                <label htmlFor="user">Users</label>
                                <select
                                    className="form-control form-control-sm"
                                    id="users"
                                    onChange={(e) => {
                                        SetUserId(e.target.value);
                                    }}
                                    required
                                >
                                    <option value="-1">Select User...</option>
                                    {allUserData &&
                                    Object.keys(allUserData)
                                        .filter(notExistingUser).map((k) => {
                                        const v = allUserData[k];
                                        return (

                                            <option value={k} key={k}
                                                    dataThumbnail={v.imageURL ? v.imageURL : userDefaultImg}>


                                                {v.userRole.name + ". " + v.name}
                                            </option>
                                        );

                                    })}
                                </select>
                            </div>

                        </div>
                        <div className="row justify-flex-end">
                            <button className="btn btn-outline-secondary mr-2"
                                    type="button"

                                    onClick={
                                        () => {

                                            setOpen(false);

                                        }
                                    }

                            >Cancel
                            </button>


                            <button className="btn btn-primary"
                                    type="button"
                                    disabled={userId == -1}

                                    onClick={
                                        () => {

                                            addUserToNoti();

                                        }
                                    }

                            >Add
                            </button>
                        </div>
                    </div>


                </div>

            </div>

        );

    }

    async function save() {
        const data = {
            unsubscribeIds: toRemove,
            tenantId: companyState.id,
            notificationEventDtos: getToAddDtos()
        }
        //console.log((data);)
        saveAll(data);
    }

    function getToAddDtos() {
        const add = [];

        toAdd.forEach((a) => {
            const split = a.split(splitter);
            const i = notiKVMap && notiKVMap[split[1]] ? notiKVMap[split[1]] : notiKVMap['ANY'];
            add.push({
                event: split[1],
                referenceId: i ? i.value : mappedId,
                notificationMappedTable: i.table,
                notificationEventGatewayDtos: [{
                    supportedGateway: split[2],
                    tenantUserIds: [split[0]]
                }]
            });
        });
        return add;
    }

    return (
        <div>


            <TableContainer className={classes.container}>
                <Table stickyHeader stickyHeader aria-label="sticky table"
                       className={classes.table}
                       options={{
                           fixedColumns: {
                               left: 1,
                               right: 0
                           }
                       }}
                >
                    <TableHead>
                        <TableRow>
                            {columns && columns.length > 0 && columns.map((col, x) => {
                                return (
                                    renderCol(col, x, (x === columns.length - 1))
                                )
                            })}
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {rows && rows.length > 0 && rows.map((row, y) => {
                            return (
                                renderRow(row, y)
                            )
                        })}
                    </TableBody>

                </Table>
            </TableContainer>

            <Modal
                open={open}
                className={classes.modal}
                onClose={() => {
                    setOpen(false)
                }}
                closeAfterTransition

                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <SelectUser
                    >
                    </SelectUser>
                </Fade>

            </Modal>
        </div>
    )
}