import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState: initState,
  reducers: {
    setUsers(state, { action, payload }) {
      for (var user of payload) {
        const { users, ...rest } = user;
        if (!(user.id in state.byId)) {
          state.byId[user.id] = rest;
          state.allIds.push(user.id);
        }
      }
    },

    clearUsers(state,{action}){
      state.byId={};
      state.allIds=[];
    }
    // setUserUpdate(state, { action, payload }) {
    //   state.byId[payload.id] = payload;
    // },
    // removeUser(state, { action, payload }) {
    //   state.allIds = state.allIds.filter((id) => id !== payload);
    //   delete state.byId[payload];
    // },
  },
});

export const userActions = usersSlice.actions;
export const userReducer = usersSlice.reducer;

export function setUsers(data) {
  return (dispatch, getState) => {
    try {
      dispatch(userActions.setUsers(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting users"));
    }
  };
}
export function clearUsers() {
  return (dispatch, getState) => {
    try {
      dispatch(userActions.clearUsers());
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting users"));
    }
  };
}

// export function createUser(data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.createUser(data);
//       dispatch(userActions.setUserUpdate(response.data));
//       dispatch(showToastSuccess("User created", "Created succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function updateUser(userId, data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.updateUser(userId, data);
//       dispatch(userActions.setUserUpdate(response.data));
//       dispatch(showToastSuccess("User updated", "Updated succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function deleteUser(userId) {
//   return async (dispatch, getState) => {
//     try {
//       await api.deleteUser(userId);
//       dispatch(userActions.removeUser(userId));
//       dispatch(showToastSuccess("User deleted", "Deleted succesfully!"));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error deleting user"));
//     }
//   };
// }
