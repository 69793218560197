import React, { useState, useEffect } from 'react';
import ConversationSearch from '../ConversationSearch';
import { useSelector, useDispatch } from "react-redux";
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';

import './ConversationList.css';
import i18n from "i18next";

export default function ConversationList(props) {



  return (
    <div className="conversation-list">
      <Toolbar
        title=    {i18n.t('chat.conversations',"Conversations")}

        leftItems={[
          <ToolbarButton key="cog" icon="ion-ios-cog" />
        ]}
        rightItems={[
          <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
        ]}
      />
      <ConversationSearch />
      {
        props.conversationGroups.map((conversationGroup, i) =>
          <ConversationListItem

            setSelectedConGroup={props.setSelectedConGroup}
            key={conversationGroup[0].name + "_" + i}
            data={conversationGroup}
          />
        )
      }
    </div>
  );
}