import { createGlobalStyle } from "styled-components";
import { COLOR_PROFILE } from "../config";

const colors = JSON.parse(sessionStorage.getItem(COLOR_PROFILE));

const incrementColor = (color, stepr) => {
  var colorToInt = parseInt(color.substr(1), 16),
    nstep = parseInt(stepr);
  if (!isNaN(colorToInt) && !isNaN(nstep)) {
    colorToInt += nstep;
    var ncolor = colorToInt.toString(16);
    ncolor = "#" + new Array(7 - ncolor.length).join(0) + ncolor;
    if (/^#[0-9a-f]{6}$/i.test(ncolor)) {
      return ncolor;
    }
  }
  return color;
};

const GlobalStyle =
  colors &&
  createGlobalStyle`
.sidebar {
    background-color: ${colors.colorBackground} !important;
}

@media (min-width: 992px) {
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title,
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
        background: ${colors.colorBackground} !important;
        box-shadow: none !important;
    }
}

.sidebar .nav .nav-item.active>.nav-link,
.sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link {
    background-color: ${incrementColor(colors.colorBackground, 723723)};
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: ${colors.colorTextInactive};
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
    color: ${incrementColor(colors.colorBackground, 9539985)};
}

.bg-primary,
.button-primary,
.badge-primary,
.table-primary,
.btn-inverse-primary,
.card-primary,

.card-inverse-primary {
  background-color: ${colors.colorMain} !important;
}


.btn-primary{
background-color: ${colors.colorMain} !important;
border-color: ${colors.colorMain} !important;
}


.btn-primary:hover{
  text-shadow: -1px -1px 17px rgba(0,0,0,1);
background-color: ${colors.colorMain} !important;
border-color: ${colors.colorMain} !important;
}
.text-primary {
  color: ${colors.colorMain} !important;
}

.btn-outline-primary {
  color: ${colors.colorMain} !important;
  border-color: ${colors.colorMain} !important;
  border: 1px solid;
}
.btn-outline-primary: hover{
background-color: ${colors.colorMain}e3 !important;
color: white !important;
}

`;

export default GlobalStyle;
