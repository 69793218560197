import React, { useEffect, useState } from 'react';


import './ConversationListItem.css';

export default function ConversationListItem(props) {
  const conversationGroup = props.data;

  

  function selectConGroup() {
    props.setSelectedConGroup(conversationGroup);
    //console.log((conversationGroup);)
  }

  return (
    <div>
      <div onClick={(e) => selectConGroup()} className="conversation-list-item flex justify-content-between" >
        
        <div className="row flex align-center ml-2 streched" >

          <img className="conversation-photo img-scale-down" src={conversationGroup[0].imageURL} alt="conversation" />
          <div className="conversation-info">
            <h1 className="conversation-title">{conversationGroup[0].name}</h1>
          </div>


        </div>
      </div>


      <hr />
    </div>

  );
}