import { PageLayout } from "../../../components/pageLayout";
import { ReportFilter } from "./shared/filter";



export function KPI(props) {


    async function onSiteChange(e){

    }

    async function onDateRangeChange(e){
        
    }
    return (
        <PageLayout>
        <div className="content-wrapper job-location">
            <div className="col-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">{props.location.pathname}</h4>
                        <p className="card-description"></p>

                    </div>
                </div>
            </div>
        </div>
        </PageLayout>
    );

}
