import { PageLayout } from "../../../components/pageLayout";
import { ReportFilter } from "./shared/filter";
import React, { useState, useEffect, setState, createRef } from "react";
import { getSitePayroll, getUserPayroll, downloadPayrollReport } from "../../../api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { formatDate } from "../../../utils/dateUtils";
import { store } from "../../../state/store";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { _viewWork } from "./payrollSite";
import { WorkSummaryEdit } from "./shared/workSummaryEdit";
import { filterJobLocationsForGroupsById } from "../../../utils/objectFilter"
import Button from "@material-ui/core/Button";
import {Menu, MenuItem} from "@material-ui/core";
import i18n from "i18next";


export function UserPayroll() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);

    const allLocationIds = useSelector((state) => state.locations.byId);


    const [selectedUser, setSelectedUser] = useState(-1);
    const [payroll, setPayroll] = useState({});


    const allGroupData = useSelector((state) => state.groups.byId);
    const [groups, setGroups] = useState([]);

    
    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });


    async function onUserChange(e) {
        setSelectedUser(e.target.value);
    }

    async function onDateRangeChange(st, en) {
        setDates({ start: st, end: en });
    }
    async function _loadReport() {
        let error = "";
        if (selectedUser == -1) {
            error = 'Please select a employee.';
        }

        let groupIds = null;

        if (groups && groups.length > 0) {
            groupIds = groups.map((g) => { return g.id });
        }
        if (error === '') {
            const res = await getUserPayroll(companyState.id, selectedUser, groupIds, formatDate(dates.start), formatDate(dates.end));
            if (res && res.data) {
                setPayroll(res.data);
            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }


    async function downloadPdf(reportType) {
        if (reportType == null) {
            reportType='CSV';
        }
        let error = "";
        if (selectedUser == -1) {
            error = 'Please select a employee.';
        }

        if (error === '') {
            const res = await downloadPayrollReport("USER", companyState.id, selectedUser, null, formatDate(dates.start), formatDate(dates.end),reportType);

            if (res && res.data) {

                let binaryString = window.atob(res.data);
                let binaryLen = binaryString.length;

                let bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    let ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                let blob = new Blob([bytes], { type:  reportType=='PDF'?"application/pdf":'text/csv' });

                let link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                var user = allClientData[selectedUser];
                link.download = user.user.firstName + " " + user.user.lastName + "."+reportType.toLowerCase();

                link.click();

            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }



    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">  {i18n.t('reports.user-payroll.title',"Site Payroll Report")}
                            </h4>
                            <p className="card-description"> {i18n.t('reports.user-payroll.description',"Generates payroll report a job location")}
                            </p>

                            <div className="row">
                                <div className="mt-2 mb-2 filter col-sm-12">
                                    <ReportFilter
                                        key="reportFilter2"
                                        onUserChange={onUserChange}
                                        onDateRangeChange={onDateRangeChange}
                                        allUserIds={Object.values(allClientData)}
                                        userFilter={true}
                                        start={dates.start}
                                        end={dates.end}
                                        groupSelection={allGroupData && Object.keys(allGroupData).length > 0}
                                        groups={groups}
                                        setGroups={setGroups}
                                        allGroups={Object.values(allGroupData)}

                                    >
                                    </ReportFilter>
                                </div>
                            </div>



                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                        onClick={(e) => _loadReport()}
                                        className="btn btn-primary btn-lg">   {i18n.t('btn.generate',"Generate")}
                                    </button>

                                </div>
                            </div>





                        </div>
                    </div>

                </div>
                {
                    payroll && payroll.payrollType && (
                        <div className="row">
                            <div className="col-sm-12">
                                <PayrollReport
                                    key="sitePayrollReport"
                                    payroll={payroll}
                                    selectedUser={selectedUser}
                                    downloadPdf={downloadPdf}
                                    _loadReport={_loadReport}
                                >
                                </PayrollReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}


export function PayrollReport(props) {
    const ref = createRef();
    const payroll = props.payroll;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    function getFileName() {
        return "selectedSpec.pdf";
    }
    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">  "reports.user-payroll.title": "User Payroll Report",
                            </h4>

                            <Button aria-controls="simple-menu"   className="btn btn-primary" variant="contained"  color="success"
                                    size="large"  aria-haspopup="true" onClick={handleClick}>
                                {i18n.t('btn.download',"Download")}

                            </Button>
                            <Menu
                                id="simple-menu"


                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={()=>props.downloadPdf('PDF')}>    {i18n.t('report.download-type.pdf',"As PDF")}
                                </MenuItem>
                                <MenuItem onClick={()=>props.downloadPdf('CSV')}> {i18n.t('report.download-type.csv',"As CSV")}
                                </MenuItem>     </Menu>

                        </div>

                        <Report
                            selectedUser={props.selectedUser}
                            payroll={payroll}
                            _loadReport={props._loadReport}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

export function Report(props) {
    const payroll = props.payroll;
    const allLocationIds = useSelector((state) => state.locations.byId);
    const allClientData = useSelector((state) => state.users.byId);
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const [open, setOpen] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [workId, setWorkId] = useState(-1);
    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "80%",
            margin: "auto"
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();
    function getClassname(success) {
        return success ? "display-4 text-five" : "display-4 text-one";
    }
    return (
        <div if="pdf">



            <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>

            <Modal
                open={open}
                className={classes.modal}
                onClose={() => { setOpen(false) }}
                closeAfterTransition

                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <WorkSummaryEdit
                        companyId={companyState.id}
                        workId={workId}
                        setOpen={setOpen}
                        _loadReport={props._loadReport}
                    >

                    </WorkSummaryEdit>
                </Fade>

            </Modal>


            <div className="row">
                <div className="col-sm-12 mt-5">
                    <ReportBasiicInfo
                        payroll={props.payroll}
                        selectedUser={props.selectedUser}
                    >
                    </ReportBasiicInfo>
                </div>
            </div>

            {
                payroll && payroll.payrollDetail &&

                (
                    <>
                        <div className="col-lg-12 mt-4">
                            <table className="table-custom">
                                <thead>
                                    <tr>
                                        <th>{i18n.t('report.header.date',"Date")}
                                        </th>
                                        <th>    {i18n.t('report.header.client-site',"Client/ Job Site")}
                                        </th>
                                        <th>{i18n.t('report.header.breaks',"Breaks")}
                                        </th>
                                        <th>{i18n.t('report.header.on-break',"On Break")}
                                        </th>

                                        <th>{i18n.t('report.header.clock-in',"Clock in")}
                                        </th>
                                        <th>{i18n.t('report.header.clock-out',"Clock out")}
                                        </th>
                                        <th>{i18n.t('report.header.service-time',"Service time")}
                                        </th>
                                        <th> {i18n.t('report.header.actions',"Actions")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(payroll.payrollDetail).map(
                                            (item) => {
                                                return (
                                                    <PayrollItem
                                                        allLocationIds={allLocationIds}
                                                        allClientData={allClientData}
                                                        item={item}
                                                        setOpen={setOpen}

                                                        workId={workId}
                                                        setWorkId={setWorkId}
                                                    />

                                                )
                                            }
                                        )
                                    }
                                </tbody>
                                <tfoot className="border-top border-bottom">
                                    <tr>
                                        <td colSpan="2" className="font-weight-bold" style={{ textAlign: "end", paddingRight: "20px" }}>    {i18n.t('report.footer.total',"Total")}
                                        </td>
                                        <td className="font-weight-bold">{getDuration(payroll.totalOnBreakTime)}</td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td className="font-weight-bold">{getDuration(payroll.totalServiceTime)}</td>
                                    </tr>

                                </tfoot>

                            </table>

                        </div>






                    </>


                )

            }

        </div>
    )
}

const getDuration = (time) => {
    if (!time) {
        time = 0;
    }
    const duration = moment.duration(time);

    const h = duration.hours();
    const m = duration.minutes();
    const s = duration.seconds();

    const hr = h === 0 ? "00: " : ((h < 10 ? "0" + h : h) + ": ");
    const mi = m === 0 ? "00: " : ((m < 10 ? "0" + m : m) + ": ");
    const sr = s === 0 ? "00" : (s < 10 ? "0" + s : s);

    return hr + "" + mi + "" + sr;

}

export function PayrollItem(props) {
    const item = props.item;
    const payroll = props.item;
    const allClientData = props.allClientData;
    const allLocationIds = props.allLocationIds;
    const client = allClientData[item.clientId];
    const jobLocation = allLocationIds[item.jobLocationId];
    var breakTime = moment.duration(item.breakTime);
    var serviceTime = moment.duration(item.serviceTime);
    var dailyQuote = moment.duration(item.dailyQuote);
    const large = moment(payroll.clockOut).date() !== moment(payroll.clockIn).date();
    async function _editWork(workid) {

        if (workid && workid != -1) {
            props.setWorkId(workid);
            props.setOpen(true);

        }


    }

    return (
        <tr>
            <td>{moment(payroll.date).format('DD/MM/YYYY')}</td>
            <td>{client.user.firstName + "/ " + jobLocation.name + ", "}<span className="text-sm">{jobLocation.address}</span></td>

            <td >{item.breakCount}</td>
            <td >{getDuration(item.breakTime)}</td>


            <td>{moment(payroll.clockIn).format('HH:mm:ss')}</td>

            <td>{
                moment(payroll.clockOut).format('HH:mm:ss')}{large && (<span className="text-sm">{moment(payroll.clockOut).format('(Do)')}</span>)}
            </td>

            <td >{getDuration(item.serviceTime)}</td>

            <td>
                <div className="btn-action">
                    <button className={(item.editHistory > 0) ? "btn btn-sm  btn-fw  btn-outline-success " : "btn btn-sm  btn-fw  btn-outline-primary"}

                        onClick={() => { _editWork(item.workId) }}
                    ><i className="mdi mdi-pencil"></i></button>
                    <button className="btn btn-sm btn-outline-primary btn-fw"

                        onClick={() => { _viewWork(item.workId) }}
                    ><i className="mdi mdi-eye"></i></button>
                </div>

            </td>
        </tr>
    );
}



export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const payroll = props.payroll;
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);
    const user = allClientData[props.selectedUser];
    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }

    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>
                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">Started Date</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.startTime).format('MMMM D, YYYY')}</td>

                                <td className="font-weight-bold p-0  pl-2">Started Time</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.startTime).format('HH:mm:SS')}</td>
                            </tr>

                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">End Date</td>
                                <td className="pl-2 p-0 pb-0 pt-1 ">{moment(payroll.endTime).format('MMMM D, YYYY')}</td>

                                <td className="font-weight-bold p-0 pb-0  pl-2">End Time</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.endTime).format('HH:mm:SS')}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">Name</td>
                                <td className="pl-2 p-0 pb-0 pt-1 ">{user.user.firstName + " " + user.user.lastName}</td>

                                <td className="font-weight-bold p-0 pb-0  pl-2">Employee Id</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{user.employeeNumber}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">ABN No</td>
                                <td className="pl-2 p-0 pb-0 pt-1 ">{user.businessNumber}</td>

                                <td className="font-weight-bold p-0 pb-0  pl-2">Tax File No</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{user.taxNumber}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


