import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import { getUserRoles, signUp, uploadProfilePicture, updateUser } from "../../../api";

import { PageLayout } from "../../../components/pageLayout";
import ImageUploader from "react-images-upload";
import { useHistory } from "react-router-dom";
import {hideLoader,showLoader} from '../../../utils/loader';
import { formatDate } from "../../../utils/dateUtils";
import i18n from "i18next";
let currentUser = null;

export function UserRegistration(props) {
  const userState = useSelector((state) => state.auth.profile);
  const companyState = useSelector((state) => state.company.profile);
  const [error, setError] = useState("");
  const [userRoles, setUserRoles] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [user, setUser] = useState({});
  const allData = useSelector((state) => state.users.byId);
  const [isEditMode, setEditMode] = useState(false);
  let history = useHistory();
 let imageUploader=useRef();
  useEffect(async () => {
    const isEdit = props && props.location && props.location.pathname.includes('/edit');
    setEditMode(isEdit);
    setError("");
    if (isEdit) {
      const id = new URLSearchParams(props.location.search).get("id")
      let user = { ...allData[id] };
      user.firstName = user.user.firstName;
      user.lastName = user.user.lastName;
      user.email = user.user.email;
      user.dob = formatDate(user.user.dateOfBirth);
      user.phone = user.user.phone;
      user.address = user.user.address;
      user.tenantId = companyState.id;
      currentUser = user;
      //console.log((user);)
      setUser(user);

    } else {
      _setUser(emptyUser);

    }
    const res = await getUserRoles(companyState.id);

    try {
      if (res.success) {
        setUserRoles(res.data);
      } else {
        setError("Loading Error!");
      }
    } catch (err) {
      setError("Network Error!");
    }
  }, []);


 
  const emptyUser = {
    employeeNumber: "",
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    dob: "",
    email: "",
    timezone: "",
    userRoleId: -1,
    businessNumber: '',
    taxNumber: '',
    password: '',
    tenantId: companyState.id,
  };
  function _setUser(user) {


    setUser(user);
  }

  useEffect(() => {
    if (error) {
      swal({
        title: 'error',
        text: error,
        icon: 'error'
      });
    }
  }, [error]);

  const registerUser = async (e) => {
    // showLoader();
    setError("");
    e.preventDefault();
    
    // //console.log((user);)
    // //console.log((profileImage);)
    if (!error || error.length == 0) {
      let res = null;
      delete user['imageURL'];
      if (!isEditMode) {
        res = await signUp(user);

      } else {
        res = await updateUser(user.id, user);
      }
      if (res && res.success) {
        let id = res.data;
        if (isEditMode) {
          id = user.id;
        }
        let res2;
        if (profileImage) {
          res2 = await uploadProfilePicture(id, profileImage);
          if(res2.success && isEditMode){
            user.imageURL = res2.data;
            setUser({ ...user });
  
          }
          
        }
        if (!profileImage || (res2 && res2.success)) {
          hideLoader();
          swal({
            title: 'Success',
            text: 'New User added',
            icon: 'success'
          }).then(() => {
            
            if (isEditMode) {
              history.goBack();
            }else{
              _setUser(emptyUser);

            }
          }

          )
        } else {
          // hideLoader();
          swal(res && res.message ? res.message : "Something went wrong");
        }
      } else {
        // hideLoader();
        swal(res && res.message ? res.message : "Something went wrong");
      }

    }

    // try {
    //   if (res.data.success) {
    //     setError("User Created!");
    //   } else {
    //     setError("Faild to Create User!");
    //   }
    // } catch (err) {
    //   if (err.response) {
    //     setError("Invalid Email or Password!");
    //   } else if (err.request) {
    //     setError("Network Error. Check Your Internet Connection!");
    //   } else {
    //     setError("Network Error!");
    //   }
    // }
  };
  function saveImage(images) {
    if (images.length > 0) {
      setProfileImage(images[0]);
      const reader = new FileReader();
      reader.readAsDataURL(images[0]);
      reader.onload = () => {
        user.imageURL = reader.result;
        setUser({ ...user });
      }
    } else {
      setProfileImage(null);
      setUser({ ...user });
    }

  }
  function getUserProp(prop, isNaN) {
    return user && user[prop] ? user[prop]
      : isNaN ? -1 : '';
  }
  function setUserProp(prop, val) {
    user[prop] = val;
    setUser({ ...user });
  }
  return (
    <PageLayout>
      <div className="content-wrapper">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{isEditMode ?     i18n.t('user.update.title',"Update User")
                  :     i18n.t('user.add.title',"Add User")
              }</h4>
              <p className="card-description">User Details</p>
              <form className="forms-sample" onSubmit={registerUser}>

                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <ImageUploader
                      withIcon={true}
                      singleImage={true}
                      buttonText=    {i18n.t('user.form.upload-profile-pic',"Upload profile picture")}

                      onChange={saveImage}
                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                      maxFileSize={5242880}
                      withPreview={true}
                      defaultImages={getUserProp('imageURL') !== '' ? [getUserProp('imageURL')] :profileImage?[profileImage]:[] }
                      inputRef={iu => imageUploader = iu}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="eid">    {i18n.t('user.form.emp-id',"Employee ID")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="eid"
                      placeholder=    {i18n.t('user.form.emp-id',"Employee ID")}

                      value={getUserProp('employeeNumber')}
                      onChange={(e) => { setUserProp('employeeNumber', e.target.value) }}
                      required={!isEditMode}
                    />
                  </div>

                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="urole">    {i18n.t('user.form.role',"Designation")}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="urole"
                      value={getUserProp('userRoleId')}
                      onChange={(e) => { setUserProp('userRoleId', e.target.value) }}
                      required
                    >
                      <option value="-1">    {i18n.t('user.form.role.select',"Select role...")}
                      </option>
                      {userRoles ? (
                        userRoles.map((role) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })
                      ) : (
                          <option value=""></option>
                        )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="fname">    {i18n.t('user.form.fname',"First Name")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="fname"
                      placeholder={i18n.t('user.form.fname',"First Name")}
                      value={getUserProp('firstName')}
                      onChange={(e) => { setUserProp('firstName', e.target.value) }}
                      required={!isEditMode}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="lname">    {i18n.t('user.form.lname',"Last Name")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="lname"
                      placeholder= {i18n.t('user.form.lname',"Last Name")}
                      value={getUserProp('lastName')}
                      onChange={(e) => { setUserProp('lastName', e.target.value) }}
                      required={!isEditMode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="dob">    {i18n.t('user.form.dob',"Date of Birth")}
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id="dob"
                      value={getUserProp('dob')}
                      onChange={(e) => { setUserProp('dob', e.target.value) }}
                      placeholder=    {i18n.t('user.form.dob',"Date of Birth")}

                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="phone"
                      value={getUserProp('phone')}
                      onChange={(e) => { setUserProp('phone', e.target.value) }}
                      placeholder="Phone Number"
                      required={!isEditMode}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">    {i18n.t('user.form.address',"Address")}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="address"
                    value={getUserProp('address')}
                    onChange={(e) => { setUserProp('address', e.target.value) }}
                    placeholder=    {i18n.t('user.form.address',"Address")}

                  />
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="email">    {i18n.t('user.form.email',"Email address")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      id="email"
                      placeholder=    {i18n.t('user.form.email',"Email address")}

                      value={getUserProp('email')}
                      onChange={(e) => { setUserProp('email', e.target.value) }}
                      disabled={isEditMode}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="password">    {i18n.t('user.form.password',"Password")}
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      id="password"
                      placeholder=    {i18n.t('user.form.password',"Password")}

                      minLength="6"
                      value={getUserProp('password')}
                      onChange={(e) => { setUserProp('password', e.target.value) }}
                      required={!isEditMode}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="businessNumber">    {i18n.t('user.form.business-number',"Business Number")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="businessNumber"
                      placeholder=    {i18n.t('user.form.business-number',"Business Number")}

                      value={getUserProp('businessNumber')}
                      onChange={(e) => { setUserProp('businessNumber', e.target.value) }}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="taxNumber">    {i18n.t('user.form.tas-number',"Tax Number")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="taxNumber"
                      placeholder=    {i18n.t('user.form.tas-number',"Tax Number")}

                      value={getUserProp('taxNumber')}
                      onChange={(e) => { setUserProp('taxNumber', e.target.value) }}
                    />
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  {isEditMode ?     i18n.t('btn.update',"Update")
                      : i18n.t('btn.submit',"Submit")}
                </button>
                {!isEditMode && (<button type="reset" className="btn btn-light">
                  {i18n.t('btn.cancel',"Cancel")}

                </button>)}
              </form>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
