import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import logoImg from "../../resources/logo.svg";

import { signIn } from "../../state/auth";
import i18n from "i18next";

export function UserAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const authUser = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const res = await dispatch(signIn(email, password, company.profile.id));

      if (res && res.success) {
        window.location.href = "/";
      }
    } catch (err) {
      if (err.response) {
        setError("Invalid Email or Password!");
      } else if (err.request) {
        setError("Network Error. Check Your Internet Connection!");
      } 
    }
  };

  useEffect(() => {
    if (error) {
      swal(error);
    }
  }, [error]);

  return (
    <div>
      <div className="row justify-content-between">
        <div className="brand-logo">
          <img src={company.profile.logoURL ? company.profile.logoURL:logoImg} alt="logo" />
        </div>
        <div className="float-right mr-3">
          <button
              className="btn btn-sm btn-primary"
              type="button"
              onClick={()=>window.location.href = "/logout"}
          >
            <i className="mdi mdi-arrow-left"></i>
          </button>
        </div>
      </div>

      <h4>{i18n.t('auth.sign.title','Hello! let\'s get started')}</h4>
      <h6 className="font-weight-light">{i18n.t('auth.sign.description','Sign in to continue.')} </h6>
      <form
        className="pt-3"
        onSubmit={(e) => {
          authUser(e);
        }}
      >
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={i18n.t('auth.sign.form.email.placeholder',"Email Address")}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-lg"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={i18n.t('auth.sign.form.pw.placeholder',"Password")}
          />
        </div>


        <div className="">
          <a  className="btn btn-link btn-rounded btn-fw"
            href="/password-reset"
          >Forgot Password</a>
        </div>
        <div className="mt-3">
          <button
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
            type="submit"
          >
            {i18n.t('btn.sign-in',"SIGN IN")}
          </button>
        </div>

      </form>
    </div>
  );
}
