import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import swal from "sweetalert";

import {validateCompany} from "../../api";
import * as apiActions from "../../state/company";

import {COLOR_PROFILE} from "../../config";
import {loadLanguage} from "../../utils/languageUtil";

export function CompanyAuth(props) {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [companyName, setCompanyName] = useState("");

    const authCompany = async (e) => {
        e.preventDefault();
        setError("");
        try {
            const res = await validateCompany(companyName);
            if (res && res.success) {
                dispatch(apiActions.saveCompanyData(res.data));
                const colors = {
                    colorBackground: res.data.colorBackground,
                    colorMain: res.data.colorMain
                };
                sessionStorage.setItem(COLOR_PROFILE, JSON.stringify(colors));
                props.companyCompleted(true);
                loadLanguage(res.data);
            }
        } catch (err) {
            if (err.response) {
                setError(err.response.data.message);
            } else if (err.request) {
                setError("Network Error. Check Your Internet Connection!");
            } else {
                setError("Network Error!");
            }
        }
    };

    useEffect(() => {
        if (error) {
            swal(error);
        }
    }, [error]);

    return (
        <div>
            <div className="brand-logo">
                <img src="images/logo.svg" alt="..."/>
            </div>
            <h4>First things first!</h4>
            <h6 className="font-weight-light">Enter your company code.</h6>
            <form
                className="pt-3"
                onSubmit={(e) => {
                    authCompany(e);
                }}
            >
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Company ID"
                        required
                    />
                </div>
                <div className="mt-3">
                    <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>
    );
}
