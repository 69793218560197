import React, { useEffect, useState } from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from '../../../../utils/loader'
import './MessageList.css';
import userDefaultImg from "../../../../resources/img/user/user-img-default.png";
import i18n from "i18next";


var tempMessages = [];
var selectedCon={};
export default function MessageList(props) {
  const userState = useSelector((state) => state.auth.profile);
  var MY_USER_ID = userState.tenantUserId;
  const allUsers = useSelector((state) => state.users.byId);
  const conversationState = useSelector((state) => state.conversation);
 
  

  const getUser = (id) => {
    if (allUsers[id]) {
      return allUsers[id];
    }
    return {
      user:{
        firstName:"Removed user",
        lastName:"",

      },
      imageURL:userDefaultImg,
      userRole:{
        name:"Unknown"
      }
    }
  }

  const getMessages = (con) => {

    showLoader();
    tempMessages = [];

    if (con.messages) {
      props.messages.forEach((message, i) => {
       const tu= getUser(message.sentUserId);
        tempMessages.push(
          {
            id: message.id,
            author: message.sentUserId,
            firstName: tu.user.firstName,
            lastName: tu.user.lastName,
            imageURL: tu.imageURL,
            role: tu.userRole.name,
            message: message.text,
            data: message.data,
            timestamp: new Date(message.sentTime).getTime()
          }

        )
      })
    }
    hideLoader();
    return tempMessages;
  }

  const renderMessages = (con) => {
    if(con){

     selectedCon={...con};
    }
    let tempMessages = getMessages(con);
    let i = 0;
    let messageCount = tempMessages.length;
    let messageViews = [];
    while (i < messageCount) {
      let previous = tempMessages[i - 1];
      let current = tempMessages[i];
      let next = tempMessages[i + 1];
      let isMine = current.author === MY_USER_ID;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      messageViews.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }


    return messageViews.reverse();
  }

  return (
    <div className="">

      <Toolbar
        title={props.selectedConGroup[0] ? props.selectedConGroup[0].name :i18n.t('chat.messages',"Messages")}
        setMessages={props.setMessages}
        rightItems={[
          <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
          <ToolbarButton key="video" icon="ion-ios-videocam" />,
          <ToolbarButton key="phone" icon="ion-ios-call" />
        ]}
      />

      {props.selectedConGroup.length > 1 && (
        <div className="  row w-100">
          <div className=" col-sm-12 h-100">
            <div className='row'>
              <div className="col-sm-12">

                <ul className="nav nav-pills nav-pills-success flex justify-content-center">

                  {props.selectedConGroup.map((con, i) => {
                    return (<li className="nav-item" onClick={() => { props.selectConversation(con, props.selectedConGroup) }}>
                      <a className={i == 0 ? "nav-link active" : "nav-link"} id={'pill_' + i} data-toggle="pill" href={"#pills-" + i} role="tab" aria-controls={"#pills-" + i} aria-selected={i == 0}>{con.contype}</a>
                    </li>)
                  })}
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className="col-sm-12">
                <div className="tab-content w-100" id="pills-tabContent">
                  {props.selectedConGroup.map((con, i) => {
                    return (<div className={i == 0 ? "tab-pane fade active show" : "tab-pane fade"} id={"pills-" + i} role="tabpanel" aria-labelledby={"pills-" + i + "-tab"}>

                      <div className="message-list-container">{renderMessages(con)}</div>
                    </div>)
                  })}
                </div>

              </div>

            </div>
          </div>
        </div>
      )
      }
      {props.selectedConGroup.length == 1 && (
        <div className="message-list-container">{renderMessages(props.selectedConGroup[0])}</div>
      )}
      <div >




        <Compose 
        selectedCon={selectedCon}
        messages={props.messages}
        myUserId={MY_USER_ID}
        sendMessage={props.sendMessage}
        setMessages={props.setMessages}
        rightItems={[
          <ToolbarButton key="photo" icon="ion-ios-camera" />,
          <ToolbarButton key="image" icon="ion-ios-image" />,
          <ToolbarButton key="audio" icon="ion-ios-mic" />,
          <ToolbarButton key="money" icon="ion-ios-card" />,
          <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
          <ToolbarButton key="emoji" icon="ion-ios-happy" />
        ]} />
      </div>
    </div>
  );
}