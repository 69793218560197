import React, {useEffect} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {RRule} from "rrule";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useSelector} from "react-redux";

const localizer = momentLocalizer(moment);
const rRuleWD = {
    0: RRule.SU,
    1: RRule.MO,
    2: RRule.TU,
    3: RRule.WE,
    4: RRule.TH,
    5: RRule.FR,
    6: RRule.SA,
}

export function FortressCalendar({events, addedCalEvents, handleCalendarClick}) {
    const allUsers = useSelector((state) => state.users.byId);


    useEffect(() => {

    }, [addedCalEvents, events])
    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.active ? '#' + event.hexColor : "#ededed";
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style,
            className: "rbc-day-bg"
        };
    }

    const fn = (a, b, c, d, e, f) => {
        //console.log((a, b, c, d, e, f);)
    }
    const abc = () => {
        return (<div><h1>ASDAS</h1></div>);
    }

    const getRRule = (time, recursive) => {
        let freq = RRule.DAILY;
        let count = 30;
        let weekday = null;
        let interval = 1;
        const dtstart = new Date(time);
        switch (recursive) {
            case 'DAILY':
                count = 90
                break;
            case 'TWO_DAYS':
                count = 45;
                interval = 2
                break;
            case 'WEEKLY':
                weekday = rRuleWD[moment(dtstart).day()]
                count = 25
                break;
            case 'QUARTERLY':
                interval = 3;
                freq = RRule.MONTHLY;
                count = 10;
                break;
            case 'MONTHLY':
                freq = RRule.MONTHLY;
                count = 15;
                break;
            case 'ANNUALLY':
                freq = RRule.YEARLY;
                count = 5;
                break;
        }

        return new RRule({
            freq: freq,
            count: count,
            byweekday: weekday,
            interval: interval,
            dtstart: dtstart
        });
    }

    function getCalendarEventColor(id) {
        return Math.floor(id * 1234232 % 16777215).toString(16).toString();
    }

    function getEvent({title, startTime, endTime, allDay, event, user}) {
        return {
            title: title,
            start: new Date(startTime),
            end: new Date(endTime),
            allDay: allDay,
            resource: event,
            active: event.active,
            user: user,
            hexColor: getCalendarEventColor(event.assigneeId)
        };
    }

    function getUser(e) {
        if (e && e.assigneeId) {
            const user = allUsers[e.assigneeId];
            if (user && user.name) {
                return user.name;
            }
            if (user && user.user && user.user.firstName) {
                return user.user.firstName;
            }
        }
        return "sdfs";

    }

    function getEndDate(e) {
        return moment(e.startTime).add(e.duration ? e.duration : 0, 'minutes').toDate()
    }

    function multiplyEvents(e) {
        let recursive = e.recursiveFrequency;
        let items = [];
        if (recursive && recursive != 'None') {
            const rule = getRRule(e.startTime, recursive);
            const endrule = getRRule(getEndDate(e), recursive);
            const enDays = endrule.all();
            rule.all().forEach((stDay, index) => {

                items.push(getEvent(
                    {
                        title: e.active ? e.dateText : e.mappedText,
                        startTime: stDay,
                        endTime: enDays[index],
                        allDay: e.allDay,
                        user: getUser(e),
                        event: e
                    }
                ))
            })
        } else {
            items.push(getEvent(
                {
                    title: e.active ? e.dateText : e.mappedText,
                    startTime: e.startTime,
                    endTime: getEndDate(e),
                    allDay: e.allDay,
                    user: getUser(e),

                    event: e
                }
            ))
        }
        return items;

    }


    function getEvents() {
        let e = [];
        events.forEach((_event) => {
            e.push(...multiplyEvents(_event));
        });
        if (addedCalEvents) {
            addedCalEvents.forEach((_event) => {
                e.push(...multiplyEvents(_event));
            })
        }
        return e;

    }

    function Event({event}) {
        return (
            <span>
      <strong style={{fontSize: "0.8em"}}>{event.user}</strong>
              <p>  {event.title && event.title}</p>
    </span>
        )
    }

    function EventAgenda({event}) {
        return (
            <span>
      <strong style={{color: 'black', fontSize: "0.8em"}}>{event.user}</strong>
      <p>{event.title}</p>
    </span>
        )
    }


    return (

        <div className="rbc-calendar-container">
            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                events={getEvents()}

                showMultiDayTimes={true}
                onSelectEvent={(e) => {
                    handleCalendarClick(null, e)
                }}
                onSelectSlot={(e) => {
                    handleCalendarClick(e, null)
                }}
                tooltipAccessor={"title"}
                defaultView="month"
                style={
                    {
                        maxHeight: "80vh",
                        minHeight: "70vh",
                        minWidth: "60vw",
                        maxWidth: "80vh",
                        margin: "0.5vw auto",
                        overflow: "scroll"
                    }

                }
                components={{
                    event: Event,
                    agenda: {
                        event: EventAgenda,
                    },
                }}
                eventPropGetter={(eventStyleGetter)}
                selectable={true}
            />
        </div>


    );

}
