import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { CompanyAuth } from "./companyAuth";
import { UserAuth } from "./userAuth";

import { AnimateDiv } from "../../components/animatedDiv";

import { getAccessToken } from "../../api";

import { COMPANY_STATUS, TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../config";
import { ForgotPassword } from "./forgotPassword";

export function AuthLayout(props) {
  const [comapnyCompleted, setComapnyCompleted] = useState(
    sessionStorage.getItem(COMPANY_STATUS)
  );
  const [passwordResetFlow, setPasswordResetFlow] = useState(false);

  useEffect(() => {
    setPasswordResetFlow(props.location.pathname.indexOf("password") != -1);
  }, [])
  const CompanyAuthScreen = () => {
    return (
      <AnimateDiv>
        <CompanyAuth
          companyCompleted={!comapnyCompleted ? setComapnyCompleted : null}
        />
      </AnimateDiv>
    );
  };

  const UserAuthScreen = () => {
    return (
      <AnimateDiv>
        <UserAuth />
      </AnimateDiv>
    );
  };

  const PasswordResetScreen = () => {
    return (
      <AnimateDiv>
        <ForgotPassword />
      </AnimateDiv>
    );
  };

  return (
    <section className="auth-layout">
      <div className="container-scroller d-flex">
        <div className="container-fluid page-body-wrapper full-page-wrapper d-flex">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-md-6 col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <AnimatePresence>

                    {passwordResetFlow ?
                      (<PasswordResetScreen />) :

                      comapnyCompleted ? (
                        <UserAuthScreen />
                      ) : (
                          <CompanyAuthScreen />
                        )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function LogoutPage() {
  const history = useHistory();
  let token = sessionStorage.getItem(REFRESH_TOKEN_NAME);

  useEffect(() => {
    if (token) {
      sessionStorage.removeItem(TOKEN_NAME);
      fetchToken();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/signin";
    }
  }, []);

  const fetchToken = async () => {
    let res = await getAccessToken(token);
    ;
    const accessToken = res.data.data.access;
    sessionStorage.setItem(TOKEN_NAME, accessToken);
    // history.goBack();
  };

  return <> </>;
}
