import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

import { getJobLocations,removeJobLocation } from "../../../api";
import * as apiActions from "../../../state/locations";

import { PageLayout } from "../../../components/pageLayout";

import locationDefaultImg from "../../../resources/img/location/location.png";
import { useHistory } from "react-router";

export function ViewJobLocations() {
  const [error, setError] = useState("");
  const allData = useSelector((state) => state.locations.byId);
  const allIds = useSelector((state) => state.locations.allIds);
  const allDataArray = allIds.map((key) => allData[key]);
  const companyState = useSelector((state) => state.company.profile);
  const selectedClientId = null;
  const page = 0;
  const size = 100;
  const dispatch = useDispatch();

  useEffect(async () => {
    setError("");

    loadAllJobLocations();
  }, []);


  let history = useHistory();

 async function loadAllJobLocations() {
    const res = await getJobLocations(companyState.id, selectedClientId, null, page, size);
    if (res.success) {
      dispatch(apiActions.clearLocations());
      dispatch(apiActions.setLocations(res.data));
    }
  }
  function editJobLocation(id) {
    history.push({
      pathname: '/jobs/addlocation/edit',
      search: '?id=' + id
    })
  }
  function _removeJobLocation(id) {
    const location=allData[id];
    swal(
      {
        title: 'Confirm',
        text: 'You cannot undo this. Are you sure to remove "' + location.name + '" ?',
        icon: 'warning',
        buttons: {
          cancel: "Close",
          submit: {
            text: "Delete",
            value: "delete"
          }
        }

      }
    ).then((val) => {
      if (val == 'delete') {
        return removeJobLocation(id);

      }
      throw null;
    }).then((res) => {
      swal({
        title: res && res.success ? 'Success' : 'Error',
        text: res && res.message ? res.message : "Something went wrong",
        icon: res && res.success ? 'success' : 'error',
        buttons: "Close"
      }).then(() => {

        loadAllJobLocations();

      }
      );
    })
  }
  return (
    <PageLayout>
      <div className="content-wrapper">
        <div className="row">

          {allDataArray.map((locationData) => {
            if (locationData != undefined) {
              return (
                <div className="col-sm-12 col-md-6 col-xl-4 grid-margin stretch-card user-card" key={locationData.id}>
                  <div className="card mb-3">
                    <div className="row no-gutters">
                      <div className="col-4">
                        <img
                          src={
                            locationData.imageURL && locationData.imageURL.includes("http") ? locationData.imageURL : locationDefaultImg
                          }
                          className="card-img-top"
                          style={{
                            height: "150px",
                            objectFit: "cover"
                          }}
                          alt="..."
                        />
                      </div>
                      <div className="col-8">
                        <div className="card-body">

                          <div className="pos-abs r-2 t-2 action-btns">
                            <button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                              onClick={() => { editJobLocation(locationData.id) }}
                            ><i className="mdi mdi-pencil"></i></button>
                            <button className="btn btn-sm btn-outline-danger btn-fw p-2"
                              onClick={() => { _removeJobLocation(locationData.id) }}
                            ><i className="mdi mdi-delete"></i></button>

                          </div>


                          <h4 className="card-title">
                            {locationData.name}

                          </h4>
                          <p className="text-muted">
                            {locationData.address}

                          </p>
                          <p className="text-muted">
                            {locationData.website}

                          </p>

                          <div>
                          {locationData.jobLocationGroups && (
                            locationData.jobLocationGroups.map((group) => {
                              return (
                                <small className="badge badge-secondary-outline badge-pill">
                                  {group.name
                                  }
                                </small>
                              )
                            })
                          )}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return (<div ></div>);

          })}
        </div>
      </div>
    </PageLayout>
  );
}
