import React from 'react';
import './ConversationSearch.css';
import i18n from "i18next";

export default function ConversationSearch() {
    return (
      <div className="conversation-search">
        <input
          type="search"
          className="conversation-search-input"
          placeholder=  {i18n.t('chat.search-text',"Search Conversations")}
        />
      </div>
    );
}