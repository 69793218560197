import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import { PageLayout } from "../../../components/pageLayout";
import moment from 'moment';

import { createContract, getContract, updateContract } from "../../../api";
import { Multiselect } from 'multiselect-react-dropdown';
import { useHistory } from "react-router";
import i18n from "i18next";

export function CreateContract(props) {
  const allUserData = useSelector((state) => state.users.byId);
  const allUserIds = useSelector((state) => state.users.allIds);
  const allUserDataArray = allUserIds.map((key) => allUserData[key]);
  const allLocationData = useSelector((state) => state.locations.byId);
  const allLocationIds = useSelector((state) => state.locations.allIds);
  const [isEditMode, setEditMode] = useState(false);
  const [contract, setContract] = useState({});
  const allLocationDataArray = allLocationIds.map(
    (key) => {
      const item = allLocationData[key];
      item.display = allLocationData[key].id + ". " + allLocationData[key].name;
      return item;
    });
  const companyState = useSelector(
    (companyState) => companyState.company.profile
  );

  const [locations, setLocations] = useState([]);
  const [error, setError] = useState("");
  let history = useHistory();

  useEffect(async () => {
    const isEdit = props && props.location && props.location.pathname.includes('/edit');
    setEditMode(isEdit);
    if (isEdit) {
      const id = new URLSearchParams(props.location.search).get("id")
      const res = await getContract(id)
      if (res && res.success && res.data) {
        setContract(res.data);
        if (res.data.jobLocations && res.data.jobLocations.length > 0) {
          let locations = [];
          res.data.jobLocations.forEach((loc) => {
            if (allLocationData[loc.id]) {
              const item = allLocationData[loc.id];
              item.display = allLocationData[loc.id].id + ". " + allLocationData[loc.id].name;
              locations.push(item);

            }
          });
          setLocations([...locations]);
        }
      }

    }

  }, [])

  const saveContract = async (e) => {
    e.preventDefault();
    let error = '';
    if (new Date(e.target.startDate.value) > new Date(e.target.endDate.value)) {
      error = 'Incorrect date range!';
    }

    if (error != '') {
      swal({
        title: "Error",
        text: error,
        icon: 'error'
      });
    } else {


      const data = {
        clientId: e.target.client.value,
        description: e.target.description.value,
        number: e.target.contractNumber.value,
        title: e.target.contractTitle.value,
        tenantId: companyState.id,
        startDate: e.target.startDate.value,
        endDate: e.target.endDate.value,
        signedById: e.target.signedBy.value,
        locations: locations ? locations.map((l) => l.id) : [],
      };

      try {
        let res = null;
        if (isEditMode) {
         res= await updateContract(contract.id, data);

        } else {
          res=await createContract(data);
        }

        if (res.success) {


          swal({
            title: 'Success',
            text: 'Contract successfully saved',
            icon: 'success'
          }).then(() => {

            if (isEditMode) {
              history.goBack();
            } else {
              document.getElementById("form").reset();

            }
          });
        } else {
          swal({
            title: "Error",
            text: res.message ? res.message : "Error Creating Contract!",
            icon: 'error'
          });
        }
      } catch (err) {
        setError("Error Creating Contract!");
        //console.log((err);)
      }
    }

  };

  useEffect(() => {
    if (error) {
      swal(error);
    }
  }, [error]);

  return (
    <PageLayout>
      <div className="content-wrapper job-location">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{isEditMode ? i18n.t('contract.update.form.title',"Update Contract") :
                  i18n.t('contract.add.form.title',"Add Contract")}</h4>
              <p className="card-description">Contract Details</p>
              <form id="form" className="form" onSubmit={saveContract}>
                <div className="row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="client">    {i18n.t('contract.form.client',"Select client")}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="client"
                      required
                      disabled={isEditMode}
                      value={contract && contract.clientId ? contract.clientId : ""}
                      onChange={(e) => {
                        contract.clientId = e.target.value;
                        setContract({ ...contract });
                      }}
                    >
                      <option value="">    {i18n.t('site.add.form.client.select',"Select client...")}
                      </option>
                      {allUserDataArray &&
                        allUserDataArray.map((user) => {
                          if (user.userRole.roleLevel === 10) {
                            return (
                              <option value={user.id} key={user.id}>
                                {user.user.firstName + " " + user.user.lastName}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="contractTitle">    {i18n.t('contract.form.title',"Contract Title")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="contractTitle"
                      placeholder= {i18n.t('contract.form.title',"Contract Title")}

                      required
                      value={contract && contract.title ? contract.title : ""}
                      onChange={(e) => {
                        contract.title = e.target.value;
                        setContract({ ...contract });
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="contractNumber">    {i18n.t('contract.form.number',"Contract Number")}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="contractNumber"
                      placeholder=    {i18n.t('contract.form.number',"Contract Number")}

                      required
                      value={contract && contract.number ? contract.number : ""}
                      onChange={(e) => {
                        contract.number = e.target.value;
                        setContract({ ...contract });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="description">    {i18n.t('contract.form.description',"Contract Description")}
                    </label>
                    <textarea
                      className="form-control form-control-sm"
                      id="description"
                      placeholder=    {i18n.t('contract.form.description',"Contract Description")}

                      rows="3"
                      value={contract && contract.description ? contract.description : ""}
                      onChange={(e) => {
                        contract.description = e.target.value;
                        setContract({ ...contract });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="startDate">    {i18n.t('contract.form.st-date',"Contract Start Date")}
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id="startDate"
                      placeholder=    {i18n.t('contract.form.st-date',"Contract Start Date")}

                      required
                      value={contract && contract.startDate ? moment(contract.startDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => {
                        contract.startDate = e.target.value;
                        setContract({ ...contract });
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="endDate">    {i18n.t('contract.form.en-date',"Contract End Date")}
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id="endDate"
                      placeholder=    {i18n.t('contract.form.en-date',"Contract End Date")}

                      required
                      value={contract && contract.endDate ? moment(contract.endDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => {
                        contract.endDate = e.target.value;
                        setContract({ ...contract });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="locations">    {i18n.t('contract.form.locations',"Add Locations")}
                    </label>


                    <Multiselect
                      options={allLocationDataArray} // Options to display in the dropdown
                      onSelect={setLocations} // Function will trigger on select event
                      onRemove={setLocations} // Function will trigger on remove event
                      displayValue="display" // Property name to display in the dropdown options
                      showCheckbox={true}
                      closeOnSelect={false}
                      selectedValues={locations}
                      id="locationSelect"
                      closeIcon="cancel"
                      emptyRecordMsg="No locations available"
                      placeholder=    {i18n.t('forms.sites.select',"Select sites...")}


                    />

                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="signedBy">    {i18n.t('contract.form.signed-by',"Signed By")}
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="signedBy"
                      required
                      value={contract && contract.signedById ? contract.signedById : ""}
                      onChange={(e) => {
                        contract.signedById = e.target.value;
                        setContract({ ...contract });
                      }}
                    >
                      <option value="">Select Person...</option>
                      {allUserDataArray &&
                        allUserDataArray.map((user) => {

                          if (user.userRole.roleLevel < 5) {
                            return (
                              <option value={user.id} key={user.id}>
                                {user.user.firstName + " " + user.user.lastName + (user.employeeNumber != null ? " (" + user.employeeNumber + ")" : "")}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-right">

                    <input
                      className="btn btn-primary"
                      type="submit"
                      value={isEditMode ? i18n.t('btn.update',"Update") :
                          i18n.t('btn.submit',"Submit")}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
