import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {getJobs} from "../../../api";
import {PageLayout} from "../../../components/pageLayout";
import {CalendarView} from "./shared/calendarView";
import {loadCalendarEventsForAddedEmployee} from "./jobEdit";
import i18n from "i18next";


export function JobCalendar(props) {

    const companyState = useSelector((state) => state.company.profile);
    const allUserData = useSelector((state) => state.users.byId);
    const allUserIds = useSelector((state) => state.users.allIds);
    const [availableEmployees, setAvailableEmployees] = useState([]);

    const [selectedJob, setSelectedJob] = useState({});
    const [jobs, setJobs] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);


    var initialized = false;
    useEffect(async () => {
        loadJobs();

    }, []);

    useEffect(async () => {
        if (selectedJob && selectedJob.id) {
            loadCalendar(selectedJob.id);
        }
    }, [selectedJob]);

    async function loadJobs(clientId, jobLocationId, jobDetailId) {
        const res = await getJobs(companyState.id, clientId, jobLocationId, jobDetailId);
        if (res.success) {
            setJobs(res.data);
            readJobFromURL(res.data);
        } else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }

    function readJobFromURL(jobs) {
        const id = new URLSearchParams(props.location.search).get("jobId");
        if (id) {
            const job = getJobFromIdInJobs(id, jobs);
            if (job) {
                setSelectedJob({...job})


                return true;
            }
        }
        return false;
    }

    function getJobFromId(id) {
        return getJobFromIdInJobs(id, jobs);
    }

  async  function loadCalendar(jobId) {
        let events= await loadCalendarEventsForAddedEmployee([selectedJob], companyState.id, [], null)

        if(events){
            events.forEach((e)=>{
               e['active']=true;
            });
            setCalendarEvents([...events]);
        }
    }

    function getJobFromIdInJobs(id, jobs) {
        for (let job of jobs) {
            if (job.id == id) {
                return job
                break;
            }
        }
    }

    function reset() {
        setSelectedJob({});
    }

    const onJobChange = async (e) => {
        try {
            const id = parseInt(e.target.value);

            const job = getJobFromId(id);
            if (job) {
                setSelectedJob({...job})

            } else {
                reset();
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }



    return (
        <PageLayout>
            <div className="content-wrapper ">
                <div className="col-12 grid-margin stretch-card col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <h4 className="card-title">    {i18n.t('job.calendar.title',"Calendar")}
                            </h4>
                            <p className="card-description">    {i18n.t('job.calendar.description',"View calendar for jobs")}
                            </p>

                            <div className="row">
                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-sm-12 form-group">
                                            <label htmlFor="jobdetail_select"><h6>    {i18n.t('job.calendar.form.job',"Select a job")}
                                            </h6></label>
                                            <select
                                                className="form-control form-control-sm"
                                                id="jobdetail_select"
                                                onChange={onJobChange}
                                                value={selectedJob ? selectedJob.id : -1}
                                                required
                                            >
                                                <option value="-1">    {i18n.t('job.calendar.form.job.select',"Select job...")}
                                                </option>
                                                {jobs &&
                                                jobs.map((job) => {
                                                    return (
                                                        <option value={job.id} key={job.id}>
                                                            {job.id + ". Job for " + job.jobLocation.name + (job.enabled ? "" : " (Deactivated)")}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {selectedJob && (
                                                <CalendarView
                                                    events={calendarEvents}
                                                    title=""
                                                    showAllEvents={true}
                                                    showSetAllButton={false}
                                                    addedCalEvents={[]}
                                                    handleCalendarClick={()=>{}}
                                                >

                                                </CalendarView>

                                            )}


                                        </div>

                                    </div>



                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>

    );


}
