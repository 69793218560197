import React, { useState, useEffect } from 'react';
import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import './Messenger.css';
import { PageLayout } from "../../../../components/pageLayout";
import { useSelector, useDispatch } from "react-redux";
import { getMessages, getConversation, getConversations, getJobLocations, addMessage } from '../../../../api'
import locationDefaultImg from "../../../../resources/img/location/location.png";
import companyDefaultImg from "../../../../resources/img/company/company.png";
import * as apiActions from "../../../../state/locations";
import * as conversationActions from "../../../../state/conversations";
import i18n from "i18next";

const savedCons = {};
const savedMessages = {};
const size = 50;
export default function Messenger(props) {

  const [selectedConGroup, setSelectedConGroup] = useState([]);
  const [messages, setMessages] = useState([]);
  const allData = useSelector((state) => state.locations.byId);
  const companyState = useSelector((state) => state.company.profile);
  const conversationState = useSelector((state) => state.conversation);
  const [selectedCon, setSelectedCon] = useState({});
  const [conversationGroups, setConversationGroups] = useState([]);
  const userState = useSelector((state) => state.auth.profile);
  var MY_USER_ID = userState.tenantUserId;
  const dispatch = useDispatch();
  useEffect(async () => {

    if (!allData || !allData.length) {
      const res = await getJobLocations(companyState.id, null, null, 0, 200);
      dispatch(apiActions.setLocations(res.data));
    }
    _getConversations();

  }, [])
  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const _getConversations = async () => {

    const res = await getConversations(null,companyState.id);
    let cons = [];
    let ten = [];
    let jl = [];
    if (res.success) {
      dispatch(conversationActions.setConversations(res.data));
      res.data.forEach((con) => {
        con.groupId = con.conversationType.mappedTable + "_" + con.mappedId;
        if (con.conversationType.mappedTable === 'JOB_LOCATION') {
          const location = allData[con.mappedId];
          con.imageURL = location && location.imageURL && location.imageURL.includes("http") ? location.imageURL : locationDefaultImg;
          con.name = location ? location.name : con.tite;
          con.contype = con.conversationType.name.includes('Communication Book') ? i18n.t('chat.communication-book',"Communication Book") :
            con.conversationType.name.includes('Job Updates') ? i18n.t('chat.internal-updates',"Updates") : con.conversationType.name;
          jl.push(con);
        } else if (con.conversationType.mappedTable === 'TENANT') {
          con.imageURL = companyState.logoURL && companyState.logoURL.length > 0 ? companyState.logoURL : companyDefaultImg;
          con.name = companyState.code.toLowerCase() + " internal";
          con.contype = "General"
          ten.push(con);
        }
      });
      cons = ten.concat(jl);
    }
    let groupedCons = groupBy(cons, "groupId");
    setConversationGroups(Object.values(groupedCons));

  }


  async function selectConGroup(conGroup) {
    setSelectedConGroup(conGroup);
    var conversation = conGroup[0];

    if (conGroup && conGroup.length == 2) {
      conversation = conGroup[0].contype === 'Communication Book' ? conGroup[0] : conGroup[1];
    }
    selectConversation(conversation, conGroup);

  }


  async function loadMessagesForConversation(conversation) {
    if (conversation.threads) {
      const thread = conversation.threads[0];
      if (thread) {
        if (!thread.page) {
          thread.page = 0;
        } else if (thread.loadNext) {
          thread.page++;
        }
        const res = await getMessages(thread.id, thread.page, size);
        if (res && res.success) {
          if (res.data.length == size) {
            thread.loadNext = true;
          }
          conversation.messages = res.data;
          conversation.messageLoadedTime = new Date().getTime;
          setMessages(res.data);
        }
      }


    }else{
      conversation.messages =[];
    }
  }
  async function selectConversation(con, conGroup) {
    setSelectedCon(con);
    if (!savedCons[con.id]) {
      const res = await getConversation(con.id);
      if (res.success) {
        savedCons[con.id] = res.data;
        con.assignees= res.data.assignees;
      }
    } else {
      con = savedCons[con.id];
    }
    if (!con.messages) {
      loadMessagesForConversation(con);
    } else {
      setMessages(con.messages);
      if (con.messageLoadedTime && new Date().getTime - con.messageLoadedTime > 15000)
        setTimeout(
          () =>
            loadMessagesForConversation(con)
          , 4000
        )
    }

    const cgi = conversationGroups.indexOf(conGroup);
    const ci = conGroup.indexOf(con);
    if (cgi != -1 && ci != -1) {
      conversationGroups[cgi][ci] = con;
      setConversationGroups([...conversationGroups]);
    }

  }
  function getMessage(threadId,text, urls) {
    return {
      conversationThreadId: threadId,
      data: urls ? urls : [],
      text: text,
      sentUserId: MY_USER_ID,
      sentTime: new Date().toISOString()
    }
  }
  async function sendMessage(text,files){
    const threadId = selectedCon && selectedCon.threads && selectedCon.threads.length > 0 ?
    selectedCon.threads[0].id : -1;
    if (selectedCon && threadId != -1 &&text&& text.trim().length>0) {
      const res = await addMessage(selectedCon.id, threadId, {
        messages: [
          {
            dataUrls: [],
            text: text,
            sentTime: new Date().getTime()
          }
        ]
      });

      if (res && res.success && getMessage(threadId,text)) {
        setMessages([getMessage(threadId,text),...messages]);
       
      }
    }
  }
  useEffect(() => {

  }, [])
  return (
    <PageLayout>
      <div className="messenger">
        {

        /* <Toolbar
          title="Messenger"
          leftItems={[
            <ToolbarButton key="cog" icon="ion-ios-cog" />
          ]}
          rightItems={[
            <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
          ]}
        /> */}


        {
          /* <Toolbar
            title="Conversation Title"
            rightItems={[
              <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
              <ToolbarButton key="video" icon="ion-ios-videocam" />,
              <ToolbarButton key="phone" icon="ion-ios-call" />
            ]}
          /> */
        }

        <div className="scrollable messenger-sidebar">
          <ConversationList
            selectedConGroup={selectedConGroup}
            setSelectedConGroup={selectConGroup}
            conversationGroups={conversationGroups}
          />
        </div>

        <div className="scrollable h-100 messenger-sidebar" >
          <MessageList
            selectedConGroup={selectedConGroup}
            messages={messages}
            setMessages={setMessages}
            sendMessage={sendMessage}
            selectConversation={selectConversation}
            loadMessagesForConversation={loadMessagesForConversation}
          />
        </div>
      </div>
    </PageLayout>

  );
}