import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Provider, useSelector} from "react-redux";
import swal from "sweetalert";
import {createJob, getContracts, getJobDetailSummary, getJobLocations, uploadJobRelatedFile} from "../../../api";
import {store} from "../../../state/store";

import {PageLayout} from "../../../components/pageLayout";
import {RegisterJobDetail} from "./registerJobDetail";
import userDefaultImg from "../../../resources/img/user/user-img-default.png";
import {EmployeeSelectWidget} from "./shared/employeeSelect";
import {loadCalendarEventsForAddedEmployee, updateUsersForJob} from "./jobEdit";
import i18n from "i18next";
import {Avatar, Chip} from "@material-ui/core";


export function JobPage(props) {

    const companyState = useSelector((state) => state.company.profile);
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);
    const allClientDataArray = allClientIds.map((key) => allClientData[key]);
    const [allContractData, setAllContractData] = useState([]);
    const [allJobSites, setAllJobSites] = useState([]);
    const [jobDetailSummaries, setJobDetailSummaries] = useState([]);
    const [availableEmployees, setAvailableEmployees] = useState([]);
    const [addedEmployees, setAddedEmployees] = useState([]);

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [removedEvents, setRemovedEvents] = useState([]);
    const [addedCalEvents, setAddedCalEvents] = useState([]);
    const [relatedFiles, setRelatedFiles] = useState([]);

    const [values, setValues] = useState({});
    var initialized = false;
    const page = 0;
    const size = 20;
    useEffect(() => {
        loadUsersForAutoComplete();
    }, []);

    useEffect(() => {
        _loadCalendarEventsForAddedEmployee(addedEmployees);
    }, [addedEmployees]);

    async function _loadCalendarEventsForAddedEmployee(addedEmployee) {

        await loadCalendarEventsForAddedEmployee([], companyState.id, addedEmployee, setCalendarEvents)


    }

    function loadUsersForAutoComplete() {

        const emps = [];
        allClientDataArray.forEach((user) => {
            if (user.userRole.roleLevel < 10 && user.userRole.roleLevel > 2) {
                const display =
                    (user["employeeNumber"] ? user["employeeNumber"] + " - " : "") +
                    (user["user"]["firstName"] ? user["user"]["firstName"] + " " : "") +
                    (user["user"]["lastName"] ? user["user"]["lastName"] : "")
                ;

                var emp = Object.assign({}, user, {
                    display: display,
                    role: user["userRole"]["name"],
                    hours: user["hours"] ? user["hours"] : 0
                });

                emps.push(emp);
            }
        })
        setAvailableEmployees([...emps]);
    }

    const addFile = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        let _size = fileObj.size;
        let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0;
        while (_size > 900) {
            _size /= 1024;
            i++;
        }
        const exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];

        event.target.value = null;


        let file = {
            fileName: fileObj.name,
            extension: "NEW",
            sizeH: exactSize,
            file: fileObj
        }
        relatedFiles.push(file);
        setRelatedFiles([...relatedFiles]);
    }

    function uploadFilesForJob(jobId) {
        if (relatedFiles && relatedFiles.length > 0) {
            for (let relatedFile of relatedFiles) {
                if (relatedFile.file) {
                    uploadJobRelatedFile(jobId, relatedFile.file);

                }
            }
        }
    }

    function postJobCreation(res) {
        updateUsersForJob(res.data, addedEmployees, [],
            {
                mappedId: res.data,
                tenantId: companyState.id,
                events: addedCalEvents,
                removeAssigneeIds: removedEvents
            }
        ).then(() => {
            setAddedCalEvents([]);
            setCalendarEvents([]);
            setAddedEmployees([]);
        })
        uploadFilesForJob(res.data);
    }

    async function submitJob(e) {
        e.preventDefault();
        var valid = true;
        const clientId = parseInt(document.getElementById('client').value);
        const contractId = document.getElementById('contract') ? parseInt(document.getElementById('contract').value) : -1;
        const contractHasLocationId = document.getElementById('jobsite_select') && parseInt(document.getElementById('jobsite_select').value) ? parseInt(document.getElementById('jobsite_select').value) : -1;
        const jobdetailId = document.getElementById('jobdetail_select') && parseInt(document.getElementById('jobdetail_select').value) ? parseInt(document.getElementById('jobdetail_select').value) : -1;
        const totalHours = document.getElementById('totalHours') && document.getElementById('totalHours').value && parseInt(document.getElementById('totalHours').value) ? parseInt(document.getElementById('totalHours').value) : 0;
        const minDistance = document.getElementById('minDistance') && document.getElementById('minDistance').value && parseInt(document.getElementById('minDistance').value) ? parseInt(document.getElementById('minDistance').value) : 0;
        const maxServiceTime = document.getElementById('maxServiceTime') && document.getElementById('maxServiceTime').value && parseInt(document.getElementById('maxServiceTime').value) ? parseInt(document.getElementById('maxServiceTime').value) : 0;
        const pushFeedbackInDays = document.getElementById('pushFeedbackInDays') && document.getElementById('pushFeedbackInDays').value && parseInt(document.getElementById('pushFeedbackInDays').value) ? parseInt(document.getElementById('pushFeedbackInDays').value) : 0;
        const distanceFilter = document.getElementById('distanceFilter') && document.getElementById('distanceFilter').value && parseInt(document.getElementById('distanceFilter').value) ? parseInt(document.getElementById('distanceFilter').value) : 0;
        const notes = document.getElementById('notes') && document.getElementById('notes').value ? document.getElementById('notes').value : null;
        if (clientId == -1) {
            swal("Please select a client to continue");
            valid = false;
        }

        if (contractId == -1) {
            valid = false;
            swal("Please select a contract to continue");
        }
        if (contractHasLocationId == -1) {
            valid = false;
            swal("Please select a job site to continue");
        }
        if (jobdetailId == -1) {
            valid = false;
            swal("Please select a job detail to continue");
        }
        const req = {
            tenantId: companyState.id,
            jobDetailId: jobdetailId,
            contractLocationId: contractHasLocationId,
            totalHours: totalHours,
            notes: notes,
            distanceFilterForLocationRecord: distanceFilter,
            minimumDistanceToStart: minDistance,
            maxServiceTime: maxServiceTime,
            pushFeedbackInDays: pushFeedbackInDays
        }

        if (valid) {
            let wrapper = document.createElement('div');
            ReactDOM.render(
                <ReviewView
                    contract={values['contract']}
                    client={values['client']}
                    jobSite={values['jobSite']}
                    jobDetail={values['jobDetail']}
                    notes={notes}
                    hours={totalHours}
                    addedEmployees={addedEmployees}
                ></ReviewView>


                , wrapper);
            let el = wrapper.firstChild;
            swal(
                {
                    title: "Review",
                    content: el,
                    buttons: {
                        cancel: "Close",
                        submit: {
                            text: "Submit",
                            value: "submit"
                        }
                    }
                }
            ).then((val) => {
                if (val == "submit") {
                    return createJob(req);
                }
                throw null;
            }).then((res) => {
                if (!res) {
                    throw null;
                }
                if (res.success && addedEmployees.length > 0) {
                    postJobCreation(res);

                }
                swal({
                    title: res && res.success ? 'Success' : 'Error',
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: res && res.success ? 'success' : 'error',
                    buttons: "Close"
                }).then(() => {

                        document.getElementById("create-job-form").reset();
                        setRelatedFiles([]);

                    }
                );
            })

        }


    }


    const onClientChange = async (e) => {
        try {
            const res = await getContracts(true, e.target.value, companyState.id);
            setValues({
                ...values,
                "client": e.target.options[e.target.selectedIndex].text
            })
            if (res.success) {
                setAllContractData(res.data);
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    const onContractChange = async (e) => {
        try {
            setValues({
                ...values,
                "contract": e.target.options[e.target.selectedIndex].text
            })
            const res = await getJobLocations(companyState.id, null, e.target.value, page, size);
            if (res.success) {
                setAllJobSites(res.data);
            }
            const id = parseInt(document.getElementById('client').value);
            if (id != -1) {
                const res = await getJobDetailSummary(companyState.id, id);
                setJobDetailSummaries(res.data);
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    function getJobSite() {
        const contractHasLocationId = document.getElementById('jobsite_select') && parseInt(document.getElementById('jobsite_select').value) ? parseInt(document.getElementById('jobsite_select').value) : -1;
        for (let allJobSite of allJobSites) {
            if (allJobSite.contractHasLocationId == contractHasLocationId) {
                return allJobSite;
            }
        }
        return null;
    }

    const onJobSiteChange = async (e) => {
        //console.log((getJobSite());)
        try {
            setValues({
                ...values,
                "jobSite": e.target.options[e.target.selectedIndex].text
            })
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    const onJobDetailChange = async (e) => {

        setValues({
            ...values,
            "jobDetail": e.target.options[e.target.selectedIndex].text
        })
    }

    function shouldAddEmployee() {
        const clientId = parseInt(document.getElementById('client').value);
        const contractId = document.getElementById('contract') ? parseInt(document.getElementById('contract').value) : -1;
        const contractHasLocationId = document.getElementById('jobsite_select') && parseInt(document.getElementById('jobsite_select').value) ? parseInt(document.getElementById('jobsite_select').value) : -1;
        const jobdetailId = document.getElementById('jobdetail_select') && parseInt(document.getElementById('jobdetail_select').value) ? parseInt(document.getElementById('jobdetail_select').value) : -1;
        return contractId != -1 &&
            contractId != -1 &&
            contractHasLocationId != -1 &&
            jobdetailId != -1;
    }


    function onSelect(list, item) {
        if (shouldAddEmployee()) {
            setAddedEmployees([...list]);
        } else {
            setAddedEmployees([]);

            swal({
                title: "Missing Information",
                text: "Please complete all the required steps."
            })
        }

    }


    function setHours(a, b) {
        a.hours = b.target.value ? parseInt(b.target.value) : 0;
        setAddedEmployees([...addedEmployees]);
    }

    function viewJobDetail() {
        const jobdetailId = document.getElementById('jobdetail_select') && parseInt(document.getElementById('jobdetail_select').value) ? parseInt(document.getElementById('jobdetail_select').value) : -1;
        if (jobdetailId == -1) {
            swal("Please select a job detail to continue");
        } else {
            let wrapper = document.createElement('div');
            ReactDOM.render(
                <Provider store={store}>
                    <RegisterJobDetail
                        viewMode={true}
                        jobDetailId={jobdetailId}
                    ></RegisterJobDetail>
                </Provider>


                , wrapper);
            let el = wrapper.firstChild;
            swal({
                title: 'View job detail',
                html: true,
                type: "info",
                className: 'swal-wide',
                content: el.children[1].children[1].firstChild,
                showCancelButton: true,
                showConfirmButton: false
            });
        }
    }


    function deleteRelatedFile(file) {
        if (file) {
            swal(
                {
                    title: 'Confirm',
                    text: ' Are you sure to remove ' + file.fileName + ' ?',
                    icon: 'warning',
                    buttons: {
                        cancel: "Close",
                        submit: {
                            text: "Delete",
                            value: "delete"
                        }
                    }

                }
            ).then((val) => {
                if (val == 'delete') {
                    relatedFiles.splice(relatedFiles.indexOf(file), 1);
                    setRelatedFiles([...relatedFiles]);

                }
                throw null;
            });
            //     .then((res) => {
            //     swal({
            //         title: res && res.success ? 'Success' : 'Error',
            //         text: res && res.message ? res.message : "Something went wrong",
            //         icon: res && res.success ? 'success' : 'error',
            //         buttons: "Close"
            //     }).then(() => {
            //
            //             let l = relatedFiles.splice(relatedFiles.indexOf(file), 1);
            //             setRelatedFiles([...l]);
            //         }
            //     );
            // });

        }
    }

    const fileUpload = useRef(null);

    function handleUploadRelatedFiles() {
        fileUpload.current.click();
    }

    function downloadFile(file) {
        console.log(file.file.mozFullPath);
        // window.open(file.file.mozFullPath);
    }

    return (
        <PageLayout>
            <div className="content-wrapper ">
                <div className="col-12 grid-margin stretch-card col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <h4 className="card-title">    {i18n.t('job.add.form.title', "Create Job")}
                            </h4>
                            <p className="card-description">    {i18n.t('job.add.form.description', "Create new job with job site and job specification")}
                            </p>


                            <div className="row">
                                <div className="col-sm-12">

                                    <form className="forms-sample" id="create-job-form" onSubmit={(e) => submitJob(e)}>


                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="client"><h6>    {i18n.t('job.add.form.step1', "Step 1")}
                                                </h6>    {i18n.t('job.add.form.client', "Select client")}
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="client"
                                                    onChange={onClientChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('job.add.form.client.select', "Select client...")}
                                                    </option>
                                                    {allClientDataArray &&
                                                    allClientDataArray.map((client) => {
                                                        if (client.userRole.roleLevel === 10) {
                                                            return (
                                                                <option value={client.id} key={client.id}>
                                                                    {client.user.firstName + " " + client.user.lastName}
                                                                </option>
                                                            );
                                                        }
                                                    })}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="contract">
                                                    <h6>    {i18n.t('job.add.form.step2', "Step 2")}
                                                    </h6>    {i18n.t('job.add.form.contract', "Select contract")}
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="contract"
                                                    onChange={onContractChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('job.add.form.contract.select', "Select contract...")}
                                                    </option>
                                                    {allContractData &&
                                                    allContractData.map((contract) => {
                                                        return (
                                                            <option value={contract.id} key={contract.id}>
                                                                {contract.title}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="jobsite_select">
                                                    <h6>    {i18n.t('job.add.form.step3', "Step 3")}
                                                    </h6>    {i18n.t('job.add.form.job-site', "Select Job Site")}
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="jobsite_select"
                                                    onChange={onJobSiteChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('job.add.form.job-site.select', "Select job site...")}
                                                    </option>
                                                    {allJobSites &&
                                                    allJobSites.map((jobSite) => {
                                                        return (
                                                            <option value={jobSite.contractHasLocationId}
                                                                    key={jobSite.contractHasLocationId}>
                                                                {jobSite.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">

                                                <div className="row">
                                                    <div className="form-group col-sm-10">
                                                        <label htmlFor="jobdetail_select">
                                                            <h6>    {i18n.t('job.add.form.step4', "Step 4")}
                                                            </h6>    {i18n.t('job.add.form.jd', "Select Job Detail")}
                                                        </label>
                                                        <select
                                                            className="form-control form-control-sm"
                                                            id="jobdetail_select"
                                                            onChange={onJobDetailChange}
                                                            required
                                                        >
                                                            <option
                                                                value="-1">    {i18n.t('job.add.form.jd.select', "Select job detail...")}
                                                            </option>
                                                            {jobDetailSummaries &&
                                                            jobDetailSummaries.map((jobDetailSummary) => {
                                                                return (
                                                                    <option value={jobDetailSummary.id}
                                                                            key={jobDetailSummary.id}>
                                                                        {jobDetailSummary.id + ". " + jobDetailSummary.title}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="flex align-center col-sm-2 mt-4">
                                                        <button className=" btn btn-sm mt-2 btn-primary mr-0"
                                                                type="button"
                                                                onClick={viewJobDetail}>    {i18n.t('btn.view', "View")}

                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">

                                                    <label for="totalHours">
                                                        <h6>    {i18n.t('job.add.form.step5', "Step 5 (Optional)")}
                                                        </h6>Total
                                                        hours</label>
                                                    <input min="0"
                                                           step="0.1"
                                                           className="form-control" id="totalHours" type="number"
                                                           min="0"></input>
                                                </div>

                                            </div>
                                            <div className="form-group col-md-6">

                                                <div className="row">
                                                    <div className="form-group col-sm-10">
                                                        <label htmlFor="jobdetail_select">
                                                            <h6>    {i18n.t('job.add.form.files', "Files")}
                                                            </h6>    {i18n.t('job.add.form.relatedFiles', "Related Files")}
                                                        </label>
                                                        <div className="col ">
                                                            {relatedFiles && relatedFiles.map(file => {
                                                                return (
                                                                    <Chip onClick={() => downloadFile(file)}
                                                                          className="ml-2" variant="outlined"
                                                                          color="success" onDelete={() => {
                                                                        deleteRelatedFile(file);
                                                                    }} avatar={
                                                                        <Avatar>{getFileExtension(file)}</Avatar>}
                                                                          label={getDisplayNameForFile(file)}
                                                                          title={file["fileName"]}/>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="flex align-center col-sm-2 mt-4">
                                                        <input
                                                            type="file"
                                                            ref={fileUpload}
                                                            onChange={addFile}
                                                            style={{opacity: "0"}}
                                                        />
                                                        <button className=" btn btn-sm mt-2 btn-primary mr-0"
                                                                type="button"
                                                                onClick={handleUploadRelatedFiles}>    {i18n.t('btn.add', "Add")}

                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                        <EmployeeSelectWidget
                                            title={i18n.t('job.add.form.step6', "Step 6")}
                                            setHours={setHours}
                                            addedEmployees={addedEmployees}
                                            onSelect={onSelect}
                                            onRemove={onSelect}
                                            setCalendarEvents={setCalendarEvents}
                                            jobLocation={getJobSite()}
                                            calendarEvents={calendarEvents}
                                            setRemovedEvents={setRemovedEvents}
                                            setAddedCalEvents={setAddedCalEvents}
                                            availableEmployees={availableEmployees}
                                            rootAddedCalEvent={addedCalEvents}
                                        ></EmployeeSelectWidget>


                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">

                                                    <label for="notes">
                                                        <h6>    {i18n.t('job.add.form.step7', "Step 7 (Optional)")}
                                                        </h6>    {i18n.t('job.add.form.notes', "Special notes")}
                                                    </label>
                                                    <textarea className="form-control" id="notes" rows="4"
                                                              placeholder={i18n.t('job.add.form.notes.placeholder', "Special notes")}

                                                              autoComplete="off"></textarea>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.location-distance', "Location record distance (in meters)")}
                                                    </label>
                                                    <input className="form-control" id="distanceFilter" rows="4"
                                                           autoComplete="off"
                                                           defaultValue={0}
                                                           type="number"
                                                           required={false}></input>
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.min-distance-to-start', "Minimum distance to start (in meters)")}
                                                    </label>
                                                    <input className="form-control" id="minDistance" rows="4"
                                                           autoComplete="off"
                                                           defaultValue={0}
                                                           type="number"
                                                           required={false}></input>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.max-service-time', "Max service time (in minutes)")}
                                                    </label>
                                                    <input className="form-control"
                                                           defaultValue={480}
                                                           type="number"
                                                           id="maxServiceTime" rows="4" autoComplete="off"
                                                           required={false}></input>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.client-feedback', "Client feedbacks interval(in days)")}
                                                    </label>
                                                    <input className="form-control"
                                                           defaultValue={30}
                                                           type="number"
                                                           id="pushFeedbackInDays" rows="4" autoComplete="off"
                                                           required={false}></input>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="flex flex-bottom row-reverse col ">
                                                    <button className="btn btn-primary "
                                                            type="submit">     {i18n.t('btn.submit', "Submit")}
                                                    </button>

                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>

    );
}

export function getDisplayNameForFile(file) {
    const re = /(?:\.([^.]+))?$/;
    const split = re.exec(file.fileName);
    const ext = split[1];
    let name = file.fileName.substr(0, file.fileName.length - (ext.length + 1));
    if (name.length > 15) {
        name = name.substr(0, 5) + "...";
    }
    return name + "." + ext + " [ " + file["sizeH"] + " ]";
}

export function getFileExtension(file) {
    return file["extension"].length > 3 ? file["extension"].substr(0, 3) : file["extension"];
}

export function ReviewView(props) {


    return (
        <div className="row">
            <div className="col-sm-12">

                <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                        <tr>
                            <td className="flex"><h6>    {i18n.t('job.add.review.client', "Client")}
                            </h6></td>
                            <td>
                                <div> {props.client}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="flex"><h6>    {i18n.t('job.add.review.contract', "Contract")}
                            </h6></td>
                            <td>
                                <div> {props.contract}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="flex"><h6>    {i18n.t('job.add.review.job-site', "Job site")}
                            </h6></td>
                            <td>
                                <div> {props.jobSite}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="flex"><h6>    {i18n.t('job.add.review.jd', "Job detail")}
                            </h6></td>
                            <td>
                                <div> {props.jobDetail}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="flex"><h6>    {i18n.t('job.add.review.total-hours', "Total hours")}
                            </h6></td>
                            <td>
                                <div> {props.hours}</div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                {props.addedEmployees.length > 0 && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <tbody>

                                                    {props.addedEmployees.map((emp, index) => {

                                                        return (
                                                            <tr className="rounded-corners">
                                                                <td className=" w-30 flex-grow p-3">
                                                                    <div className="flex justify-content align-bottom">
                                                                        <img
                                                                            src={
                                                                                emp.imageURL ? emp.imageURL : userDefaultImg
                                                                            }
                                                                            className="card-img-top"
                                                                            alt="..."
                                                                        />
                                                                        <p className=" ml-3">{emp.display}</p>
                                                                    </div>
                                                                </td>
                                                                <td className="w-20">
                                                                    <div className=" flex justify-content align-bottom">

                                                                        <p className=" ml-3" style={{
                                                                            textTransform: "capitalize"
                                                                        }}>{emp.userRole.name.toLowerCase()}</p>
                                                                    </div>
                                                                </td>

                                                                <td className="w-50">
                                                                    <div className="form-group">
                                                                        <div className="input-group">

                                                                            <div className="input-group-prepend">

                                                                                <span
                                                                                    className="input-group-text bg-primary">h</span>
                                                                            </div>


                                                                            <input
                                                                                disabled

                                                                                className="form-control form-control-sm"
                                                                                value={emp.hours}
                                                                            />

                                                                        </div>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td><h6>    {i18n.t('job.add.review.notes', "Special notes")}
                            </h6></td>
                            <td>
                                <div><textarea className="form-control" id="notes" rows="4"
                                               autoComplete="off">{props.notes}</textarea></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>
            </div>

        </div>
    );
}