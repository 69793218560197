import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState: initState,
  reducers: {
    setContracts(state, { action, payload }) {
      for (var contract of payload) {
        const { contracts, ...rest } = contract;
        if (!(contract.id in state.byId)) {
          state.byId[contract.id] = rest;
          state.allIds.push(contract.id);
        }
      }
    },
    // setContractUpdate(state, { action, payload }) {
    //   state.byId[payload.id] = payload;
    // },
    // removeContract(state, { action, payload }) {
    //   state.allIds = state.allIds.filter((id) => id !== payload);
    //   delete state.byId[payload];
    // },
  },
});

export const contractActions = contractsSlice.actions;
export const contractReducer = contractsSlice.reducer;

export function setContracts(data) {
  return (dispatch, getState) => {
    try {
      dispatch(contractActions.setContracts(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting contracts"));
    }
  };
}

// export function createContract(data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.createContract(data);
//       dispatch(contractActions.setContractUpdate(response.data));
//       dispatch(showToastSuccess("Contract created", "Created succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function updateContract(contractId, data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.updateContract(contractId, data);
//       dispatch(contractActions.setContractUpdate(response.data));
//       dispatch(showToastSuccess("Contract updated", "Updated succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function deleteContract(contractId) {
//   return async (dispatch, getState) => {
//     try {
//       await api.deleteContract(contractId);
//       dispatch(contractActions.removeContract(contractId));
//       dispatch(showToastSuccess("Contract deleted", "Deleted succesfully!"));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error deleting contract"));
//     }
//   };
// }
