import React, { useState } from 'react';
import './Compose.css';
import { addMessage } from '../../../../api'
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import i18n from "i18next";


export default function Compose(props) {
  const selectedConversation = props.selectedCon;
  const threadId = selectedConversation && selectedConversation.threads && selectedConversation.threads.length > 0 ?
    selectedConversation.threads[0].id : -1;

const [text,setText]=useState("");

  const _handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if(text&& text.trim().length>0){
        await props.sendMessage(text,null);
        setText("");
      }
     
    }
   
  };
  return (
    <div className="compose">
      <input
        onKeyPress={(e)=>_handleKeyDown(e)}
        type="text"
        className="compose-input"
        placeholder=    {i18n.t('chat.message-send.placeholder',"Type a Message")}

        onChange={(e) => setText(e.target.value)}
        value={text}
      />

      {
        props.rightItems
      }
    </div>
  );
}