import { motion } from "framer-motion";

export const AnimateDiv = ({ children }) => {
  const sliderVariants = {
    hidden: { left: "100%", position: "relative" },
    visible: { left: "0px" },
    exit: { left: "100%" },
  };
  const slideDuration = 0.3;

  return (
    <motion.div
      className="auth-layout-content"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sliderVariants}
      transition={{ duration: slideDuration }}
    >
      {children}
    </motion.div>
  );
};
