import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import {
  GoogleMap,
  useLoadScript,
  Polyline,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import ImageUploader from "react-images-upload";
import { Multiselect } from 'multiselect-react-dropdown';

import "@reach/combobox/styles.css";
import swal from "sweetalert";

import { getContracts, createJobLocation, uploadJobLocationImage, updateJobLocation } from "../../../api";
import { userActions } from "../../../state/users";
import { contractActions } from "../../../state/contracts";
import * as config from "../../../config";

import { PageLayout } from "../../../components/pageLayout";
import { AnimateDiv } from "../../../components/animatedDiv";
import { useHistory } from "react-router";
import i18n from "i18next";

const libraries = ["places"];
const mapContainerStyle = {
  height: "500px",
  width: "100%",

};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

export function RegisterJobLocation(props) {
  const userState = useSelector((state) => state.auth.profile);
  const companyState = useSelector((state) => state.company.profile);
  const allClientData = useSelector((state) => state.users.byId);
  const allClientIds = useSelector((state) => state.users.allIds);
  const allGroupData = useSelector((state) => state.groups.byId);
  const allGroupIds = useSelector((state) => state.groups.allIds);
  const allClientDataArray = allClientIds.map((key) => allClientData[key]);
  const [currentStep, setCurrentStep] = useState({
    id: 1,
    name: "Select Client",
  });
  const [stepData, setStepData] = useState({});
  const [error, setError] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  let history = useHistory();

  const getDisplayGroupFromId = (it) => {
    let item = { ...it };
    item.display = it.name;
    return item;
  }

  const allGroupDataArray = allGroupIds.map(
    (key) => {
      return getDisplayGroupFromId(allGroupData[key]);
    });



  useEffect(() => {
    const isEdit = props && props.location && props.location.pathname.includes('/edit');
    setEditMode(isEdit);
    if (isEdit) {
      setCurrentStep({
        id: 2,
        name: "Location Address",
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      swal(error);
      setError(null);
    }
  }, [error]);

  const SelectClient = () => {
    const [allContractData, setAllContractData] = useState([]);

    const onClientChange = async (e) => {
      try {
        const res = await getContracts(true, e.target.value, companyState.id);
        if (res.success) {
          setAllContractData(res.data);
        }
      } catch (err) {
        //console.log((err);)
        setError("Error Getting Contract Data!");
      }
    };

    const saveClient = (e) => {
      e.preventDefault();
      setStepData(
        {
          clientId: e.target.client.value,
          contractId: e.target.contract.value,
        },
      );

      setCurrentStep({
        id: 2,
        name: "Location Address",
      });
    };

    return (
      <AnimateDiv>
        <form className="form" onSubmit={saveClient}>
          <div className="row">
            <div className="form-group col-sm-12">
              <label htmlFor="client">    {i18n.t('site.add.form.client',"Select client")}
              </label>
              <select
                className="form-control form-control-sm"
                id="client"
                onChange={onClientChange}
                required
              >
                <option value="">    {i18n.t('site.add.form.client.select',"Select client...")}
                </option>
                {allClientDataArray &&
                  allClientDataArray.map((client) => {
                    if (client.userRole.roleLevel === 10) {
                      return (
                        <option value={client.id} key={client.id}>
                          {client.user.firstName + " " + client.user.lastName}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label htmlFor="contract">    {i18n.t('site.add.form.contract',"Select contract")}
              </label>
              <select
                className="form-control form-control-sm"
                id="contract"
                required
              >
                <option value="">    {i18n.t('site.add.form.contract.select',"Select contract...")}
                </option>
                {allContractData &&
                  allContractData.map((contract) => {
                    return (
                      <option value={contract.id} key={contract.id}>
                        {contract.title}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col text-right">
              <input className="btn btn-primary" type="submit" value=    {i18n.t('btn.next',"Next")}
              />
            </div>
          </div>
        </form>
      </AnimateDiv>
    );
  };


  const CollectAddress = () => {

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: config.GOOGLE_API_KEY,
      libraries,
    });

    const [marker, setMarker] = useState();
    const mapRef = useRef();
    const [picture, setPicture] = useState(null);
    const [defaultImage, setDefaultImage] = useState([]);
    const [jobSiteData, setJobSiteData] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);
    const [id, setId] = useState(-1);
    const [center, setCenter] = useState({});
    const [groups, setGroups] = useState([]);

    useEffect(async () => {


      if (isEditMode) {
        const id = new URLSearchParams(props.location.search).get("id");
        let location = { ...allLocationIds[id] };
        if (location) {
          if (location.jobLocationGroups) {
            let _groups = [];
            location.jobLocationGroups.forEach((item) => {
              _groups.push(getDisplayGroupFromId(item));
            })
            setGroups([..._groups])

          }
          setId(Number(id));

        }
        setJobSiteData({ ...location });
        setMarker({ lat: location.lat, lng: location.lang });
        setCenter({ lat: location.lat, lng: location.lang });
        setDefaultImages(location);
      } else {
        setMarker(center);
        await setJobSiteData({ ...jobSiteData, ...stepData });
        setDefaultImages(stepData);
      }

    }, [stepData]);


    const saveLocation = () => {
      const prevStepData = jobSiteData;
      const currStepData = { location: marker };
      setJobSiteData(...prevStepData, ...currStepData);

    };

    const onMapLoad = useCallback((map) => {
      mapRef.current = map;
    }, []);

    const panTo = useCallback(({ lat, lng }) => {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(18);
    }, []);

    const getPinLocation = (e) => {
      panTo({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    };

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";


    const getAddress = async (address) => {

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setCenter({ lat, lng });
        setCurrentStep({
          id: 3,
          name: "Select Location form Map",
        });
      } catch (err) {
        //console.log((err);)
      }
    };

    const saveAddress = (e) => {
      e.preventDefault();
      const prevStepData = jobSiteData;
      const currStepData = {
        address:
          e.target.addrL1.value,
        name: e.target.name.value,
      };
      setJobSiteData(...prevStepData, ...currStepData);



      // getAddress(addr);
    };




    const submitForm = async (e) => {
      e.preventDefault();
      if (!marker || !marker.lat) {
        swal({
          title: "Location is empty",
          text: "Select a location in map",
          icon: "error"
        });

      }
      else {
        let groupsToAdd = [];
        let groupsToRemove = [];
        let prvIds = [];
        let currIds = [];
        if (jobSiteData && jobSiteData.jobLocationGroups) {
          prvIds = jobSiteData.jobLocationGroups.map((k) => { return k.id });
        }
        if (groups && groups.length > 0) {
          currIds = groups.map((k) => { return k.id });
        }

        if (prvIds && prvIds.length > 0) {
          prvIds.forEach((id) => {
            if (currIds.indexOf(id) == -1) {
              groupsToRemove.push(id);
            }
          })
        }

        if (currIds && currIds.length > 0) {
          currIds.forEach((id) => {
            if (prvIds.indexOf(id) == -1) {
              groupsToAdd.push(id);
            }
          })
        }


        const data = {
          address: jobSiteData.address,
          clientId: Number(jobSiteData.clientId),
          contractId: Number(jobSiteData.contractId),
          description: jobSiteData.description,
          lang: marker.lng,
          lat: marker.lat,
          name: jobSiteData.name,
          tenantId: companyState.id,
          website: jobSiteData.website,
          groupIdsToAdd: groupsToAdd,
          groupIdsToRemove: groupsToRemove,
        };
        try {
          let res = null;
          if (isEditMode) {
            res = await updateJobLocation(id, data);

          } else {
            res = await createJobLocation(data);

          }
          if (res && res.success) {
            if (res && res.success && res.data) {
              if (picture) {
                const data = res.data;
                const res2 = await uploadJobLocationImage(data.id, picture);

              }
              swal({
                title: "Success",
                text: "Job location is added",
                icon: "success"
              }).then(() => {
                if (isEditMode) {
                  history.goBack();
                }
                else {
                  setCurrentStep({
                    id: 1,
                    name: "Select Client",
                  });
                }
              })

            }
          } else {
            swal({
              title: "Error",
              text: res.message,
              icon: "error"
            });
          }

        } catch (err) {
          //console.log((err);)
          swal({
            title: "Error",
            text: "Error Adding Location!",
            icon: "error"
          });
        }
      }

    };

    function setDefaultImages(_data) {
      const data = { ...jobSiteData, ..._data };
      //console.log((data);)
      if (data.imageURL && data.imageURL.indexOf("http") != -1) {
        setDefaultImage([data.imageURL]);
      } else

        if (data && data.clientId && allClientData[data.clientId]) {
          const imgURL = allClientData[data.clientId].imageURL;

          if (imgURL && imgURL.indexOf("http") != -1) {
            setDefaultImage([imgURL]);
          }
        }

    }
    return (
      <AnimateDiv>
        <form className="form" onSubmit={submitForm}>

          <div>
            <Search panTo={panTo} setMarker={setMarker}
              jobSiteData={jobSiteData}
              center={center}
              setJobSiteData={setJobSiteData} />
            <div className="row">
              <div className="col-md-6">
                <ImageUploader
                  withIcon={true}
                  fileContainerStyle={{ height: "500px" }}
                  singleImage={true}
                  buttonText=    {i18n.t('site.add.form.image',"Select location image")}

                  onChange={(images) => setPicture(images[0])}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  defaultImages={defaultImage}
                  withPreview={true}
                />
              </div>
              <div className="col-md-6" style={{ padding: "10px" }}>

                <GoogleMap
                  id="map"
                  mapContainerStyle={mapContainerStyle}
                  zoom={12}
                  center={center && center}
                  options={options}
                  onLoad={onMapLoad}
                >
                  {marker && (
                    <Marker
                      position={{ lat: marker.lat, lng: marker.lng }}
                      draggable
                      onDragEnd={getPinLocation}
                    />
                  )}
                </GoogleMap>

              </div>

            </div>



          </div>


          <div className="row">
            <div className="form-group col-sm-12 col-md-6">
              <label htmlFor="name">    {i18n.t('site.add.form.name',"Location Name")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                value={jobSiteData.name ? jobSiteData.name : ""}
                onChange={(e) => {
                  jobSiteData.name = e.target.value;
                  setJobSiteData({ ...jobSiteData });
                }}
                placeholder=    {i18n.t('site.add.form.name',"Location Name")}

                required
              />
            </div>

            <div className="form-group col-sm-12 col-md-6">
              <label htmlFor="groups">    {i18n.t('site.add.form.groups',"Groups")}
              </label>


              <Multiselect
                options={allGroupDataArray} // Options to display in the dropdown
                onSelect={setGroups} // Function will trigger on select event
                onRemove={setGroups} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                showCheckbox={true}
                closeOnSelect={false}
                selectedValues={groups}
                id="groupSelect"
                closeIcon="cancel"
                emptyRecordMsg="No groups available"
                placeholder=    {i18n.t('site.add.form.groups.select',"Select groups...")}


              />

            </div>
          </div>




          <div className="row">
            <div className="form-group col-md-6 col-sm-12">
              <label htmlFor="addrL1">    {i18n.t('site.add.form.address',"Address")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="addrL1"
                placeholder=    {i18n.t('site.add.form.address.placeholder',"Address")}

                value={jobSiteData.address ? jobSiteData.address : ""}
                onChange={(e) => {
                  jobSiteData.address = e.target.value;
                  setJobSiteData({ ...jobSiteData });
                }}
                required
              />
            </div>

            <div className="form-group col-md-6 col-sm-12">
              <label htmlFor="website">    {i18n.t('site.add.form.website',"Website")}
              </label>
              <input
                type="text"
                className="form-control"
                id="website"
                placeholder=    {i18n.t('site.add.form.website.placeholder',"Website")}

                value={jobSiteData.website ? jobSiteData.website : ""}
                onChange={(e) => {
                  jobSiteData.website = e.target.value;
                  setJobSiteData({ ...jobSiteData });
                }}
              />
            </div>
          </div>





          <div className="row">
            <div className="form-group col-sm-12">
              <label htmlFor="description">    {i18n.t('site.add.form.notes',"Special notes")}
              </label>
              <textarea
                className="form-control form-control-sm"
                id="description"
                placeholder=    {i18n.t('site.add.form.notes.placeholder',"Special notes about the job site")}

                rows="2"
                value={jobSiteData.description ? jobSiteData.description : ""}
                onChange={(e) => {
                  jobSiteData.description = e.target.value;
                  setJobSiteData({ ...jobSiteData });
                }}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col text-right">
              <input className="btn btn-primary" type="submit" value={
                isEditMode ? i18n.t('btn.update',"Update") :i18n.t('btn.save',"Save")
              } />
            </div>
          </div>

        </form>
      </AnimateDiv>
    );
  };



  const Search = ({ panTo, setMarker, jobSiteData, setJobSiteData, center }) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => center.lat, lng: () => center.lng },
        radius: 1000,
      },
    });

    const handleInput = (e) => {
      setValue(e.target.value);
    };
    function handleAddress(address) {
      if (address) {
        const split = address.split(',');
        if (split.length > 0) {
          jobSiteData.name = split[0];
          jobSiteData.address = address;
          setJobSiteData({ ...jobSiteData });
        }
      }
    }

    const handleSelect = async (address) => {
      setValue(address);
      clearSuggestions();
      handleAddress(address);

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        panTo({ lat, lng });
        setMarker({ lat, lng });
      } catch (error) {
        //console.log(("Error: ", error);)
      }
    };

    return (
      <div className="search">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            onChange={handleInput}
            disabled={!ready}
            defaultValue={jobSiteData && jobSiteData.address ? jobSiteData.address : ""}
            placeholder=    {i18n.t('site.add.search-location.placeholder',"Search your location")}

          />
          <ComboboxPopover>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption key={place_id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="content-wrapper job-location">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{isEditMode ?     i18n.t('site.update.form.title',"Update Job Location")
                  :     i18n.t('site.add.form.title',"Add Job Location")}
              </h4>
              <p className="card-description">
                {isEditMode ? i18n.t('site.update.form.description',"Update location details") : i18n.t('site.add.form.description',"Set location details")}
              </p>
              <AnimatePresence>
                {currentStep.id === 1 ? (
                  <SelectClient />
                ) : (
                    <CollectAddress />
                  )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
