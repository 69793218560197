import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {currentUser, getUsers, getNotifications, markNotificationsRead, getGroups, updateLocation} from "../api";
import { authActions } from "../state/auth";
import { userActions } from "../state/users";
import { groupActions } from "../state/groups";
import { notificationActions } from "../state/notifications";
import userDefaultImg from "../resources/img/user/user-img-default.png";
import notificationDefaultImg from "../resources/img/other/notification.png";
import moment from 'moment';
import Icon from "@mdi/react";
import {notiIconMap} from "../modules/pages/jobPages/shared/notificationWidget";
import i18n from "i18next";
import {loadLanguage} from "../utils/languageUtil";

export function PageLayout({ children }) {
  const dispatch = useDispatch();
  const userState = useSelector((userState) => userState.auth.profile);
  const chatState = useSelector((chatState) => chatState.chat);
  const nots = useState('notifications.byId');


  const companyState = useSelector(
    (companyState) => companyState.company.profile
  );
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const [notifications, setNotifications] = useState([]);
  const KEY_NOTIFICATION_ENABLED = "notificationEnabled";
  useEffect(async () => {
    loadLanguage(companyState);
    const res = await getNotifications();
    if (res && res.success && res.data) {
      setNotifications(res.data);

    }

    if (!sessionStorage.getItem(KEY_NOTIFICATION_ENABLED)) {
      setInterval(async () => {
        sessionStorage.setItem(KEY_NOTIFICATION_ENABLED, true);

        const res = await getNotifications();
        if (res && res.success && res.data) {
          setNotifications(res.data);
        }
      }, 30000);

    }


  }, []);
  useEffect(async () => {
    loadAllGroups();
    const res = await currentUser();
    updateUserLocation();
    const users = await getUsers(companyState.id, false);
    try {
      if (res.success && users.success) {
        dispatch(userActions.setUsers(users.data));
        dispatch(authActions.setUser(res.data));
      } else {
      }
    } catch (err) {
    }
  }, []);

async function updateUserLocation(){
  navigator.geolocation.getCurrentPosition(function(position) {
    //console.log(("Latitude is :", position.coords.latitude);)
    //console.log(("Longitude is :", position.coords.longitude);)
    updateLocation(position.coords.latitude,position.coords.longitude);
  },
  function(error) {
    console.error("Error Code = " + error.code + " - " + error.message);
    updateLocation();
  }
  );
}
  async function loadAllGroups() {
    const res = await getGroups(companyState.id);
    if (res.success) {
      dispatch(groupActions.clearGroups());
      dispatch(groupActions.setGroups(res.data));
    }
  }
  const logoutHandler = () => {
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  return (
    <section className="container-scroller d-flex">
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item sidebar-category">
            <p>Navigation</p>
            <span></span>
          </li>
          <li className="nav-item ">
            <a className="nav-link" href="/">
              <i className="mdi mdi-view-quilt menu-icon"></i>
              <span className="menu-title">    {i18n.t('menu.dashboard',"Dashboard")}
</span>
              {/* <div className="badge badge-primary badge-pill">2</div> */}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/chat">
              <i className="mdi mdi-message-bulleted menu-icon"></i>
              <span className="menu-title">    {i18n.t('menu.chat',"Chat")}
</span>
            </a>
          </li>
          <li className="nav-item sidebar-category">
            <p>    {i18n.t('menu.components',"Components")}
            </p>
            <span></span>
          </li>
          {userState?.userRole?.roleLevel === 1 ||
            userState?.userRole?.roleLevel === 2 ||
            userState?.userRole?.roleLevel === 3 ? (
              <>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#users"
                    aria-expanded="false"
                    aria-controls="users"
                  >
                    <i className="mdi mdi-account-multiple menu-icon"></i>
                    <span className="menu-title">    {i18n.t('menu.users',"Users")}</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="users">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">

                        <a className="nav-link" href="/users/add">
                          {i18n.t('menu.users.registration','User Registration')}
                      </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/users">
                          {i18n.t('menu.users.view','View Users')}
                      </a>
                      </li>
                    </ul>
                  </div>
                </li>





                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#contracts"
                    aria-expanded="false"
                    aria-controls="contracts"
                  >
                    <i className="mdi mdi-book-open menu-icon"></i>
                    <span className="menu-title">    {i18n.t('menu.contracts',"Contracts")}
</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="contracts">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/contracts/add">
                          {i18n.t('menu.contracts.add',"Add Contract")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/contracts/all">
                          {i18n.t('menu.contracts.view',"View Contracts")}

                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#jobsite"
                    aria-expanded="false"
                    aria-controls="jobsite"
                  >
                    <i className="mdi mdi-briefcase menu-icon"></i>
                    <span className="menu-title">       {i18n.t('menu.sites',"Sites")}
</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="jobsite">
                    <ul className="nav flex-column sub-menu">

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/groups">
                          {i18n.t('menu.sites.group',"Site Groups")}

                        </a>
                      </li>

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/jobs/addlocation">
                          {i18n.t('menu.sites.add',"Add Site")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/jobs/viewlocation/all">
                          {i18n.t('menu.sites.view',"View Sites")}

                        </a>
                      </li>

                    </ul>
                  </div>
                </li>


                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#jobdetail"
                    aria-expanded="false"
                    aria-controls="jobdetail"
                  >
                    <i className="mdi mdi-file-check menu-icon"></i>
                    <span className="menu-title">    {i18n.t('menu.job-detail',"Job Detail")}
</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="jobdetail">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/jobs/jobdetail/add">
                          {i18n.t('menu.job-detail.add',"Add Job Detail")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/jobs/jobdetail/edit">
                          {i18n.t('menu.job-detail.edit',"Edit Job Detail")}

                        </a>
                      </li>
                    </ul>
                  </div>
                </li>



                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#job"
                    aria-expanded="false"
                    aria-controls="job"
                  >
                    <i className="mdi mdi-checkbox-multiple-marked menu-icon"></i>
                    <span className="menu-title">    {i18n.t('menu.jobs',"Jobs")}
</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="job">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/job/add">
                          {i18n.t('menu.jobs.add',"Add Job")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/job/edit">
                          {i18n.t('menu.jobs.edit',"Edit Job")}

                        </a>
                      </li>

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/job/notifications">
                          {i18n.t('menu.jobs.notification',"Notifications")}

                        </a>
                      </li>

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/job/calendar">
                          {i18n.t('menu.calendar',"Calendar")}

                      </a>
                      </li>
                    </ul>
                  </div>
                </li>


                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#report"
                    aria-expanded="false"
                    aria-controls="report"
                  >
                    <i className="mdi mdi-checkbox-multiple-marked menu-icon"></i>
                    <span className="menu-title">    {i18n.t('menu.reports',"Reports")}
</span>
                    <i className="menu-arrow"></i>
                  </a>
                  <div className="collapse" id="report">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/inspection">
                          {i18n.t('menu.reports.inspections',"Inspections")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/payroll/site">
                          {i18n.t('menu.reports.site-payroll',"Payroll By Site")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/payroll/user">
                          {i18n.t('menu.reports.emp-payroll',"Payroll By Employee")}

                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/trend/inspection">
                          {i18n.t('menu.reports.inspection-trend',"Inspection Trend")}
                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/detailed">
                          {i18n.t('menu.reports.detail-work-report',"Detail Work Report")}
                      </a>
                      </li>

                      <li className="nav-item">
                        {" "}
                        <a className="nav-link" href="/report/heat-map">
                          {i18n.t('menu.reports.hear-map',"Heat Map")}
                      </a>
                      </li>
                    </ul>
                  </div>
                </li>


              </>
            ) : null}
        </ul>
      </nav>
      <div className="container-fluid page-body-wrapper">
        <nav className="navbar col-lg-12 col-12 px-0 py-0 py-lg-4 d-flex flex-row"
          style={{
            backgroundImage: 'url(' + (companyState.coverImageURL && companyState.coverImageURL.includes('http') ? companyState.coverImageURL
              : '../images/custom/company/company-cover-img-default.jpg') + ')'
          }}>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
            >
              <span className="mdi mdi-menu"></span>
            </button>
            <div className="navbar-brand-wrapper row align-center">
              <a
                className="navbar-brand brand-logo-mini d-none d-md-block"
                href="/"
              >


                <img className="header-logo text-shadow" src={companyState.logoURL} alt="logo" />

              </a>
              <h3 className="font-weight-bold mb-0 d-md-block ml-3 ml-md-0 mt-0 mt-md-3 mr-3 text-shadow">
                {companyState.fullName}
              </h3>
            </div>
            {companyState.verified && (
              <small className="mdi mdi-checkbox-marked-circle-outline company-verified-tick"></small>
            )}
            {window.location.pathname === "/" && (
              <div className="align-bottom padding-std">
                <h4 className="font-weight-bold mb-0 d-md-block pl-4 ml-4 ml-md-0 mt-0 mt-md-3 mr-3 text-shadow">
                  {i18n.t('header-layout.welcome',"Welcome back")}, {userState.firstName}
                </h4>
              </div>
            )}
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item">
                <h4 className="mb-0 font-weight-bold d-none d-xl-block text-shadow">
                  {today.toDateString()}
                </h4>
              </li>
              <li className="nav-item dropdown mr-1">
                <a
                  className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center"
                  id="messageDropdown"
                  href="#"
                  data-toggle="dropdown"
                >
                  <i className="mdi mdi-calendar mx-0"></i>
                  {notifications && notifications.filter((not) => !not.read).length > 0 &&
                    (<span className="count bg-primary">
                      {notifications.filter((not) => !not.read).length}
                    </span>)
                  }

                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  style={{
                    maxHeight: "700px",
                    overflow: "auto"
                  }}
                  aria-labelledby="messageDropdown"
                >
                  <p className="mb-0 font-weight-normal float-left dropdown-header">
                    {i18n.t('header-layout.notifications',"Notifications")}

                  </p>

                  {notifications && notifications.map((notification) => {
                    return (
                      <a
                        className={notification.read ? "dropdown-item preview-item card-body" : "dropdown-item preview-item text-primary"}
                        title={notification.message}
                      >
                        <div className="preview-thumbnail">
                          {notiIconMap[notification.imageURL] ?(
                              <Icon path={notiIconMap[notification.imageURL]}
                                    className="mr-2 noti-icon "
                                    title={notification.imageURL}
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                              />


                          ):(

                              <img
                                  src={notification.imageURL == null ? notificationDefaultImg : notification.imageURL}
                                  alt="image"
                                  className="profile-pic"
                              />
                          )}

                        </div>
                        <div className="preview-item-content flex-grow">
                          <h6 className="preview-subject ellipsis font-weight-normal">
                            {notification.title}
                          </h6>
                          <p className="font-weight-light small-text text-muted mb-0" style={{
                            maxWidth: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                            data-original-title={notification.message}
                          >
                            {notification.message}
                          </p>
                          <p className="font-weight-light small-text text-muted mb-0"
                            style={{
                              textAlign: "end"
                            }}>{moment(notification.createdDate).fromNow()}</p>
                        </div>
                        <hr></hr>
                      </a>
                    )
                  })}


                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">

                    </div>

                    {notifications && notifications.length > 0 &&
                      notifications.filter((not) => !not.read).length > 0 && (

                        <div className="preview-item-content flex-grow"
                          onClick={() => {
                            notifications.forEach((n) => n.read = true);
                            setNotifications([...notifications]);
                            markNotificationsRead()
                          }}
                        >


                          <a className="font-weight-light small-text text-muted mb-0">
                            {i18n.t('header-layout.notifications.mark-all-read',"Mark all read")}

                          </a>
                        </div>
                      )}


                  </a>
                </div>
              </li>
              <li className="nav-item dropdown mr-2">
                <a
                  className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                >
                  <i className="mdi mdi-settings mx-0"></i>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="notificationDropdown"
                >
                  <p className="mb-0 font-weight-normal float-left dropdown-header">

                  </p>

                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings mx-0"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">
                        {i18n.t('header-layout.menu.settings',"Settings")}

                      </h6>

                    </div>
                  </a>

                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
            >
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
          <div className="navbar-menu-wrapper navbar-search-wrapper d-none d-lg-flex align-items-center">
            <ul className="navbar-nav mr-lg-2">
              <li className="nav-item nav-search d-none d-lg-block">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Here..."
                    aria-label="search"
                    aria-describedby="search"
                  />
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-profile dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  id="profileDropdown"
                >
                  <img className="card-img-top  avatar-img" src={
                    userState.imageURL ? userState.imageURL : userDefaultImg} alt="profile" />
                  <span className="nav-profile-name">
                    {userState.firstName + " " + userState.lastName}
                  </span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="profileDropdown"
                >
                  <a className="dropdown-item">
                    <i className="mdi mdi-settings text-primary"></i>
                    {i18n.t('header-layout.menu.settings',"Settings")}

                  </a>
                  <a className="dropdown-item" onClick={logoutHandler}>
                    <i className="mdi mdi-logout text-primary"></i>
                    {i18n.t('header-layout.menu.logout',"Logout")}

                  </a>
                </div>
              </li>
              {/* <li className="nav-item">
                <a href="#" className="nav-link icon-link">
                  <i className="mdi mdi-plus-circle-outline"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link icon-link">
                  <i className="mdi mdi-web"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link icon-link">
                  <i className="mdi mdi-clock-outline"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
        <section className="main-panel">{children}</section>
        <div>
          <footer className="footer">
            <div className="card">
              <div className="card-body">
                <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
                  <p className="text-center text-sm-left d-block d-sm-inline-block mb-0">
                    Copyright © 2019{" "}
                    <a href="https://www.bootstrapdash.com/" target="_blank">
                      Bootstrapdash
                    </a>
                    . All rights reserved.
                  </p>
                  <p className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center mb-0">
                    Hand-crafted & made with{" "}
                    <i className="mdi mdi-heart-outline text-muted icon-sm"></i>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
}
