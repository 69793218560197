import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Provider, useSelector} from "react-redux";
import swal from "sweetalert";
import {
    getCalendarEvents,
    getJob,
    getJobDetailSummary,
    getJobLocations,
    getJobRelatedFiles,
    getJobs,
    registerUsersForJob,
    removeJobRelatedFile,
    removeUsersForJob,
    setJobActiveState,
    updateJob,
    updateUsersForJobApi,
    uploadJobRelatedFile
} from "../../../api";

import {PageLayout} from "../../../components/pageLayout";
import {store} from "../../../state/store";
import {RegisterJobDetail} from "./registerJobDetail";
import {useHistory} from "react-router-dom";
import {EmployeeSelectWidget, getSingleText, getUserId} from "./shared/employeeSelect";
import i18n from "i18next";
import {Avatar, Chip} from "@material-ui/core";
import {getDisplayNameForFile, getFileExtension} from "./job";

let values = {};


export async function updateUsersForJob(jobId, addUsers, removeUsers, calendarEvents) {
    addUsers = addUsers ? addUsers : [];
    removeUsers = removeUsers ? removeUsers : [];

    const req = {
        jobUsers: addUsers.map((e) => {
                return {
                    tenantUserId: e.id,
                    hours: e.hours ? e.hours : 0
                }
            },
        ),
        removeUserIds: removeUsers,
        calendarEventRequest: calendarEvents
    }
    return await (updateUsersForJobApi(jobId, req));

}

export function JobEditPage(props) {

    const companyState = useSelector((state) => state.company.profile);
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);
    const allClientDataArray = allClientIds.map((key) => allClientData[key]);
    const [allContractData, setAllContractData] = useState([]);
    const [allJobSites, setAllJobSites] = useState([]);
    const [jobDetailSummaries, setJobDetailSummaries] = useState([]);
    const [availableEmployees, setAvailableEmployees] = useState([]);
    const [initialUsers, setInitialUsers] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [addedEmployees, setAddedEmployees] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [removedEvents, setRemovedEvents] = useState([]);
    const [addedCalEvents, setAddedCalEvents] = useState([]);
    const [relatedFiles, setRelatedFiles] = useState([]);

    var initialized = false;
    const page = 0;
    const size = 20;
    useEffect(async () => {
        loadUsersForAutoComplete();
        loadJobLocations();
        loadJobDetailSummaries();
        loadJobs();

    }, []);
    useEffect(async () => {
        if (addedEmployees.length > 0 && selectedJob) {

            _loadCalendarEventsForAddedEmployee(selectedJob, addedEmployees);
        }
    }, [addedEmployees])

    async function loadJobs(clientId, jobLocationId, jobDetailId) {
        const res = await getJobs(companyState.id, clientId, jobLocationId, jobDetailId);
        if (res.success) {
            setJobs(res.data);
        } else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }

    async function _loadCalendarEventsForAddedEmployee(selectedJob, addedEmployee) {

        await loadCalendarEventsForAddedEmployee(jobs, companyState.id, addedEmployee, setCalendarEvents)


    }

    function loadUsersForAutoComplete() {

        const emps = [];
        allClientDataArray.forEach((user) => {
            if (user.userRole.roleLevel < 10 && user.userRole.roleLevel > 2) {
                const display =
                    (user["employeeNumber"] ? user["employeeNumber"] + " - " : "") +
                    (user["user"]["firstName"] ? user["user"]["firstName"] + " " : "") +
                    (user["user"]["lastName"] ? user["user"]["lastName"] : "")
                ;

                var emp = Object.assign({}, user, {
                    display: display,
                    role: user["userRole"]["name"],
                    hours: user["hours"] ? user["hours"] : 0
                });

                emps.push(emp);
            }
        })
        setAvailableEmployees([...emps]);
    }


    async function addEmployeesToJob(jobId, ade) {

        const req = {
            jobUsers: ade.map((e) => {
                return {
                    tenantUserId: e.id,
                    hours: e.hours ? e.hours : 0
                }
            })
        }
        const res = await registerUsersForJob(jobId, req);

    }


    async function removeEmployeesFremoJob(jobId, rme) {

        const req = rme.map((e) => {
            return e.tenantUserId
        });

        const res = await removeUsersForJob(jobId, req);

    }

    const onClientChange = async (e) => {
        try {
            values.clientId = e.target.value == -1 ? null : e.target.value

            loadJobs(values.clientId, values.jobSiteId, values.jobDetailId);
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }


    async function loadJobLocations(clientId) {
        try {

            const res = await getJobLocations(companyState.id, clientId, null, page, size);
            if (res.success) {
                setAllJobSites(res.data);
            }
            const id = parseInt(document.getElementById('client').value);
            if (clientId) {
                loadJobDetailSummaries(clientId);
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    async function loadJobDetailSummaries(clientId) {
        const res = await getJobDetailSummary(companyState.id, clientId);
        if (res.success) {
            setJobDetailSummaries(res.data);
        }
    }

    const onJobSiteChange = async (e) => {
        try {
            values.jobSiteId = e.target.value == -1 ? null : e.target.value

            loadJobs(values.clientId, values.jobSiteId, values.jobDetailId);
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    const onJobDetailChange = async (e) => {

        values.jobDetailId = e.target.value == -1 ? null : e.target.value

        loadJobs(values.clientId, values.jobSiteId, values.jobDetailId);
    }

    function findOnList(list, item, prop) {
        if (list && item && item[prop]) {
            for (var i of list) {
                if (i[prop] && i[prop] === item[prop]) {
                    return true;
                }

            }
            return false;
        }
    }

    function onSelect(list, item) {
        if (selectedJob && selectedJob.id) {

            setAddedEmployees([...list]);
            //console.log((list, item);)
        } else {
            setAddedEmployees([]);

            swal({
                title: "Missing Information",
                text: "Please select a job first."
            })
        }
    }


    const onJobChange = async (e) => {
        try {
            const id = parseInt(e.target.value);
            if (id === -1) {
                setSelectedJob(null);
            } else {

                for (let job of jobs) {
                    if (job.id === id) {
                        populateJob(job);
                        break;
                    }
                }
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }

    function loadEmps(job, res) {
        if (res.success) {
            job = res.data;
            job.maxServiceTime = job.maxServiceTime && job.maxServiceTime > 0 ? job.maxServiceTime : 480;
            setSelectedJob(job);
            setJobs([...jobs]);
            var users = job.jobAssignees;
            var emps = [];
            if (users && users.length > 0) {
                let userIds = [];
                users.forEach((user) => {
                    userIds.push(user["tenantUserId"]);
                    const display =
                        (user["employeeNumber"] ? user["employeeNumber"] + " - " : "") +
                        (user["firstName"] ? user["firstName"] + " " : "") +
                        (user["lastName"] ? user["lastName"] : "")
                    ;

                    var emp = Object.assign({}, user, {
                        display: display,
                        userRole: {name: user["role"]},
                        hours: user["hours"] ? user["hours"] : 0
                    });

                    emps.push(emp);

                });
                setInitialUsers([...emps]);
                setAddedEmployees([...emps]);

            } else {
                setInitialUsers([]);
                setAddedEmployees([]);
            }


        }
    }

    const fileUpload = useRef(null);

    async function loadRelatedFiles(job, res) {
        const fileRes = await getJobRelatedFiles(job.id);
        setRelatedFiles(fileRes.data);
    }

    async function populateJob(job) {

        const res = await getJob(job.id);
        loadEmps(job, res);
        loadRelatedFiles(job, res);
    }

    function setHours(a, b) {
        a.hours = b.target.value ? parseInt(b.target.value) : 0;
        setAddedEmployees([...addedEmployees]);
        selectedJob.changes = true;
        setSelectedJob(selectedJob);
    }

    function viewJobDetail() {
        if (!selectedJob) {
            swal("Please select a job detail to continue");
        } else {
            let wrapper = document.createElement('div');
            ReactDOM.render(
                <Provider store={store}>
                    <RegisterJobDetail
                        viewMode={true}
                        jobDetailId={selectedJob.jobDetailId}
                    ></RegisterJobDetail>
                </Provider>


                , wrapper);
            let el = wrapper.firstChild;
            swal({
                title: 'View job detail',
                html: true,
                type: "info",
                className: 'swal-wide',
                content: el.children[1].children[1].firstChild,
                showCancelButton: true,
                showConfirmButton: false
            });
        }
    }

    async function updateUsers() {
        const adEmps = []
        const rmEmps = []
        addedEmployees.forEach((e) => {
            if (!findOnList(initialUsers, e, "tenantUserId")) {
                adEmps.push(e);
            }
        });

        initialUsers.forEach((e) => {
            if (!findOnList(addedEmployees, e, "tenantUserId")) {
                rmEmps.push(e['tenantUserId']);
            }
        })

        //console.log((adEmps, rmEmps, addedCalEvents);)
        let changed = false;
        const hasNewUsers = adEmps && adEmps.length > 0;
        const hasRemoveUsers = rmEmps && rmEmps.length > 0;
        const hasScheduleChanges = addedCalEvents && addedCalEvents.length > 0;
        const hasRemovedScheduled = removedEvents && removedEvents.length > 0;
        if (hasNewUsers || hasRemoveUsers || hasScheduleChanges || hasRemovedScheduled) {
            changed = true;
            updateUsersForJob(selectedJob.id, adEmps, rmEmps,
                {
                    mappedId: selectedJob.id,
                    tenantId: companyState.id,
                    events: addedCalEvents,
                    removeAssigneeIds: removedEvents

                }
            ).then((res) => {
                setCalendarEvents([]);
                setAddedCalEvents([]);
                setAddedEmployees([]);
                setRemovedEvents([]);
                setSelectedJob(null);
            });
        }


        return changed;

    }

    async function _setJobActiveState() {
        if (selectedJob) {

            swal(
                {
                    title: 'Confirm',
                    text: ' Are you sure to ' + (selectedJob.enabled ? "deactivate" : "activate") + ' this job?',
                    icon: 'warning',
                    buttons: {
                        cancel: "Close",
                        submit: {
                            text: (selectedJob.enabled ? "Deactivate" : "Activate"),
                            value: "delete"
                        }
                    }

                }
            ).then((val) => {
                if (val == 'delete') {
                    return setJobActiveState(selectedJob.id, !selectedJob.enabled);

                }
                throw null;
            }).then((res) => {
                swal({
                    title: res && res.success ? 'Success' : 'Error',
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: res && res.success ? 'success' : 'error',
                    buttons: "Close"
                }).then(() => {

                        loadJobs();
                        selectedJob.enabled = !selectedJob.enabled;
                        setSelectedJob({...selectedJob});
                    }
                );
            });


        }
    }

    async function updateFiles() {
        if (relatedFiles && relatedFiles.length > 0) {
            for (let relatedFile of relatedFiles) {
                if (relatedFile.file) {
                    await uploadJobRelatedFile(selectedJob.id, relatedFile.file);
                }
            }
        }

    }

    async function _updateJob() {
        const changed = await updateUsers();
        await updateFiles();

        const totalHours = document.getElementById('totalHours') && document.getElementById('totalHours').value && parseInt(document.getElementById('totalHours').value) ? parseInt(document.getElementById('totalHours').value) : 0;
        const minDistance = document.getElementById('minDistance') && document.getElementById('minDistance').value && parseInt(document.getElementById('minDistance').value) ? parseInt(document.getElementById('minDistance').value) : 0;
        const distanceFilter = document.getElementById('distanceFilter') && document.getElementById('distanceFilter').value && parseInt(document.getElementById('distanceFilter').value) ? parseInt(document.getElementById('distanceFilter').value) : 0;
        const notes = document.getElementById('notes') && document.getElementById('notes').value ? document.getElementById('notes').value : null;
        const maxServiceTime = document.getElementById('maxServiceTime') && document.getElementById('maxServiceTime').value ? document.getElementById('maxServiceTime').value : null;
        const pushFeedbackInDays = document.getElementById('pushFeedbackInDays') && document.getElementById('pushFeedbackInDays').value && parseInt(document.getElementById('pushFeedbackInDays').value) ? parseInt(document.getElementById('pushFeedbackInDays').value) : 0;


        const req = {
            totalHours: totalHours,
            notes: notes,
            distanceFilterForLocationRecord: distanceFilter,
            minimumDistanceToStart: minDistance,
            maxServiceTime: maxServiceTime,
            pushFeedbackInDays: pushFeedbackInDays

        }

        const res = await updateJob(selectedJob.id, req);
        // const res = {success: true};


        if (changed || (res && res.success)) {
            swal({
                title: "Success",
                text: "job updated",
                icon: "success"
            }).then(() => {
                setAddedEmployees([]);
                setRelatedFiles([]);
                loadJobs();

            });

        }
    }

    let history = useHistory();

    function _gotoNotifications() {
        history.push({
            pathname: '/job/notifications',
            search: '?jobId=' + selectedJob.id
        })
    }

    function getJobName(prefix, job) {
        return prefix + " " + job.jobLocation.name + (job.enabled ? "" : " (Deactivated)");
    }

    function downloadFile(file) {
        window.open(file.url);

    }

    function deleteRelatedFile(file) {
        if (file) {
            swal(
                {
                    title: 'Confirm',
                    text: ' Are you sure to remove ' + file.fileName + ' ?',
                    icon: 'warning',
                    buttons: {
                        cancel: "Close",
                        submit: {
                            text: "Delete",
                            value: "delete"
                        }
                    }

                }
            ).then(async (val) => {
                if (val == 'delete') {
                    if(file.id){
                        return await removeJobRelatedFile(file.id);

                    }else {
                        return {success:true,message:"File removed"};
                    }

                }
                throw null;
            })
                .then((res) => {
                    swal({
                        title: res && res.success ? 'Success' : 'Error',
                        text: res && res.message ? res.message : "Something went wrong",
                        icon: res && res.success ? 'success' : 'error',
                        buttons: "Close"
                    }).then(() => {
                            const i = relatedFiles.indexOf(file);
                            let l = relatedFiles.splice(i, 1);
                            console.log(l);
                            setRelatedFiles(l);
                        }
                    );
                });

        }
    }

    function handleUploadRelatedFiles() {
        if (!selectedJob || !selectedJob.id) {
            swal("You have to select a job first");
            return;
        }
        fileUpload.current.click();
    }

    const addFile = event => {
        console.log(selectedJob);
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        let _size = fileObj.size;
        let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0;
        while (_size > 900) {
            _size /= 1024;
            i++;
        }
        const exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];

        event.target.value = null;


        let file = {
            fileName: fileObj.name,
            extension: "NEW",
            sizeH: exactSize,
            file: fileObj
        }
        relatedFiles.push(file);
        setRelatedFiles([...relatedFiles]);

    }

    return (
        <PageLayout>
            <div className="content-wrapper ">
                <div className="col-12 grid-margin stretch-card col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <h4 className="card-title">    {i18n.t('job.edit.form.title', "Update Job")}
                            </h4>
                            <p className="card-description">    {i18n.t('job.edit.form.description', "Search and update jobs")}
                            </p>


                            <div className="row">
                                <div className="col-sm-12">

                                    <form className="forms-sample" id="create-job-form"
                                          onSubmit={(e) => addEmployeesToJob(e)}>


                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5>    {i18n.t('job.edit.form.filter-by', "Filter By")}
                                                </h5></div>

                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="client"
                                                    onChange={onClientChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('forms.client.select', "Select client...")}
                                                    </option>
                                                    {allClientDataArray &&
                                                    allClientDataArray.map((client) => {
                                                        if (client.userRole.roleLevel === 10) {
                                                            return (
                                                                <option value={client.id} key={client.id}>
                                                                    {client.user.firstName + " " + client.user.lastName}
                                                                </option>
                                                            );
                                                        }
                                                    })}
                                                </select>
                                            </div>


                                            <div className="form-group col-md-4">
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="jobsite_select"
                                                    onChange={onJobSiteChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('job.add.form.job-site.select', "Select job site...")}
                                                    </option>
                                                    {allJobSites &&
                                                    allJobSites.map((jobSite) => {
                                                        return (
                                                            <option value={jobSite.contractHasLocationId}
                                                                    key={jobSite.contractHasLocationId}>
                                                                {jobSite.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">


                                                <select
                                                    className="form-control form-control-sm"
                                                    id="jobdetail_select"
                                                    onChange={onJobDetailChange}
                                                    required
                                                >
                                                    <option
                                                        value="-1">    {i18n.t('job.add.form.jd.select', "Select job detail...")}
                                                    </option>
                                                    {jobDetailSummaries &&
                                                    jobDetailSummaries.map((jobDetailSummary) => {
                                                        return (
                                                            <option value={jobDetailSummary.id}
                                                                    key={jobDetailSummary.id}>
                                                                {jobDetailSummary.id + ". " + jobDetailSummary.title}
                                                            </option>
                                                        );
                                                    })}
                                                </select>


                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 form-group">
                                                <label htmlFor="jobdetail_select"><h6>    {i18n.t('menu.jobs', "Jobs")}
                                                </h6></label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="jobdetail_select"
                                                    onChange={onJobChange}
                                                    required
                                                >
                                                    <option value="-1">    {i18n.t('forms.job.select', "Select job...")}
                                                    </option>
                                                    {jobs &&
                                                    jobs.map((job) => {
                                                        return (
                                                            <option value={job.id} key={job.id}>
                                                                {job.id + ". " + getJobName("Job:", job)}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">

                                                    <label
                                                        for="totalHours">    {i18n.t('job.add.form.total-hours', " urs")}
                                                    </label>
                                                    <input className="form-control" id="totalHours"
                                                           min="0"
                                                           value={selectedJob ? selectedJob.hours : 0}
                                                           onChange={(e) => {
                                                               if (selectedJob) {
                                                                   selectedJob.hours = e.target.value;
                                                                   setSelectedJob(selectedJob);
                                                               }
                                                           }}
                                                           type="number" min="0"></input>
                                                </div>

                                            </div>
                                            <div className="form-group col-md-6">

                                                <div className="row">
                                                    <div className="form-group col-sm-10">
                                                        <label
                                                            htmlFor="jobdetail_select">    {i18n.t('menu.job-detail', "Job Detail")}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            id="jobdetail_select"
                                                            onChange={onJobDetailChange}
                                                            required
                                                            disabled={true}
                                                            value={selectedJob ? selectedJob.jobDetailId : "Select a job..."}
                                                        />

                                                    </div>
                                                    <div className="flex align-center col-sm-2 mt-1">
                                                        <button className=" btn btn-sm mt-2 btn-primary mr-0"
                                                                type="button"
                                                                onClick={viewJobDetail}>    {i18n.t('btn.view', "View")}

                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">

                                                <div className="row">
                                                    <div className="form-group col-sm-10">
                                                        <label htmlFor="jobdetail_select">
                                                            <h6>    {i18n.t('job.add.form.files', "Files")}
                                                            </h6>    {i18n.t('job.add.form.relatedFiles', "Related Files")}
                                                        </label>
                                                        <div className="col ">
                                                            {relatedFiles && relatedFiles.map(file => {
                                                                return (
                                                                    <Chip onClick={() => downloadFile(file)}
                                                                          className="ml-2" variant="outlined"
                                                                          color="success" onDelete={() => {
                                                                        deleteRelatedFile(file);
                                                                    }} avatar={
                                                                        <Avatar>{getFileExtension(file)}</Avatar>}
                                                                          label={getDisplayNameForFile(file)}
                                                                          title={file["fileName"]}/>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="flex align-center col-sm-2 mt-4">
                                                        <input
                                                            type="file"
                                                            ref={fileUpload}
                                                            onChange={addFile}
                                                            style={{opacity: "0"}}
                                                        />
                                                        <button className=" btn btn-sm mt-2 btn-primary mr-0"
                                                                type="button"
                                                                onClick={handleUploadRelatedFiles}>    {i18n.t('btn.add', "Add")}

                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                        <EmployeeSelectWidget
                                            setHours={setHours}
                                            addedEmployees={addedEmployees}
                                            onSelect={onSelect}
                                            onRemove={onSelect}
                                            selectedJob={selectedJob}
                                            jobLocation={selectedJob && selectedJob.jobLocation ? selectedJob.jobLocation : null}
                                            availableEmployees={availableEmployees}
                                            calendarEvents={calendarEvents}
                                            setCalendarEvents={setCalendarEvents}
                                            setRemovedEvents={setRemovedEvents}
                                            setAddedCalEvents={setAddedCalEvents}
                                            rootAddedCalEvent={addedCalEvents}
                                        ></EmployeeSelectWidget>


                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.notes', "Special notes")}
                                                    </label>
                                                    <textarea className="form-control" id="notes" rows="4"
                                                              autoComplete="off"
                                                              value={selectedJob ? selectedJob.description : ""}
                                                              onChange={(e) => {
                                                                  selectedJob.description = e.target.value;
                                                                  setSelectedJob(selectedJob);
                                                              }}
                                                    ></textarea>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.location-distance', "Location record distance (in meters)")}
                                                    </label>
                                                    <input className="form-control" id="distanceFilter" rows="4"
                                                           autoComplete="off" required={false}
                                                           type="number"
                                                           value={selectedJob && selectedJob.distanceFilterForLocationRecord ? selectedJob.distanceFilterForLocationRecord : 0}
                                                           onChange={(e) => {
                                                               if (selectedJob) {
                                                                   // minimumDistanceToStart:minDistance
                                                                   selectedJob.distanceFilterForLocationRecord = e.target.value;
                                                                   setSelectedJob({...selectedJob});
                                                               }
                                                           }}
                                                    ></input>
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="notes">    {i18n.t('job.add.form.min-distance-to-start', "Minimum distance to start (in meters)")}
                                                    </label>
                                                    <input className="form-control" id="minDistance" rows="4"
                                                           autoComplete="off" required={false}
                                                           type="number"
                                                           value={selectedJob && selectedJob.minimumDistanceToStart ? selectedJob.minimumDistanceToStart : 0}
                                                           onChange={(e) => {
                                                               if (selectedJob) {
                                                                   // minimumDistanceToStart:minDistance
                                                                   selectedJob.minimumDistanceToStart = e.target.value;
                                                                   setSelectedJob({...selectedJob});
                                                               }
                                                           }}
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="maxServiceTime">    {i18n.t('job.add.form.max-service-time', "Max service time (in minutes)")}
                                                    </label>
                                                    <input className="form-control" id="maxServiceTime" rows="4"
                                                           autoComplete="off" required={false}
                                                           type="number"
                                                           value={selectedJob && selectedJob.maxServiceTime ? selectedJob.maxServiceTime : 0}
                                                           onChange={(e) => {
                                                               if (selectedJob) {
                                                                   // minimumDistanceToStart:minDistance
                                                                   selectedJob.maxServiceTime = e.target.value;
                                                                   setSelectedJob({...selectedJob});
                                                               }
                                                           }}
                                                    ></input>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label
                                                        for="pushFeedbackInDays">    {i18n.t('job.add.form.client-feedback', "Client feedbacks interval(in days)")}
                                                    </label>
                                                    <input className="form-control" id="pushFeedbackInDays" rows="4"
                                                           autoComplete="off" required={false}
                                                           min={0}
                                                           type="number"
                                                           value={selectedJob && selectedJob.pushFeedbackInDays ? selectedJob.pushFeedbackInDays : 0}
                                                           onChange={(e) => {
                                                               if (selectedJob) {
                                                                   // minimumDistanceToStart:minDistance
                                                                   selectedJob.pushFeedbackInDays = e.target.value;
                                                                   setSelectedJob({...selectedJob});
                                                               }
                                                           }}
                                                    ></input>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="flex flex-bottom row-reverse col ">
                                                    <button className="btn btn-primary " type="button"
                                                            onClick={(e) => _updateJob()}>     {i18n.t('btn.update', "Update")}

                                                    </button>
                                                    <button
                                                        className={selectedJob && selectedJob.enabled ? "btn btn-danger mr-2" : "btn btn-warning mr-2"}
                                                        type="button"
                                                        enabled={selectedJob != null && selectedJob.length > 0}
                                                        onClick={(e) => _setJobActiveState()}> {selectedJob && selectedJob.enabled ? i18n.t('btn.deactivate', "Deactivate") :
                                                        i18n.t('btn.activate', "Activate")}</button>
                                                    {selectedJob && selectedJob.enabled && (
                                                        <button className="btn btn-outline-primary mr-2 " type="button"
                                                                onClick={(e) => _gotoNotifications()}>     {i18n.t('btn.setup-notifications', "Setup notifications")}
                                                        </button>

                                                    )}

                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>

    );
}

export async function loadCalendarEventsForAddedEmployee(jobs, tenantId, addedEmployee, setCalendarEvents) {
    let ids = addedEmployee.map((e) => getUserId(e));
    const jobIds = jobs ? jobs.map((e) => e.id) : null;
    const res = await getCalendarEvents({
        tenantId: tenantId,
        calendarEventTypes: ['JOB_TASK'],
        shouldActive: true,
        mappedIds: jobIds,
        tenantUserIds: ids
    });
    if (res && res.success && res.data) {
        let events = res.data;
        setDisplayNames(jobs, res.data);
        if (setCalendarEvents) {
            setCalendarEvents([...res.data]);

        }
        return res.data;
    }
    //console.log((res);)
}

const jobFilter = (jobs, id) => {
    for (let job of jobs) {
        if (job.id == id) {
            return job;
        }
    }

}

function setDisplayNames(jobs, events) {
    events.forEach((e) => {
        const job = jobFilter(jobs, e.calendarEvent.mappedId);
        e.dateText = getSingleText(e, job && job.jobLocation ? job.jobLocation : null);
        e.mappedText = e.title ? e.title : getJobName("Task :", job)
    });
    //console.log((events);)
}

function getJobName(prefix, job) {
    return prefix + " " + (job && job.jobLocation ? (job.jobLocation.name + (job.enabled ? "" : " (Deactivated)")) : "New");
}